/**
 * Created by Zirialk on 15/01/2019.
 */
import {InputBase} from './input-base';
import {Component} from '@angular/core';
import {ValueDate} from '../../../model/values/value-date';

@Component({
    selector: 'ol-input-time',
    template:
        '<ng-container [formGroup]="form">' +
        '<input class="form-control " [value]="inputValue" [placeholder]="placeholder" [id]="name" type="text" readonly daterangepicker' +
        '       [formControlName]="formControlName" [options]="value.options" (cancelDaterangepicker)="onCancel($event)" (applyDaterangepicker)="applyDaterangepicker($event)"  (selected)="onSelected($event)" [name]="name"' +
        '       (keyup.enter)="onEnterPressed()" (keyup.esc)="onEscapePressed()"/>' +
        '</ng-container>'
})
export class InputTimeComponent extends InputBase {
    onSelected(value) {
        (<ValueDate>this.value).selectedDate = value;
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }

    public onCancel(e: any) {
        this.value.value = '';
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }
    applyDaterangepicker(e: any) {
        (<ValueDate>this.value).selectedDate = {'start': e.picker.startDate, 'end': e.picker.endDate};
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }
}
