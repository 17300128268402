import {Component} from '@angular/core';
import {EntityAdd} from '../../model/entities/entity-add';
import {Item} from '../../model/item';
import {FormComponent} from './form.component';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';

@Component({
    selector: 'ol-add-component',
    templateUrl: './form.component.html',
})
export class AddComponent extends FormComponent<EntityAdd> {

    constructor(activatedRoute: ActivatedRoute, location: Location, private bodyAttributesSetter: BodyAttributesSetter) {
        super(activatedRoute, location, bodyAttributesSetter);

        this.id = activatedRoute.snapshot.params['id'];
    }

    getItem(): Observable<Item> {
        return this.entity.getItem(null);
    }


    isAddComponent(): boolean {
        return true;
    }
}
