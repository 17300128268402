import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Item} from '../../model/item';
import {EntityList} from '../../model/entities/entity-list';
import {ListResponse} from '../../model/remote/responses/list-response';
import {Column, SortMethod} from '../../model/columns/column';
import {IndividualAction} from '../../model/actions/individual-action';
import {UpdatableComponent} from '../updatable.component';
import {SummaryItemData} from '../../model/remote/responses/structures/data/list/summary-item-data';
import {GenericAction} from '../../model/actions/generic-action';
import {SelectionAction} from '../../model/actions/selection-action';
import {FilterList} from '../../model/filters/filter-list';
import {ValueChanged} from '../../model/values/value-changed';
import {TranslateService} from '@ngx-translate/core';
import {BaseEntityComponent} from '../base-entity.component';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {DownloadComponent} from '../download.component';
import {RestService} from '../../services/rest/rest.service';
import {BaseResponse} from '../../model/remote/responses/base-response';
import {DialogInfoComponent} from '../dialog/dialog-info.component';
import {MatDialog} from '@angular/material';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import Responsive from 'datatables.net-responsive';


interface Actions {
    general: GenericAction[];
    individual: IndividualAction[];
    selection: SelectionAction[];
}

@Component({
    selector: 'ol-table',
    templateUrl: './table.component.html',
    styleUrls: [
        '../../css/table/iCheck-flat-green.less',
        '../../css/table/buttons.bootstrap.min.less',
        '../../css/table/fixedHeader.bootstrap.min.less',
        '../../css/table/scroller.bootstrap.min.less',
    ],
    encapsulation: ViewEncapsulation.None
})

export class TableComponent extends BaseEntityComponent<EntityList> implements OnInit, UpdatableComponent, DownloadComponent, AfterViewInit {

    items: Item[] = [];
    columnsNames: Item = new Item();
    totalRows = 0;
    itemsPerPageAvailable: number[] = [];
    selectable = true;
    selectedIds: any[] = [];
    columns: Column[] = [];
    private timeoutReloadHandle = null;
    actions: Actions = {
        general: [],
        individual: [],
        selection: []
    };
    private _headerLoaded = false;
    summaryItems: SummaryItemData[] = [];
    private _allSelected = false;
    refreshable = true;
    @Input() urlReplacements: Params = {};
    listInfoVisible: boolean;
    filters: FilterList;
    private _currentPage: number = 1;
    private dialog: MatDialog;
    dtOptions: any = {
        responsive: {
            details: {
                renderer: Responsive.renderer.listHiddenNodes(),
                type: 'column',
                target: 0
            }
        },
        columnDefs: [{
            className: 'control',
            orderable: false,
            targets: 0
        }],
        language: {
            emptyTable: this.translateService.instant('no-data')
        },
        paging: false,
        ordering: false,
        info: false,
        searching: false
    };
    dtTrigger = new Subject();
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;

    constructor(protected router: Router, protected activatedRoute: ActivatedRoute, private http: RestService, dialog: MatDialog,
                bodyAttributesSetter: BodyAttributesSetter, protected translateService: TranslateService) {
        super(activatedRoute, bodyAttributesSetter);
        this.dialog = dialog;
    }

    async initializeEntity(entity: EntityList) {
        await this.onSetEntity(entity);
    }

    protected onSetEntity(entity: EntityList) {
        this.listInfoVisible = entity.listInfoVisible;

        if (entity) {
            entity.component = this;
        }

        this.updatePage();

    }

    ngOnInit(): void {

        this.urlReplacements = {...this.activatedRoute.snapshot.params, ...this.urlReplacements};

        this.entity.createRequest(this.urlReplacements, this.activatedRoute.snapshot.queryParams, this.translateService);

        this.entity.columnsAsRowItem.subscribe(item => this.columnsNames = item);
        this.itemsPerPageAvailable = this.entity.itemsPerPageAvailable;
        this.selectable = this.entity.options.selectable;
        this.entity.columns.subscribe((columns: Column[]) => this.columns = columns);
        this.createFilterList();
        this.createActions();
        localStorage.setItem('last_entity', this.entity.name);
    }

    private createFilterList() {
        let params = {...this.queryParams};
        if (sessionStorage.getItem(this.entity.name) !== null && this.entity.checkFromAction()) {
            params = {...params, ...JSON.parse(sessionStorage.getItem(this.entity.name)) as Params};
            sessionStorage.removeItem(this.entity.name);
        }
        this.entity.createFilterList(params).subscribe(filterList => {
            this.filters = filterList;
            this.entity.setFilters(filterList);
            this.filters.filterChanged.subscribe(() => this.onFilterChanged());

        });

    }

    showDialog(response: BaseResponse<any>) {
        this.dialog.open(DialogInfoComponent, {
            data: {
                title: response.data['title'],
                info: response.data['info']
            },
            disableClose: false

        });
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    updatePage(): void {
        this.selectedIds = [];
        if (!this._headerLoaded) {
            return;
        }
        for (const filter of this.filters.all) {
            if (filter.visible) {
                this.entity.request.addFilter(filter);
            }
        }

        for (const column of this.columns) {
            if (column.sortMethod !== SortMethod.none) {
                this.entity.request.orderBy(column.name, column.sortMethod === SortMethod.asc);
            }
        }

        this.entity.request.clear();
        this.entity.request.execute().subscribe((response: ListResponse) => {
            this.items = response.rows;
            this.totalRows = response.data.total;
            this.summaryItems = response.data.summary;
            if (this.currentPage !== 1 && this.totalRows <= (this.currentPage - 1) * this.entity.request.limit) {
                this.currentPage = this.currentPage - 1;
            } else if (this._currentPage && this._currentPage !== 1 && this.totalRows <= (this._currentPage - 1) * this.entity.request.limit) {
                this.currentPage = this._currentPage - 1;
            }

            this.onPageUpdated();

        });
    }


    protected onPageUpdated(): void {
        this.updateAllSelected();
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            // Call the dtTrigger to rerender again
            dtInstance.destroy();
            this.dtTrigger.next();
        });
    }

    get allSelected(): boolean {
        return this._allSelected;
    }

    set allSelected(value: boolean) {
        this._allSelected = value;

        this.items.map((item) => value ? this.addItemSelected(item) : this.removeSelectedItem(item));
    }

    private updateAllSelected() {
        let allSelected = this.selectedIds.length > 0;

        this.items.map(item => {
            if (!this.isSelected(item)) {
                allSelected = false;
            }
        });

        this._allSelected = allSelected;
    }

    set currentPage(value: number) {
        this._currentPage = value;
        localStorage.setItem(this.entity.name, this._currentPage.toString());
        this.entity.request.page = value;
        this.updatePage();
    }

    get currentPage(): number {
        const page = localStorage.getItem(this.entity.name);
        if (page && this.entity.fromActionBack) {
            return parseInt(page, null);
        }
        return 1;
    }

    set itemsPerPage(value: number) {
        value = Number(value);
        this.entity.request.limit = value;
        this.entity.request.page = 1;

        this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: this.queryParams}).then(() => {
            this.updatePage();
        });
    }

    get queryParams(): Params {
        return {
            ...this.activatedRoute.snapshot.queryParams,
            'limit': this.itemsPerPage
        };
    }

    get itemsPerPage(): number {
        return this.entity.request.limit;
    }

    get entityName(): string {
        return this.entity.visualName;
    }

    get statsCurrentPage() {
        const start = this.items.length === 0 ? 0 : this.entity.request.offset + 1;
        const end = this.items.length === 0 ? 0 : (start + this.items.length - 1);
        return {
            start,
            end,
            total: this.totalRows
        };
    }

    switchSelection(item: Item) {
        if (this.isSelected(item)) {
            this.removeSelectedItem(item);
        } else {
            this.addItemSelected(item);
        }

        this.updateAllSelected();
    }

    protected addItemSelected(item: Item) {
        if (!this.isSelected(item)) {
            this.selectedIds.push(item.getPrimary());
        }
    }

    protected removeSelectedItem(item: Item) {
        const index = this.selectedIds.indexOf(item.getPrimary());

        if (index > -1) {
            this.selectedIds.splice(index, 1);
        }
    }

    isSelected(item: Item): boolean {
        return this.selectedIds.indexOf(item.getPrimary()) > -1;
    }

    get countSelectedItems(): number {
        return this.selectedIds.length;
    }

    set sortChanged(column: Column) {
        this.updatePage();
    }

    onFilterChanged() {
        if (this.timeoutReloadHandle !== null) {
            clearTimeout(this.timeoutReloadHandle);
        }

        this.timeoutReloadHandle = setTimeout(() => {
            this.currentPage = 1;
        }, 200);
    }

    createActions() {
        const snapshot = this.activatedRoute.snapshot;
        const queryParams = {...snapshot.params, ...snapshot.queryParams};
        this.entity.getGenericActions(this.router, queryParams)
            .subscribe((action) => {
                action.setFilters(this.filters);
                this.actions.general.push(action);
            });
        this.entity.getSelectionActions(this.router, queryParams)
            .subscribe((action) => {
                action.setFilters(this.filters);
                this.actions.selection.push(action);
            });
    }

    set headerLoaded(loaded: boolean) {
        this._headerLoaded = loaded;
        this.updatePage();
    }

    actionsIndividual(): boolean {
        return this.entity.data.options.actionsIndividual.length > 0;
    }

    onValueChanged(valueChanged: ValueChanged) {
        const value = valueChanged.value;
        const column = value.column;
        const primaryValue = valueChanged.item.getPrimary();

        for (const item of this.items) {
            if (item.getPrimary() === primaryValue) {
                const foundValue = item.getValueModel(column);
                foundValue.visualValue = value.visualValue;
                foundValue.value = value.value;
            }
        }
    }


    downloadFile(filename, fileurl, mimetype): void {
        this.http.downloadFile(fileurl, filename, mimetype);
    }


}
