import {MessageBase} from './message_base';
import {MessageType} from '../../model/messages/message-type';

export class MessageConfirm extends MessageBase {
    private onConfirm: () => void;

    constructor(message: string, title: string, onConfirm: () => void, oklabel: string, cancellabel: string) {
        super(MessageType.WARNING, message, title);

        this.onConfirm = onConfirm;

        //this.options.icon = 'fas fa-question-circle';
        this.options.hide = false;
        this.options.modules = {
            Confirm: {
                confirm: true,
            },
            Buttons: {
                closer: false,
                sticker: false
            },
            History: {
                history: false
            },
        };
        this.options.addclass = 'stack-modal';
        this.options.stack = {
            dir1: 'down', modal: true, firstpos1: 25
        };
    }


    show(): void {
        super.show();
        this.notify.on('pnotify.confirm', () => this.onConfirm());
    }

}
