import {BaseAction} from './base-action';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {Params, Router} from '@angular/router';
import {MessageError} from '../../services/messages/message-error';
import {Entity} from '../entities/entity';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';

export abstract class ItemsActionBase extends BaseAction {
    noSelectionAllowed = false;


    constructor(actionData: ActionData, router: Router, serviceHolder: RequestServiceHolder, entity: Entity,
                component: any, currentUrlReplacements: Params) {
        super(actionData, router, serviceHolder, entity, component, currentUrlReplacements);
    }

    protected checkConditions(): boolean {
        if (!this.noSelectionAllowed && this.ids.length === 0) {
            new MessageError('No selection', 'You have to select at least one item to do this action').show();

            return false;
        }

        return true;
    }
}
