import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {MenuItem} from '../../model/menu-item';
import {ActivatedRoute, Router} from '@angular/router';
import {SlideInOutAnimation} from '../animation';
import {GeneralConfigService} from '../../services/general-config.service';
import {ConfigStyle} from '../../model/remote/responses/structures/data/config/config-style';

/**
 * Created by angro on 12/05/2017.
 */

@Component({
    selector: '.menu-item',
    templateUrl: './menu-item.component.html',
    animations: [SlideInOutAnimation]

})
export class MenuItemComponent implements OnInit {

    private _item: MenuItem;

    @Output() activated: EventEmitter<void> = new EventEmitter<void>(true);
    @Output() activatedCategory: EventEmitter<MenuItem> = new EventEmitter<MenuItem>(true);

    @HostBinding('class.active') activeClass = false;
    @HostBinding('class.current-page') currentPageClass = false;

    constructor(private route: ActivatedRoute, private generalConfig: GeneralConfigService, private routerNavigate: Router) {

    }

    ngOnInit(): void {
        let url = this.route.snapshot.url.join('/');
        if (!this.item.isCategory() && url === this.item.url.substr(1)) {
            this.currentPageClass = true;
            this.item.submenuOpen = true;
            this.activated.emit();
        }
        if (!this.checkStyleOpen() && url === this.item.url) {
            this.currentPageClass = true;
            this.item.submenuOpen = true;

            this.activated.emit();
        }
        this.item.observableActiveClassItem.subscribe(next => {
            this.activeClass = next;
        });
    }

    private checkStyleOpen() {

        return this.generalConfig.style === ConfigStyle.NORMAL;
    }

    toggleShowDiv() {
        this.item.animationState = this.item.animationState === 'open' || this.item.animationState === 'open-child' ? 'close' : 'open';
    }

    get submenuOpen(): boolean {
        return this.item.submenuOpen;
    }

    set submenuOpen(value: boolean) {
        this.item.submenuOpen = value;
        this.item.observableActiveClassItem.next(value);

    }

    @Input() set item(value: MenuItem) {
        this._item = value;
    }

    onSubmenuClick(item: MenuItem) {
        this.toggleShowDiv();
        this.submenuOpen = !this.submenuOpen;
        this.activatedCategory.emit(item);

    }

    get item(): MenuItem {
        return this._item;
    }

    get iconClasses(): string {
        return 'fa ' + this.item.icon;
    }


    onChildActivated() {
        if (this.checkStyleOpen()) {
            this.item.animationState = 'open-child';
        }
        this.item.observableActiveClassItem.next(true);
        this.item.submenuOpen = true;

        this.activated.emit();
        if (window.innerWidth <= 991) {
            this.activatedCategory.emit();
        }

    }

    isVisible(): boolean {
        return this.generalConfig.style === ConfigStyle.NORMAL;
    }
}
