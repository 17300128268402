import {BaseResponse} from './base-response';
import {ResponseStructure} from './structures/response-structure';
import {MessageBase} from '../../../services/messages/message_base';
import {MessageType} from '../../messages/message-type';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';
import {Redirect} from './structures/redirect';

export class RestResponse<T> implements BaseResponse<T> {
    readonly status: number = 200;
    readonly message: string = null;
    readonly action: Redirect = null;
    readonly type: MessageType;
    readonly data: T;

    constructor(responseData: ResponseStructure<T>, private serviceHolder: RequestServiceHolder) {
        this.status = responseData.status;
        if (!this.status) {
            throw new Error('Malformed response. Status is required');
        }

        this.type = this.getType(responseData.type);
        this.message = responseData.message;
        this.data = responseData.data;
        if (responseData.action !== undefined && responseData.data !== null) {
            this.action = responseData.action;
        }
    }

    private getType(type: number): MessageType {
        if (type == -1) {
            if (this.isSuccess()) {
                return MessageType.SUCCESS;
            } else if (this.isError()) {
                return MessageType.ERROR;
            } else {
                return MessageType.WARNING;
            }
        }
        return type;
    }

    isSuccess(): boolean {
        return this.status >= 200 && this.status < 299;
    }

    isError(): boolean {
        return this.status >= 400 && this.status < 599;
    }

    shouldShowMessage(): boolean {
        return !!this.message || this.isError();
    }


    showMessage(): void {
        if (this.shouldShowMessage()) {
            new MessageBase(this.type, this.getMessage(), this.getDefaultTitle(), this.action, this.serviceHolder).show();
        }
    }


    private getMessage(): string {
        if (this.message) {
            return this.message;
        }

        return 'Unexpected error ' + this.status;
    }

    private static getProperty(jsonObj: any, name: string, defaultValue: any): any {
        if (name in jsonObj) {
            return jsonObj[name];
        }

        return defaultValue;
    }

    private getDefaultTitle() {
        switch (this.type) {
            case MessageType.SUCCESS:
                return this.serviceHolder.translateService.instant('message.success');
            case MessageType.WARNING:
                return this.serviceHolder.translateService.instant('message.warning');
            case MessageType.ERROR:
                return this.serviceHolder.translateService.instant('message.error');
            case MessageType.INFO:
                return this.serviceHolder.translateService.instant('message.info');

        }
    }
}
