import {InputBase} from './input-base';
import {Component} from '@angular/core';
import {ValueDate} from '../../../model/values/value-date';

@Component({
    selector: 'ol-input-date',
    templateUrl: './input-date.component.html'

})
export class InputDateComponent extends InputBase {
    onSelected(value) {
        (<ValueDate>this.value).selectedDate = value;
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }

    public onCancel() {
        this.value.value = '';
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }

    applyDaterangepicker(e: any) {
        (<ValueDate>this.value).selectedDate = {'start': e.picker.startDate, 'end': e.picker.endDate};
        this.inputValue = this.value.value;
        this.control.setValue(this.value.value);
    }

}
