import {InputBase} from './input-base';
import {Component} from '@angular/core';

@Component({
    selector: 'ol-input-radio',
    template:
    '<label for="{{column.name}}_{{option.id}}" *ngFor="let option of options" [formGroup]="form">' +
    '    <input type="radio" [formControlName]="formControlName" id="{{column.name}}_{{option.id}}" ' +
    '           [name]="formControlName" [value]="option.id"/> ' +
    '    {{option.value}} ' +
    '</label>'
})
export class InputRadioComponent extends InputBase {
    get options(): { id: number | string, value?: string }[] {
        return this.innerValue.column.values;

    }
}
