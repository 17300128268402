import {InputBase} from './input-base';
import {AfterViewInit, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ol-input-checkbox',
    template: '<input type="checkbox" [checked]="!!inputValue" (change)="touch()" [id]="htmlId"/> ' +
        '<label [for]="htmlId">{{visualName}}</label>'
})
export class InputCheckboxComponent extends InputBase implements AfterViewInit {
    ngAfterViewInit(): void {
        if (this.inputValue === '') {
            this.inputValue = false;
        }
        setTimeout(() => this.value.column.data.required = false);
    }

    constructor() {
        super();
        this.registerOnTouched(() => this.onChange());
    }

    onChange() {
        this.inputValue = !this.inputValue ? 1 : 0;
    }
}
