import {Component} from '@angular/core';
import {InputBase} from './input-base';

@Component({
    selector: 'ol-input-rich-text-editor',
    templateUrl: './input-rich-text-editor.component.html'

})
export class InputRichTextEditorComponent extends InputBase {

}
