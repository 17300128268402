export class EntityType {
    static readonly LIST = 'list';
    static readonly ADD = 'add';
    static readonly UPLOAD = 'upload';
    static readonly UPDATE = 'update';
    static readonly DELETE = 'delete';
    static readonly DELETE_MULTIPLE = 'delete_multiple';
    static readonly RELOAD_LIST = 'reload_list';
    static readonly PERMISSION_LIST = 'permission_list';
    static readonly GO_BACK = 'go_back';
    static readonly DETAIL = 'detail';
    static readonly IMAGE_CREATOR = 'image-creator';
    static readonly IMAGE_POSITION = 'image-position-selector';
    static readonly TABLE_CLASSIFIER = 'table-classifier';
    static readonly DASHBOARD = 'dashboard';
    static readonly MENU = 'menu';
    static readonly MENU_SELECTOR = 'menu-selector';
    static readonly SINGLE_RESOURCE = 'single_resource';
    static readonly IFRAME = 'iframe';
    static readonly INFO = 'info';
    static readonly DOWNLOAD = 'download';
    static readonly TOP_MENU = 'top_menu_selector';
    static readonly DIALOG_INFO = 'dialog_info';
    static readonly INBOX = 'inbox';
    static readonly FILEMANAGER = 'file_manager';
    static readonly FILEMANAGER_CREATE_FOLDER = 'file_manager_create_folder';
    static readonly FILEMANAGER_DELETE = 'file_manager_delete';
    static readonly FILEMANAGER_DOWNLOAD = 'file_manager_download';
    static readonly FILEMANAGER_MOVE = 'file_manager_move_to';
    static readonly FILEMANAGER_RENAME = 'file_manager_rename';
    static readonly FILEMANAGER_UPLOAD = 'file_manager_upload';
    static readonly FILEMANAGER_ZIP = 'file_manager_zip';

    static readonly INBOX_COMPOSE = 'inbox_compose';
    static readonly INBOX_GET = 'inbox_get';
    static readonly CREATE_FOLDER = 'create_folder';
    static readonly TREE_LIST = 'tree_list';
}
