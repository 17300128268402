import {EntityBase} from './entity-base';
import {EntityActionOptions} from '../remote/responses/structures/data/config/entity/options/entity-action-options';
import {GoBackComponent} from '../../components/go-back-component';
import {Params} from '@angular/router';

export class EntityGoBack extends EntityBase<EntityActionOptions> {
    get restMethod(): string {
        return this.options.method;
    }

    getMethodOnActionOk(component: GoBackComponent): () => any {
        return component.back;
    }


    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}
