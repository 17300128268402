import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Filter} from '../../model/columns/filter';
import {ColumnType} from '../../model/columns/column-type';

@Component({
    selector: '.ol-filter',
    templateUrl: './filter.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FilterComponent {
    @Input() selectable = false;
    @Input() actions = false;
    @Input() labelVisible = false;

    options: any = {
        'opens': 'left',
        locale: {
            cancelLabel: 'Clear'
        }
    };
    @Input() filters: Filter[] = [];

    constructor() {

    }

    readonlycheck(type) {
        return type === ColumnType.listquery;
    }

    checkStyle(filter: Filter) {
        return filter.value === '' ? 'disable-color-filter' : '';
    }
}
