import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Value} from '../../../model/values/value';
import {EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Column} from '../../../model/columns/column';

export abstract class InputBase implements OnInit {
    name: string;

    innerValue: Value;
    private changed: ((value: any) => void)[] = [];
    private touched: (() => void)[] = [];
    visualName: string;
    htmlId: string;
    @Input() form: FormGroup;
    @Input() placeholder = '';
    @Input() formSecondary: [FormGroup[], string[][]];
    control: FormControl;
    formControlName: string;
    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() escapePressed: EventEmitter<void> = new EventEmitter<void>();

    get column(): Column {
        return this.innerValue.column;
    }

    @Input()
    set value(value: Value) {
        this.innerValue = value;
        this.name = value.column.name;
        this.formControlName = this.column.formControlName;
        this.visualName = value.column.visualName;
        this.htmlId = 'input_' + value.column.cssName;
    }

    private createControl() {
        this.control = new FormControl(this.inputValue);

        if (this.innerValue.column.required) {
            this.control.setValidators(Validators.required);
        }

        this.innerValue.addOnValueChanged(() => {
            this.control.setValue(this.innerValue.value);
        });

        this.control.valueChanges.subscribe(value => this.inputValue = value);

        this.registerOnChange(value => {
            if (this.control.value !== value) {
                this.control.setValue(value);
            }
        });
        this.form.addControl(this.formControlName, this.control);

        this.createControlSecondary(this.formControlName, this.control);

    }

    ngOnInit(): void {
        if (this.innerValue === undefined) {
            throw new Error('Attribute value is required');
        }

        this.createControl();
        this.checkControl();

    }

    get value(): Value {
        return this.innerValue;
    }

    set inputValue(value: any) {
        if (this.innerValue.value !== value) {
            if (typeof value !== 'object') {
                this.innerValue.value = value;
            } else {
                this.innerValue.value = value.id;
            }
            this.changed.forEach(onChange => onChange(value));


        }
    }

    get inputValue(): any {
        return this.innerValue.value === null ? '' : this.innerValue.value;
    }

    registerOnChange(fn: (value: any) => void) {
        this.changed.push(fn);
    }

    registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    touch() {
        this.touched.forEach(onTouch => onTouch());
    }

    onEnterPressed() {
        this.enterPressed.emit();
    }

    onEscapePressed() {
        this.escapePressed.emit();
    }

    private createControlSecondary(formControlName: string, control: FormControl) {
        if (this.formSecondary !== null) {
            for (const values in this.formSecondary[1]) {
                if (this.formSecondary[1].hasOwnProperty(values)) {
                    for (const names in this.formSecondary[1][values]) {
                        if (this.formSecondary[1][values][names].replace('.', '__') === formControlName) {
                            this.formSecondary[0][values].setControl(formControlName, control);
                        }
                    }
                }
            }

        }
    }

    protected checkControl() {
        if (this.formSecondary !== null && this.formSecondary[0][this.formControlName] !== undefined) {
            this.check();
            let formcgroup: FormGroup = this.formSecondary[0][this.formControlName];
            for (const control in formcgroup.controls) {
                formcgroup.controls[control].valueChanges.subscribe(it => {
                    this.inputValue = '';
                    if (it === '') {
                        this.control.disable();
                    } else {
                        this.check();
                    }


                });

            }


        }
    }

    protected checkValitations() {

        if (this.formSecondary[0][this.formControlName] !== undefined) {
            if (this.formSecondary[0][this.formControlName].valid) {

                this.control.enable();
            } else {
                this.control.disable();
            }
        } else {
            this.control.enable();
        }
    }

    private check() {
        let formcgroup: FormGroup = this.formSecondary[0][this.formControlName];
        formcgroup.valid ? this.control.enable() : this.control.disable();

    }
}
