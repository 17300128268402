import {RequestBase} from './request-base';
import {ListData} from '../../../model/remote/responses/structures/data/list/list-data';
import {EntityResource} from '../../../model/entities/entity-resource';
import {ResponseStructure} from '../../../model/remote/responses/structures/response-structure';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {ListResponse} from '../../../model/remote/responses/list-response';
import {Filter} from '../../../model/columns/filter';
import {RequestServiceHolder} from '../request-service-holder';

export class ListRequest extends RequestBase<ListData> {

    constructor(serviceHolder: RequestServiceHolder, private entity: EntityResource, replacements: any) {
        super(entity.getRestUrl(replacements), entity.restMethod, serviceHolder);

        this.addRequestParams = true;
    }

    protected createResponse(data: ResponseStructure<ListData>): BaseResponse<ListData> {
        return new ListResponse(data, this.entity, this.serviceHolder);
    }

    get offset(): number {

        const value = this.getRequestValue('offset');

        return typeof value === 'undefined' ? 0 : value;
    }

    set page(value: number) {
        this.addRequestValue('offset', (value - 1) * this.limit);
    }

    get limit(): number {
        const value = this.getRequestValue('limit');

        return typeof value === 'undefined' ? 10 : value;
    }

    set limit(value: number) {
        this.addRequestValue('limit', value);
    }

    orderBy(fieldName: string, asc: boolean): void {
        fieldName = (asc ? 'asc_' : 'desc_') + fieldName;
        this.addRequestValue('sort', fieldName.replace('.', '__'));
    }

    addFilter(filter: Filter) {
        if (filter.value === '') {
            this.removeRequestValue(filter.paramName);
        } else {
            this.addRequestValue(filter.paramName, filter.value);
        }
    }
}
