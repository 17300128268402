import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {Entity} from './entity';
import {Url} from '../url';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {RequestBase} from '../../services/rest/requests/request-base';
import {Params} from '@angular/router';

/**
 * Created by angro on 09/05/2017.
 */
enum SaveButtons {
    BOTH, SAVE, SAVEEXIT,
}

export abstract class EntityBase<T extends BaseOptions> implements Entity {

    private _data: EntityData<T>;
    component: any;
    urlParams: Params;
    private _pagename: string;

    constructor(data: EntityData<T>, protected entitiesFinder: EntitiesFinderService) {
        this._data = data;
    }

    abstract get linkedEntitiesNames(): Params;

    abstract get requiredEntitiesNames(): string[];

    get geolocation(): Boolean {
        return this.data.geolocation;
    }
    get data(): EntityData<T> {
        return this._data;
    }

    get visualName() {
        return this.data.visualName;
    }

    set visualName(value) {
        this.data.visualName = value;
    }

    get options(): T {
        return this.data.options;
    }

    set options(value: T) {
        this.data.options = value;
    }

    get routePath(): string {
        let url = this.data.url;

        if (this.relativeRoutePath) {
            url += '/' + this.relativeRoutePath;
        }

        return url.replace(/\{([^\}]+)\}/, ':$1');
    }

    get name() {
        return this.data.name;
    }

    getRestUrl(replacements = {}): Url {
        let prefix = this.data.restPrefixUrl;
        if (typeof prefix === 'undefined') {
            prefix = RequestBase.restPrefixUrl;
        }
        var url = new Url([prefix, this.data.url], true, replacements);
        url.geolocation = this.data.geolocation;

        return url;
    }

    getVisualUrl(replacements = {}): Url {
        var url = new Url([this.data.url, this.relativeRoutePath], false, replacements);
        url.geolocation =  this.data.geolocation;
        return url;
    }

    getActionUrl(replacements = {}): Url {
        return this.entitiesFinder.getComponent(this) ? this.getVisualUrl(replacements) : this.getRestUrl(replacements);
    }

    get relativeRoutePath(): string {
        return '';
    }

    getMethodOnActionOk(component: any): (res) => any {
        return () => {
        };
    }

    abstract get restMethod(): string;

    get pageName(): string {
        return this._pagename;
    }

    setPageName(page: string) {
        this._pagename = page;
    }

    isSaveButton(): boolean {
        return this.data.options.saveButtonsVisibility === undefined || this.options.saveButtonsVisibility === SaveButtons.SAVE || this.options.saveButtonsVisibility === SaveButtons.BOTH;
    }

    isSaveAndExitButton(): boolean {
        return this.data.options.saveButtonsVisibility === undefined || this.options.saveButtonsVisibility === SaveButtons.SAVEEXIT || this.options.saveButtonsVisibility === SaveButtons.BOTH;
    }


}
