import {RequestBase} from './request-base';
import {Url} from '../../../model/url';
import {RequestServiceHolder} from '../request-service-holder';

export class ChangeRequest<T> extends RequestBase<T> {

    constructor(url: Url, method: string, serviceHolder: RequestServiceHolder) {
        super(url, method, serviceHolder);
        this.addDynamicParams();
    }

    private addDynamicParams() {
        const router = this.serviceHolder.router;
        const params = router.parseUrl(router.url).queryParams;

        for (const param in params) {
            if (!params.hasOwnProperty(param)) {
                continue;
            }

            this.addRequestValue(param, params[param]);
        }
    }
}