import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ResponseStructure} from '../../model/remote/responses/structures/response-structure';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from '../auth.service';
import {ActivatedRoute, Params} from '@angular/router';
import {BodyAttributesSetter} from '../body-attributes-setter.service';
import {RestRequest} from './requests/rest-request';
import {RestPreconditionsService} from './preconditions/rest-preconditions-service';
import {map} from 'rxjs/operators';

@Injectable()
export class RestService {
    private globalUrlParams: Params = {};
    private _globalRequestParams: Params = {};

    constructor(private http: HttpClient, private auth: AuthService, private bodyClassesSetter: BodyAttributesSetter,
                private activatedRoute: ActivatedRoute, private preconditionsService: RestPreconditionsService) {

    }

    addGlobalUrlParams(key: string, value: string) {
        this.globalUrlParams[key] = value;
    }

    execute<T>(request: RestRequest<T>): Observable<ResponseStructure<T>> {
        return new Observable<ResponseStructure<T>>((observer) => {
            if (request.afterPreconditions) {
                this.preconditionsService.onAllPreconditionsDone(() => {
                    this.doRequest(request, observer);
                });
            } else {
                this.doRequest(request, observer);
            }
        });
    }

    prepareRequest<T>(request: RestRequest<T>) {
        request.url.params = {...request.url.params, ...this.activatedRoute.snapshot.params};
        request.url.addUrlParams(this.globalUrlParams);

        console.info('Requesting \'' + request.url.path + '\'');

        if (request.addRequestParams) {
            this.addCurrentRequestParams<T>(request);
        }
    }


    private checkCookies<T>(request: RestRequest<T>) {
        for (const cookie in this.auth.cookies) {
            if (this.auth.cookies.hasOwnProperty(cookie)) {
                request.addRequestValue(cookie, this.auth.cookies[cookie]);
            }

        }
    }

    private doRequest<T>(request: RestRequest<T>, observer: Observer<ResponseStructure<T>>) {
        this.prepareRequest(request);
        this.checkCookies(request);
        const httpRequest = new HttpRequest<string>(request.type, request.url.path, request.body, {
            params: this.getHttpParams(request),
            headers: request.headers.set('X-Auth-Token', request.url.path.includes('login') ? '' : this.auth.sessionToken).set('lang', this.auth.lang)
        });
        let finalResult: HttpResponse<ResponseStructure<T>> = null;
        const response = this.http.request<ResponseStructure<T>>(httpRequest);
        let finalError: HttpErrorResponse;

        if (finalResult !== null) {
            observer.next(finalResult.body);
        } else if (typeof finalError !== 'undefined') {
            observer.error(finalError);
        } else {
            if (request.lockScreen === true) {
                this.bodyClassesSetter.loadingVisible = true;
            }

            response.subscribe((result: HttpEvent<ResponseStructure<T>>) => {
                if (result.type === HttpEventType.Response) {
                    finalResult = result as HttpResponse<ResponseStructure<T>>;
                    if (finalResult.headers.has('X-Auth-Token') && !request.transparent && !request.url.path.includes('api/lang')) {
                        this.auth.sessionToken = finalResult.headers.get('X-Auth-Token');
                    }

                    console.log(finalResult);
                    observer.next(finalResult.body);
                }
            }, (error: HttpErrorResponse) => {
                if (request.lockScreen === true) {
                    this.bodyClassesSetter.loadingVisible = false;
                }
                console.error(error);

                if (typeof finalError === 'undefined') {
                    finalError = error;
                }

                if (request.handle401Error && finalError.error.status === 401) {
                    this.auth.logout();
                    observer.complete();
                    window.location.reload();
                } else {
                    observer.error(finalError.error);
                }
            }, () => {
                if (request.lockScreen === true) {
                    this.bodyClassesSetter.loadingVisible = false;
                }
            });
        }
    }

    getHttpParams<T>(request: RestRequest<T>): HttpParams {
        let httpParams = request.httpParams;

        Object.keys(this._globalRequestParams).map(key => {
            httpParams = httpParams.append(key, this._globalRequestParams[key]);
        });

        return httpParams;
    }

    private addCurrentRequestParams<T>(request: RestRequest<T>): void {
        const params = this.activatedRoute.snapshot.queryParams;
        Object.keys(params).map(key => {
            if (typeof request.getRequestValue(key) === 'undefined') {
                request.addRequestValue(key, params[key]);
            }
        });
    }


    get globalRequestParams(): Params {
        return this._globalRequestParams;
    }

    addGlobalRequestParam(name: string, value: string) {
        this._globalRequestParams[name] = value;
    }

    public downloadFile(url, filename, mimetype) {
        return this.http
            .get(url, {
                responseType: 'blob',


            }).pipe(
                map(res => {
                    return {
                        filename: filename,
                        data: res.slice(0, res.size, mimetype)
                    };
                }))
            .subscribe(res => {
                console.log('start download:', res);
                var url = window.URL.createObjectURL(res.data);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = res.filename;
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove(); // remove the element
            }, error => {
                console.log('download error:', JSON.stringify(error));
            }, () => {
                console.log('Completed file download.');
            });
    }

}
