import {RequestBase} from './request-base';
import {SecondaryMenuData} from '../../../model/remote/responses/structures/data/menu/secondary-menu-data';
import {Url} from '../../../model/url';
import {RequestServiceHolder} from '../request-service-holder';

export class MenuRequest extends RequestBase<SecondaryMenuData> {

    constructor(url: Url, method: string, serviceHolder: RequestServiceHolder) {
        super(url, method, serviceHolder);

        this.afterPreconditions = false;
    }
}