import {Component, Input} from '@angular/core';
import {SummaryItemData} from '../../model/remote/responses/structures/data/list/summary-item-data';
import {GenericAction} from '../../model/actions/generic-action';
import {ActivatedRoute} from '@angular/router';
@Component({
    selector: '.summary',
    templateUrl: './summary.component.html'
})
export class SummaryComponent {
    @Input() summaryItems: SummaryItemData[];
    @Input() entity_name?: string;
    @Input() actions: GenericAction[];
    idsActions: any[];


    constructor(activatedRoute: ActivatedRoute) {
        activatedRoute.params.subscribe(params => this.idsActions = [params['id']]);
    }
}
