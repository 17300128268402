import {ActivatedRoute, Router} from '@angular/router';
import {EntityList} from '../../model/entities/entity-list';
import {EntityAdd} from '../../model/entities/entity-add';
import {EntityUpdate} from '../../model/entities/entity-update';
import {EntityData} from '../../model/remote/responses/structures/data/config/entity/entity-data';
import {ConfigData} from '../../model/remote/responses/structures/data/config/config-data';
import {Observable, ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {BaseOptions} from '../../model/remote/responses/structures/data/config/entity/options/base-options';
import {ListOptions} from '../../model/remote/responses/structures/data/config/entity/options/list-options';
import {EntityDelete} from '../../model/entities/entity-delete';
import {EntityReloadList} from '../../model/entities/entity-reload-list';
import {EntityActionOptions} from '../../model/remote/responses/structures/data/config/entity/options/entity-action-options';
import {GeneralConfigService} from '../general-config.service';
import {EntityPermissionList} from '../../model/entities/entity-permission-list';
import {EntitiesFinderService} from './entities-finder.service';
import {PermissionListOptions} from '../../model/remote/responses/structures/data/config/entity/options/permission-list-options';
import {EntityGoBack} from '../../model/entities/entity-go-back';
import {EntityDetail} from '../../model/entities/entity-detail';
import {EntityImageCreator} from '../../model/entities/entity-image-creator';
import {EntityTableClassifier} from '../../model/entities/entity-table-classifier';
import {TableClassifierOptions} from '../../model/remote/responses/structures/data/config/entity/options/table-classiffier-options';
import {ChangeOptions} from '../../model/remote/responses/structures/data/config/entity/options/change-options';
import {Entity} from '../../model/entities/entity';
import {TableComponent} from '../../components/table/table.component';
import {AddComponent} from '../../components/form/add.component';
import {EditComponent} from '../../components/form/edit.component';
import {DetailComponent} from '../../components/detail/detail.component';
import {ImageCreatorComponent} from '../../components/image-creator.component';
import {TableClassifierComponent} from '../../components/table/table-classifier.component';
import {EntityType} from '../../model/entities/entity-type';
import {EntityDashboard} from '../../model/entities/entity-dashboard';
import {DashboardComponent} from '../../components/dashboard/dashboard.component';
import {RequestServiceHolder} from '../rest/request-service-holder';
import {EntityMenu} from '../../model/entities/entity-menu';
import {MenuOptions} from '../../model/remote/responses/structures/data/config/entity/options/menu-options';
import {EntitySingleResource} from '../../model/entities/entity-single-resource';
import {BackendOptions} from '../../model/remote/responses/structures/data/config/entity/options/backend-options';
import {EntityDeleteMultiple} from '../../model/entities/entity-delete-multiple';
import {TranslateService} from '@ngx-translate/core';
import {EntityIframe} from '../../model/entities/entity-iframe';
import {IframeComponent} from '../../components/Iframe.component';
import {IframeOptions} from '../../model/remote/responses/structures/data/config/entity/options/iframe-options';
import {EntityImagePositionSelector} from '../../model/entities/entity-image-position-selector';
import {ImagePositionComponent} from '../../components/image-position.component';
import {ImagePositionOptions} from '../../model/remote/responses/structures/data/config/entity/options/image-position-options';
import {InfoOptions} from '../../model/remote/responses/structures/data/config/entity/options/info-options';
import {EntityInfo} from '../../model/entities/entity-info';
import {EntityDownload} from '../../model/entities/entity-download';
import {DownloadFileOptions} from '../../model/remote/responses/structures/data/config/entity/options/download-file-options';
import {EntityUploadFiles} from '../../model/entities/entity-upload-files';
import {UploadComponent} from '../../components/form/upload.component';
import {Column} from '../../model/columns/column';
import {CookiesService} from '../cookies.service';
import {MenuEntitySelectorComponent} from '../../components/menu-entity-selector.component';
import {InfoComponent} from '../../components/info.component';
import {EntityMenuSelector} from '../../model/entities/entity-menu-selector';
import {EntityDialogInfo} from '../../model/entities/entity-dialog-info';
import {InboxComponent} from '../../components/inbox.component';
import {EntityFileManager} from '../../model/entities/entity-file-manager';
import {FileManagerOptions} from '../../model/remote/responses/structures/data/file/file-manager-options';
import {FileManagerComponent} from '../../components/file-manager.component';
import {InboxOptions} from '../../model/remote/responses/structures/data/config/entity/options/inbox-options';
import {EntityInbox} from '../../model/entities/entity-inbox';
import {PermissionListComponent} from '../../components/permission-list.component';
import {EntityTreeList} from '../../model/entities/entity-tree-list';
import {TreeOptions} from '../../model/remote/responses/structures/data/config/entity/options/tree-options';
import {TreeListComponent} from '../../components/tree-list.component';

/**
 * Created by angro on 21/05/2017.
 */
@Injectable()
export class EntitiesHandlerService implements EntitiesFinderService {
    private _entities: ReplaySubject<Entity> = null;
    private _columnsTop: ReplaySubject<Column> = null;
    private components: { [entityName: string]: any } = {};
    entitiesNames: string[] = [];

    constructor(private serviceHolder: RequestServiceHolder, private generalConfig: GeneralConfigService, private router: Router, private translateService: TranslateService,
                private activatedrouter: ActivatedRoute, private cookieservice: CookiesService) {
        this.components[EntityList.name] = TableComponent;
        this.components[EntityAdd.name] = AddComponent;
        this.components[EntityUploadFiles.name] = UploadComponent;
        this.components[EntityUpdate.name] = EditComponent;
        this.components[EntityPermissionList.name] = PermissionListComponent;
        this.components[EntityDetail.name] = DetailComponent;
        this.components[EntityImageCreator.name] = ImageCreatorComponent;
        this.components[EntityImagePositionSelector.name] = ImagePositionComponent;
        this.components[EntityTableClassifier.name] = TableClassifierComponent;
        this.components[EntityDashboard.name] = DashboardComponent;
        this.components[EntityIframe.name] = IframeComponent;
        this.components[EntityInfo.name] = InfoComponent;
        this.components[EntityInbox.name] = InboxComponent;
        this.components[EntityFileManager.name] = FileManagerComponent;
        this.components[EntityTreeList.name] = TreeListComponent;
        this.components['menu'] = MenuEntitySelectorComponent;
        this.serviceHolder.entitiesFinder = this;
    }

    findEntityByName(entityName?: string): Observable<Entity> {
        if (!entityName) {
            console.error('Incorrect entity: ' + entityName);
        }

        return new Observable<Entity>((observer) => {
            this.entities.subscribe(item => {
                if (item.name === entityName) {
                    observer.next(item);
                    observer.complete();
                }
            }, (error) => {
                observer.error(error);
            }, () => {
                observer.complete();
            });
        });
    }

    private createEntities() {
        if (this._entities !== null) {
            return;
        }

        this._entities = new ReplaySubject();

        this.generalConfig.config.subscribe((config: ConfigData) => {
            for (const entityData of config.entities) {
                if (entityData.type !== EntityType.TOP_MENU) {
                    this.entitiesNames.push(entityData.name);
                    this._entities.next(this.getTemplate(entityData));
                }
            }
            this._entities.complete();
        }, error => {
            this._entities.error(error);
        });

    }

    private getTemplate(entityData: EntityData<BaseOptions>): any {
        switch (entityData.type) {
            case EntityType.LIST:
                return new EntityList(entityData as EntityData<ListOptions>, this.serviceHolder, this.translateService);
            case EntityType.ADD:
                return new EntityAdd(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.INBOX_COMPOSE:
                return new EntityAdd(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.INBOX_GET:
                return new EntityList(entityData as EntityData<ListOptions>, this.serviceHolder, this.translateService);
            case EntityType.UPLOAD:
                return new EntityUploadFiles(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.UPDATE:
                return new EntityUpdate(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.DELETE:
                return new EntityDelete(entityData, this);
            case EntityType.DIALOG_INFO:
                return new EntityDialogInfo(entityData, this);
            case EntityType.DELETE_MULTIPLE:
                return new EntityDeleteMultiple(entityData, this);
            case EntityType.RELOAD_LIST:
                return new EntityReloadList(entityData as EntityData<EntityActionOptions>, this);
            case EntityType.DOWNLOAD:
                return new EntityDownload(entityData as EntityData<DownloadFileOptions>, this);
            case EntityType.PERMISSION_LIST:
                return new EntityPermissionList(entityData as EntityData<PermissionListOptions>, this.serviceHolder, this.generalConfig);
            case EntityType.GO_BACK:
                return new EntityGoBack(entityData as EntityData<EntityActionOptions>, this);
            case EntityType.DETAIL:
                return new EntityDetail(entityData, this.serviceHolder);
            case EntityType.IMAGE_CREATOR:
                return new EntityImageCreator(entityData, this);
            case EntityType.TABLE_CLASSIFIER:
                return new EntityTableClassifier(entityData as EntityData<TableClassifierOptions>, this.serviceHolder, this.router, this.translateService);
            case EntityType.INFO:
                return new EntityInfo(entityData as EntityData<InfoOptions>, this.serviceHolder);
            case EntityType.INBOX:
                return new EntityInbox(entityData as EntityData<InboxOptions>, this.serviceHolder);
            case EntityType.FILEMANAGER:
                return new EntityFileManager(entityData as EntityData<FileManagerOptions>, this.serviceHolder);
            case EntityType.FILEMANAGER_CREATE_FOLDER:
                return new EntityAdd(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.FILEMANAGER_DELETE:
                return new EntityDelete(entityData, this);
            case EntityType.FILEMANAGER_DOWNLOAD:
                return new EntityDownload(entityData as EntityData<DownloadFileOptions>, this);
            case EntityType.FILEMANAGER_MOVE:
                return new EntityUpdate(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.FILEMANAGER_RENAME:
                return new EntityUpdate(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.FILEMANAGER_UPLOAD:
                return new EntityUploadFiles(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.FILEMANAGER_ZIP:
                return new EntityDownload(entityData as EntityData<DownloadFileOptions>, this);
            case EntityType.DASHBOARD:
                return new EntityDashboard(entityData, this.serviceHolder);
            case EntityType.MENU:
                return new EntityMenu(entityData as EntityData<MenuOptions>, this, this.serviceHolder);
            case EntityType.SINGLE_RESOURCE:
                return new EntitySingleResource(entityData as EntityData<BackendOptions>, this.serviceHolder, this.translateService);
            case EntityType.IFRAME:
                return new EntityIframe(entityData as EntityData<IframeOptions>, this.serviceHolder.entitiesFinder);
            case EntityType.IMAGE_POSITION:
                return new EntityImagePositionSelector(entityData as EntityData<ImagePositionOptions>, this.serviceHolder);
            case EntityType.MENU_SELECTOR:
                return new EntityMenuSelector(entityData, this.serviceHolder);
            case EntityType.CREATE_FOLDER:
                return new EntityAdd(entityData as EntityData<ChangeOptions>, this.serviceHolder, this.translateService, this.activatedrouter);
            case EntityType.TREE_LIST:
                return new EntityTreeList(entityData as EntityData<TreeOptions>, this.serviceHolder, this.translateService);
        }

        throw new Error('Entity template ' + entityData.type + ' not found');
    }

    getComponent(entity: Entity): any {
        if (entity.constructor.name in this.components) {
            return this.components[entity.constructor.name];
        }

        return undefined;
    }

    getComponentByName(name: string): any {
        return this.components[name];

    }

    get entities(): Observable<Entity> {
        this.createEntities();
        return this._entities;
    }

    get columnsTop(): Observable<Column> {
        this.createColumnsTop();
        return this._columnsTop;
    }

    private createColumnsTop() {
        if (this._columnsTop !== null) {
            return;
        }
        this._columnsTop = new ReplaySubject();
        this.generalConfig.config.subscribe((config: ConfigData) => {
            for (const entityData of config.entities) {
                if (entityData.type === EntityType.TOP_MENU) {
                    for (const column of entityData.options['columns']) {
                        const cooki = this.cookieservice.getCookie(column.name);
                        this.serviceHolder.restService.addGlobalRequestParam(column.name, cooki === '' ? column.values[0]['id'] : cooki);
                    }
                    this._columnsTop.next(entityData.options['columns']);

                }
            }
            this._columnsTop.complete();
        }, error => {
            this._columnsTop.error(error);
        });

    }
}
