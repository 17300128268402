import {TableClassifierComponent} from '../../components/table/table-classifier.component';
import {EntityList} from './entity-list';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {TableClassifierOptions} from '../remote/responses/structures/data/config/entity/options/table-classiffier-options';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {Router} from '@angular/router';
import {SelectionAction} from '../actions/selection-action';
import {Observable} from 'rxjs';
import {MessageSuccess} from '../../services/messages/message-success';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';

export class EntityTableClassifier extends EntityList {
    constructor(data: EntityData<TableClassifierOptions>, protected serviceHolder: RequestServiceHolder, private router: Router, translateService: TranslateService) {
        super(data, serviceHolder, translateService);
    }

    get options(): TableClassifierOptions {
        return <TableClassifierOptions>this.data.options;
    }

    createAction(method: () => void, component: TableClassifierComponent): Observable<SelectionAction> {
        const data: ActionData = {
            entity_name: this.options.targetEntityName,
            visualName: 'Next',
            onlyIcon: false,
            icon: '',
            askMessage: '',
            style: 'primary'
        };

        return new Observable<SelectionAction>(observer => {
            this.entitiesFinder.findEntityByName(this.options.targetEntityName).subscribe((targetEntity) => {
                const action = new SelectionAction(data, this.router, this.serviceHolder, targetEntity, component, this.urlParams);

                action.noSelectionAllowed = true;
                action.actionMethod = method;

                observer.next(action);
            });
        });
    }

    onFinished() {
        new MessageSuccess('Data sent successfully', 'Sent').show();
        this.entitiesFinder.findEntityByName(this.options.finishRedirectEntityName).subscribe((entity) => {
            this.router.navigateByUrl(entity.getVisualUrl().path);
        });
    }
}
