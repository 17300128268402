import {animate, group, state, style, transition, trigger} from '@angular/animations';

export const SlideInOutAnimation = [
    trigger('slideInOut', [
        state('open', style({
            'max-height': '1000px', 'opacity': '1', 'visibility': 'visible', 'display': 'block'
        })),
        state('open-child', style({
            'max-height': '1000px', 'opacity': '1', 'visibility': 'visible', 'display': 'block'
        })),
        state('close', style({
            'max-height': '0px', 'opacity': '0', 'visibility': 'hidden', 'display': 'none'
        })),
        transition('open => close', [group([
                animate('100ms ease-in-out', style({
                    'opacity': '0'
                })),
                animate('400ms ease-in-out', style({
                    'height': '0px'
                })),
                animate('100ms ease-in-out', style({
                    'visibility': 'hidden'
                })),
                animate('600ms ease-in-out', style({
                    'display': 'none'
                }))
            ]
        )]),
        transition('open-child => close', [group([

                animate('200ms ease-in-out', style({
                    'opacity': '0'
                })),
                animate('400ms ease-in-out', style({
                    'height': '0px'
                })),
                animate('200ms ease-in-out', style({
                    'visibility': 'hidden'
                })),
                animate('400ms ease-in-out', style({
                    'display': 'none'
                }))

            ]
        )]),
        transition('close => open', [group([
                animate('0ms ease-in-out', style({
                    'display': 'block'
                })),
                animate('1ms ease-in-out', style({
                    'visibility': 'visible'
                })),
                animate('800ms ease-in-out', style({
                    'max-height': '1000px'
                })),
                animate('800ms ease-in-out', style({
                    'opacity': '1'
                }))
            ]
        )]),
        transition('close => open-child', [group([
                animate('0ms ease-in-out', style({
                    'display': 'block'
                })),
                animate('1ms ease-in-out', style({
                    'visibility': 'visible'
                })),
                animate('1ms ease-in-out', style({
                    'max-height': '1000px'
                })),
                animate('1ms ease-in-out', style({
                    'opacity': '1'
                }))
            ]
        )])
    ]),
];
