import {Component, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Column} from '../../model/columns/column';
import {EntitiesHandlerService} from '../../services/entities/entities-handler.service';
import {ConfigStyle} from '../../model/remote/responses/structures/data/config/config-style';
import {GeneralConfigService} from '../../services/general-config.service';

/**
 * Created by angro on 10/05/2017.
 */

@Component({
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: []
})
export class HomeComponent {
    loadingVisible = false;
    top_selector: Column;

    constructor(private auth: AuthService, bodyClassesSetter: BodyAttributesSetter, private router: Router, private entities: EntitiesHandlerService, private generalConfig: GeneralConfigService) {
        bodyClassesSetter.inHome = true;

        if (generalConfig.style !== ConfigStyle.NORMAL) {
            bodyClassesSetter.setBodyClass('nav-sm', true);
            bodyClassesSetter.setBodyClass('nav-md', false);
        }

        entities.columnsTop.subscribe(it => {
            this.top_selector = it;
        });
    }

    public logout() {
        this.auth.loggedIn = false;
    }

    goHome() {
        this.router.navigateByUrl('/');
    }
}
