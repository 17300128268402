import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    templateUrl: 'put-name-dialog-component.html'
})
export class PutNameDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, placeholder: string }) {
    }

    name: string;
}

