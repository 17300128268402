import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Item} from '../../model/item';
import {IndividualAction} from '../../model/actions/individual-action';

@Component({
    templateUrl: 'action-dialog.component.html'
})
export class ActionDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { item: Item, _entity_name: any, actions: IndividualAction[] }) {
    }

    isHidden(action: IndividualAction): boolean {
        if (action.data.visibilityColumn !== undefined && action.data.visibilityColumn !== null) {
            return !this.data.item.getValue(action.data.visibilityColumn);
        } else {
            return false;
        }
    }


}

