import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem} from '../model/menu-item';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {Location} from '@angular/common';

@Component({
    selector: 'ol-menu-entity-selector',
    templateUrl: 'menu-entity-selector.component.html'
})
export class MenuEntitySelectorComponent implements OnInit {
    menu: MenuItem[];
    back: boolean;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private boddy: BodyAttributesSetter, private location: Location) {
        this.menu = activatedRoute.snapshot.data['menu'];
        this.back = activatedRoute.snapshot.data['back'];

    }

    sendToMenu(menuItem: MenuItem) {
        if (menuItem.numberChildren === 0) {
            menuItem.entity.subscribe(entity => {
                this.router.navigateByUrl(entity.getActionUrl().path);

            });
        } else {
            this.router.navigateByUrl(menuItem.url);
        }
    }

    ngOnInit(): void {
    }

    rowClass(): string {
        return 'row menu' + (this.menu.length < 4 ? ' justify-content-center' : '');
    }

    numberItemsClass(menuItem: MenuItem): string {
        return 'col-6 col-sm-4 col-md-3' + (this.menu.length <= 2 ? (' col-md-offset-' + (this.menu.indexOf(menuItem) + 1)) : '');
    }

    getStyleHeight() {
        return {'margin-top': '0px', 'margin-bottom': '150px'};
    }

    backBtn() {
        this.location.back();
    }
}
