import {Value} from './value';
import {Column} from '../columns/column';

export class ValueImage extends Value {


    constructor(column: Column, value: any) {
        super(column, value);
    }
}
