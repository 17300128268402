import {Url} from './url';
import {Params} from '@angular/router';
import {CellLinkData} from './remote/responses/structures/data/list/cell-link-data';
import {Entity} from './entities/entity';
import {ListColumnLink} from './remote/responses/structures/data/config/entity/options/list-column-link';
import {Item} from './item';
import {RequestBase} from '../services/rest/requests/request-base';
import {RequestServiceHolder} from '../services/rest/request-service-holder';

export class ListLink {
    url: Url;
    method: string;
    icon?: string;
    tooltip?: string;
    params?: Params;
    readonly withReplacements: boolean;

    constructor(entity: Entity, linkData: ListColumnLink, link: CellLinkData, item: Item) {
        const replacements = this.getUrlReplacements(linkData.foreignColumnName, link, item);

        this.url = entity.getActionUrl(replacements);
        this.method = entity.restMethod;
        this.icon = link.icon;
        this.params = link.linkValues;
        this.tooltip = !!link.tooltip ? link.tooltip : '';
        this.withReplacements = !!replacements;
    }

    get replacementsRequired(): boolean {
        return this.url.path.indexOf('{') !== -1;
    }

    private getUrlReplacements(foreignField: any, link: CellLinkData, item: Item): Params {
        if (!!link.replacements && Object.getOwnPropertyNames(link.replacements).length > 0) {
            return link.replacements;
        }

        let replacements;

        if (foreignField) {
            const id = item.getValue(foreignField);

            if (id) {
                replacements = {id};
            }
        }

        return replacements;
    }

    execute(serviceHolder: RequestServiceHolder) {
        if (this.url.rest) {
            const request = new RequestBase(this.url, this.method, serviceHolder);

            request.addRequestValues(this.params);

            request.execute().subscribe();
        }
        else {
            this.url.queryParams = {...this.url.queryParams, ...this.params};

            serviceHolder.router.navigateByUrl(this.url.path);
        }
    }
}