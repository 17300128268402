import {RestRequest} from './rest-request';
import {Observable, Observer} from 'rxjs';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {BodyFormat} from '../../../model/remote/request/body-formats/body-format';
import {UrlEncoded} from '../../../model/remote/request/body-formats/url-encoded';
import {Url} from '../../../model/url';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {ResponseStructure} from '../../../model/remote/responses/structures/response-structure';
import {RestResponse} from '../../../model/remote/responses/rest-response';
import {RequestServiceHolder} from '../request-service-holder';
import {Redirect} from '../../../model/remote/responses/structures/redirect';
import {Params} from '@angular/router';
import {MessageError} from '../../messages/message-error';

export class RequestBase<T> implements RestRequest<T> {

    static restPrefixUrl: string;
    private static globalHeaders: { [name: string]: string };
    bodyData: any = {};

    handle401Error: boolean = true;
    protected bodyFormatter: BodyFormat = new UrlEncoded();

    type = 'GET';
    url: Url;
    lockScreen = true;
    transparent = false;
    addRequestParams = false;
    afterPreconditions = true;
    private lastResponse: BaseResponse<T>;
    private error: boolean;

    constructor(url: Url, method: string, protected serviceHolder: RequestServiceHolder) {
        this.url = url;
        this.type = method;
        this.bodyData['XDEBUG_SESSION_START'] = 'PHPSTORM';

        /*        this.locationService.getIpAddress().subscribe(it => console.log(it['ip']));*/

    }

    static addGlobalHeaders(headers: { [name: string]: string }) {
        RequestBase.globalHeaders = {...RequestBase.globalHeaders, ...headers};
    }


    addRequestValues(values: { [name: string]: any }) {
        for (const name in values) {
            if (values.hasOwnProperty(name)) {
                this.addRequestValue(name, values[name]);
            }
        }
    }

    get params(): Params {
        if (this.type === 'GET' || this.type === 'DELETE') {
            return this.url.queryParams;
        } else {
            return this.bodyData;
        }
    }


    addRequestValue(name: string, value: any): void {
        if (!value && value !== 0) {
            value = '';
        }

        name = name.replace('.', '__');

        this.params[name] = value;
    }

    removeRequestValue(name: string) {
        name = name.replace('.', '_');
        if (name in this.params) {
            delete this.params[name];
        }
    }

    getRequestValue(name: string): any {
        name = name.replace('.', '_');
        return name in this.params ? this.params[name] : undefined;
    }

    get body(): string {
        if (!this.bodyAllowed) {
            return null;
        }

        return this.bodyFormatter.format(this.bodyData);
    }

    get bodyAllowed(): boolean {
        return this.type != 'GET' && this.type != 'DELETE';
    }

    clear(): void {
        this.lastResponse = undefined;
    }

    execute(): Observable<BaseResponse<T>> {
        var error = false;
        return new Observable((observer: Observer<BaseResponse<T>>) => {
            if (this.lastResponse !== undefined) {
                if (!this.error) {
                    observer.next(this.lastResponse);
                } else {
                    observer.error(this.lastResponse);
                }
            } else {
                this.serviceHolder.locationService.getPosition().then(pos => {
                    RequestBase.addGlobalHeaders({'longitude': pos.lng.toString()});
                    RequestBase.addGlobalHeaders({'latitude': pos.lat.toString()});
                }, onreject => {
                    if (this.url.geolocation) {
                        new MessageError(this.serviceHolder.translateService.instant('location-error'), this.serviceHolder.translateService.instant('message.warning')).show();
                        observer.error('Location required');
                        error = true;
                        observer.complete();
                    }
                }).finally(() => {
                    if (!error) {
                        this.serviceHolder.restService.execute(this).subscribe((result: ResponseStructure<T>) => {
                                this.lastResponse = this.createResponse(result);
                                this.error = false;

                                if (this.lastResponse.shouldShowMessage()) {
                                    this.lastResponse.showMessage();
                                }

                                if (this.shouldDoRedirect(result)) {
                                    this.doRedirect(result.redirect);
                                } else {
                                    observer.next(this.lastResponse);
                                }

                                observer.complete();
                            },
                            (errorData: ResponseStructure<any>) => {
                                this.lastResponse = new RestResponse<any>(errorData, this.serviceHolder);
                                this.error = true;

                                if (this.lastResponse.shouldShowMessage()) {
                                    this.lastResponse.showMessage();
                                }

                                observer.error(this.lastResponse);
                            });
                    }

                });

            }
        });
    }

    private shouldDoRedirect(response: ResponseStructure<T>) {
        return !!response.redirect && !!response.redirect.entity_name;
    }

    private doRedirect(redirect: Redirect) {
        this.serviceHolder.entitiesFinder.findEntityByName(redirect.entity_name).subscribe(entity => {
            const url = entity.getVisualUrl({id: redirect.id});
            url.params = {id: redirect.id};
            url.queryParams = redirect.params;

            this.serviceHolder.router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(() =>
                this.serviceHolder.router.navigateByUrl(url.path));
        });
    }

    get httpParams(): HttpParams {
        let params = new HttpParams();

        if (!this.bodyAllowed) {
            for (let key in this.bodyData) {
                params = params.set(key, this.getRequestValue(key));
            }
        }

        return params;
    }

    get headers(): HttpHeaders {

        return new HttpHeaders({...RequestBase.globalHeaders, ...{'Content-Type': this.bodyFormatter.contentType}, ...{'test': '333'}});
    }

    protected createResponse(data: ResponseStructure<T>): BaseResponse<T> {
        return new RestResponse<T>(data, this.serviceHolder);
    }


}
