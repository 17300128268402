/**
 * Created by angro on 10/05/2017.
 */

import {NgModule} from '@angular/core';
import {HomeComponent} from '../components/home/home.component';
import {RouterModule} from '@angular/router';
import {MenuComponent} from '../components/home/menu.component';
import {CommonModule} from '@angular/common';
import {MenuItemComponent} from '../components/home/menu-item.component';
import {HomeTopComponent} from '../components/home/home-top.component';
import {GeneralConfigService} from '../services/general-config.service';
import {EntitiesHandlerService} from '../services/entities/entities-handler.service';
import {TopSecondaryMenuComponent} from '../components/home/secondary-menu/top-secondary-menu.component';
import {NotificationsComponent} from '../components/home/notifications.component';
import {TopSecondaryMenuItemComponent} from '../components/home/secondary-menu/top-secondary-menu-item.component';
import {SharedModule} from './shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {MenuSelectorComponent} from '../components/home/menu-selector.component';
import {BackendModule} from './backend.module';
import {MatBadgeModule, MatIconModule} from '@angular/material';

@NgModule({
    declarations: [
        HomeComponent,
        MenuComponent,
        MenuSelectorComponent,
        MenuItemComponent,
        HomeTopComponent,
        TopSecondaryMenuComponent,
        TopSecondaryMenuItemComponent,
        NotificationsComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        BackendModule,
        MatIconModule,
        MatBadgeModule
    ],
    providers: [GeneralConfigService, EntitiesHandlerService]
})
export class HomeModule {
}
