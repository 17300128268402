import {Component, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

/**
 * Created by angro on 09/05/2017.
 */

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent {
    private _itemsPerPage = 10;
    pages: number[] = [];
    private _total = 0;
    @Output()
    pageChanged: EventEmitter<number> = new EventEmitter();
    private _currentPage = 1;
    totalPages = 0;
    _entityName = '';

    @Input()
    set total(value: number) {
        if (this._total == value) {
            return;
        }

        this._total = value;
        if (this._total > 0) {
            this.totalPages = Math.ceil(this._total / this._itemsPerPage);
        }

        this.createPagination();
    }

    @Input()
    set entityName(entityname: string) {
        this._entityName = entityname;
    }

    private createPagination() {
        this.totalPages = Math.ceil(this._total / this._itemsPerPage);

        this.pages = [];

        let start: number = Math.max(1, this.currentPage - 1);
        let end: number = Math.min(this.totalPages, this.currentPage + 1);

        if (this.currentPage < 5 || this.totalPages == 5) {
            start = 1;
            end = Math.min(5, this.totalPages);
        } else if (this.currentPage > this.totalPages - 4) {
            start = Math.max(1, this.totalPages - 3);
            end = this.totalPages;
        }

        if (start !== 1) {
            this.pages.push(1);
            this.pages.push(0);
        }

        for (let i = start; i <= end; i++) {
            this.pages.push(i);
        }

        if (end !== this.totalPages) {
            this.pages.push(0);
            this.pages.push(this.totalPages);
        }
    }

    public getClasses(page: number) {
        const classes = {
            paginate_button: true,
            // page-item: true,
            disabled: page == 0,
            active: page == this.currentPage
        };

        return classes;
    }

    @Input()
    set itemsPerPage(value: number) {
        this._itemsPerPage = value;
        this.currentPage = this._currentPage;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    @Input()
    set initialPage(value: number) {
        this._currentPage = value;
    }

    set currentPage(page: number) {
        if (page < 1 || page > this.totalPages) {
            return;
        }

        this.createPagination();

        if (page != this._currentPage) {
            this.pageChanged.emit(page);
        }
        this._currentPage = page;
    }


}
