/**
 * Created by angro on 09/05/2017.
 */

import {Injectable} from '@angular/core';
import {EntityBase} from '../model/entities/entity-base';
import {Observable} from 'rxjs';
import {ConfigData} from '../model/remote/responses/structures/data/config/config-data';
import {RequestBase} from './rest/requests/request-base';
import {ConfigRequest} from './rest/requests/config-request';
import {BaseResponse} from '../model/remote/responses/base-response';
import {RestService} from './rest/rest.service';
import {ConfigStyle} from '../model/remote/responses/structures/data/config/config-style';
import {LocationService} from './location.service';


@Injectable()
export class GeneralConfigService {


    private _entities: EntityBase<any>[] = [];
    private _configData: ConfigData;

    constructor(private request: ConfigRequest, private restService: RestService, private locationService: LocationService,) {
    }


    get config(): Observable<ConfigData> {
        return new Observable<ConfigData>((observer) => {
            if (this._configData) {
                observer.next(this._configData);
                observer.complete();
            } else {
                this.request.execute().subscribe((response: BaseResponse<ConfigData>) => {
                    this.configData = response.data;
                    this.locationService.askLocation = response.data.request_location ? response.data.request_location : false;
                    observer.next(this._configData);
                    observer.complete();
                }, error => observer.error(error));
            }
        });
    }

    private set configData(value: ConfigData) {
        RequestBase.restPrefixUrl = value.restPrefixUrl;
        RequestBase.addGlobalHeaders(value.headers);
        this._configData = value;
    }

    set entities(value: EntityBase<any>[]) {
        this._entities = value;
    }

    getEntity(name: string): EntityBase<any> {
        for (let entity of this.entities) {
            if (entity.name == name) {
                return entity;
            }
        }

        throw new Error('Entity not found => ' + name);
    }

    get entities(): EntityBase<any>[] {
        return this._entities;
    }

    get style(): string {
        if (this._configData === undefined || this._configData.style === undefined) {
            return ConfigStyle.NORMAL;
        }
        switch (this._configData.style) {
            case ConfigStyle.NORMAL:
                return ConfigStyle.NORMAL;
            case ConfigStyle.ADVANCED:
                return ConfigStyle.ADVANCED;
            default:
                return ConfigStyle.NORMAL;
        }
    }

}
