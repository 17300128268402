import {MessageType} from '../../model/messages/message-type';
import {RequestServiceHolder} from '../rest/request-service-holder';
import {Redirect} from '../../model/remote/responses/structures/redirect';

import PNotify from 'pnotify/dist/es/PNotify';
import PNotifyButtons from 'pnotify/dist/es/PNotifyButtons';
import PNotifyConfirm from 'pnotify/dist/es/PNotifyConfirm';
import PNotifyStyleMaterial from 'pnotify/dist/es/PNotifyStyleMaterial';

export class MessageBase {
    protected options: {
        title: string,
        text: string,
        styling: string
        type: string,
        icon?: string,
        icons?: string,
        hide?: boolean,
        modules?: {
            Confirm?: {
                confirm?: boolean
                buttons?: any
            },
            Animate?: {
                animate?: boolean,
                inClass?: string,
                outClass?: string
            },
            Buttons?: {
                closer?: boolean,
                sticker?: boolean,
                labels?: {}
            },
            History?: {
                history?: boolean,
                maxInStack?: number
            },
        },
        addclass?: string,
        stack?: { dir1?: string, firstpos1?: number, modal?: boolean },
    } = {
        text: '',
        title: '',
        modules: {
            Confirm: {}, Animate: {
                animate: true,
                inClass: 'bounceInDown',
                outClass: 'hinge'
            },
            History: {
                maxInStack: 5
            }
        },
        styling: 'material',
        icons: 'material',
        type: 'notice',

    };

    protected notify;

    constructor(type: MessageType, message: string, title: string = '', action: Redirect = null, serviceHolder: RequestServiceHolder = null) {
        // tslint:disable-next-line:no-unused-expression
        PNotifyButtons;
        // tslint:disable-next-line:no-unused-expression
        PNotifyConfirm;
        // tslint:disable-next-line:no-unused-expression
        PNotifyStyleMaterial;

        this.options.type = MessageBase.getPNotifyType(type);

        this.options.text = message;
        this.options.title = title;
        this.options.modules.Confirm = {confirm: action !== null};
        if (action !== null) {
            this.options.modules.Confirm.buttons = [{
                text: action.button_name !== null ? action.button_name : 'Ok',
                textTrusted: false,
                addClass: 'btn',
                primary: true,
                promptTrigger: true,
                click: (notice, value) => {
                    serviceHolder.entitiesFinder.findEntityByName(action.entity_name).subscribe(next => {
                        const url = next.getActionUrl([{id: action.id}]);
                        url.queryParams = action.params;
                        serviceHolder.router.navigateByUrl(next.getActionUrl([{id: action.id}]).path);
                    });
                }
            },
                {
                    text: 'Cancel',
                    textTrusted: false,
                    addClass: 'hidden'
                }];
        }


    }


    private static getPNotifyType(type: MessageType): string {
        switch (type) {
            case MessageType.SUCCESS:
                return 'success';
            case MessageType.WARNING:
                return 'notice';
            case MessageType.ERROR:
                return 'error';
            case MessageType.INFO:
                return 'info';
        }
    }

    setPositionCenter() {
        this.options.stack = {
            dir1: 'down', modal: false, firstpos1: 25
        };
        return this;
    }

    show(): void {
        this.notify = PNotify.alert(this.options);

    }
}
