import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {IframeOptions} from '../model/remote/responses/structures/data/config/entity/options/iframe-options';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';
import {EntityBase} from '../model/entities/entity-base';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {RequestBase} from '../services/rest/requests/request-base';

@Component({
    templateUrl: './iframe.component.html',
})
export class IframeComponent {
    private _entity: EntityBase<IframeOptions>;
    iframe: SafeResourceUrl;
    enabledIframe = false;

    constructor(protected activedrouter: ActivatedRoute, private _sanitizer: DomSanitizer, private bodyClassesSetter: BodyAttributesSetter, serviceholder: RequestServiceHolder) {
        this._entity = activedrouter.snapshot.data['entity'];
        console.log('llega');
        bodyClassesSetter.loadingVisible = true;
        if (this._entity.options.url !== undefined) {
            this.enabledIframe = true;
            this.iframe = _sanitizer.bypassSecurityTrustResourceUrl(this._entity.options.url); this.enabledIframe = true;
            let timeout = setTimeout(() => {
                this.resize();
                timeout = null;
            }, 250);

        } else {
            let called = false;
            const paramsObserver = this.activedrouter.params.subscribe(params => {
                const queryParamsObserver = this.activedrouter.queryParams.subscribe(queryparams => {
                    if (!called) {
                        const request = new RequestBase<IframeOptions>(this._entity.getRestUrl(), 'GET', serviceholder);
                        request.afterPreconditions = false;
                        request.addRequestValues(activedrouter.snapshot.params);
                        request.addRequestValues(queryparams);
                        request.addRequestValues(params);
                        request.handle401Error = false;
                        request.url.geolocation = this._entity.geolocation;

                        called = true;
                        request.execute().subscribe((respo) => {
                            this.enabledIframe = true;

                            this.iframe = _sanitizer.bypassSecurityTrustResourceUrl(respo.data.url);
                            let timeout = setTimeout(() => {
                                this.resize();
                                timeout = null;
                            }, 250);
                        });

                    }
                });
            });
        }

    }

    static responsiveResize(heigth: number) {
        const leftWidth = document.getElementsByClassName('left_col')[0].clientWidth;
        const rightWidth = document.getElementsByClassName('right_col')[0].clientWidth;
        let iframe = document.getElementById('iframe');
        iframe.style.left = leftWidth + 'px';
        iframe.style.width = rightWidth + 'px';
        iframe.style.height = (heigth - 56) + 'px';
        const nav_menu = document.getElementsByClassName('nav_menu')[0] as HTMLElement;
        nav_menu.style.width = rightWidth + 'px';

    }

    static loading() {
    }

    resize(): void {
        if (this.iframe !== null) {
            const nav_menu = document.getElementsByClassName('nav_menu')[0] as HTMLElement;
            nav_menu.style.position = 'fixed';
            nav_menu.style.top = '0';
            nav_menu.style.right = '0';
            let lastPage = null;
            IframeComponent.responsiveResize(window.innerHeight);
            fromEvent(window, 'resize')

                .subscribe((event: Event) => {
                    IframeComponent.responsiveResize(window.innerHeight);
                });


            this.bodyClassesSetter.loadingVisible = false;
            document.getElementById('menu_toggle').onclick = function () {
                IframeComponent.responsiveResize(window.innerHeight);
            };
            if (lastPage === null) {
                (document.getElementById('iframe') as HTMLIFrameElement).style.position = 'unset';
                setTimeout(function () {
                    (document.getElementById('iframe') as HTMLIFrameElement).style.position = 'fixed';
                }, 100);
            }
            try {
                lastPage = (document.getElementById('iframe') as HTMLIFrameElement).contentWindow.location.href;

            } catch (e) {
                lastPage = null;

            }


        }
    }

}
