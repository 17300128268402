import {EntityBase} from './entity-base';
import {UpdatableComponent} from '../../components/updatable.component';
import {EntityActionOptions} from '../remote/responses/structures/data/config/entity/options/entity-action-options';
import {Params} from '@angular/router';
import {DownloadComponent} from '../../components/download.component';
import {DownloadFileOptions} from '../remote/responses/structures/data/config/entity/options/download-file-options';
import {BaseResponse} from '../remote/responses/base-response';
import {RestResponse} from '../remote/responses/rest-response';

export class EntityDownload extends EntityBase<DownloadFileOptions> {
    get restMethod(): string {
        return 'GET';
    }

    getMethodOnActionOk(component: DownloadComponent): (response: BaseResponse<DownloadFileOptions>) => any {
        return (response: RestResponse<DownloadFileOptions>) => {
            component.downloadFile(response.data.name, response.data.url, response.data.mime_type);
        };
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }


}
