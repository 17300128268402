import {FilterDate} from './filter-date';
import {DateRangePickerOptions} from '../date-range-picker-options';
import {Column} from './column';
import {Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

export class FilterDateTime extends FilterDate {

    constructor(column: Column, params: Params, translateservice: TranslateService) {
        super(column, params, translateservice);
        this.restformat = 'YYYYMMDDHHmm';
    }

    protected get baseOptions(): DateRangePickerOptions {
        return new DateRangePickerOptions('DD/MM/YYYY HH:mm', true, true, false, this.translateservice).setRestParameterFormat(this.restformat);
    }
}
