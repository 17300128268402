import {RequestBase} from './request-base';
import {EntityPermissionList} from '../../../model/entities/entity-permission-list';
import {RequestServiceHolder} from '../request-service-holder';
import {Permission} from '../../../model/permissions/permission';

export class PermissionSaveRequest extends RequestBase<any> {

    constructor(id, entity: EntityPermissionList, serviceHolder: RequestServiceHolder, permissions?: Permission[]) {
        super(entity.getSaveRestUrl({id: id}), entity.restMethod, serviceHolder);
        if (permissions !== null) {
            for (const permission of permissions) {
                for (const permissionDependant of permission.requiredBy) {
                    if (permissionDependant.enabled) {
                        permission.enabled = true;
                        break;
                    }
                }

                this.addRequestValue(permission.name, permission.enabled ? 1 : 0);
            }
        } else {
            this.addRequestValue('remove_all_permissions', 1);
        }
    }
}
