import {EntityBase} from './entity-base';
import {Observable} from 'rxjs';
import {PermissionListRequest} from '../../services/rest/requests/permission-list-request';
import {PermissionSaveRequest} from '../../services/rest/requests/permission-save-request';
import {PermissionListOptions} from '../remote/responses/structures/data/config/entity/options/permission-list-options';
import {IndividualAction} from '../actions/individual-action';
import {Params, Router} from '@angular/router';
import {PermissionGroup} from '../permissions/permission-group';
import {GeneralConfigService} from '../../services/general-config.service';
import {MenuItem} from '../menu-item';
import {Permission} from '../permissions/permission';
import {PermissionListData} from '../remote/responses/structures/data/permission-list-data';
import {Entity} from './entity';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {PermissionListEntityData} from './permission-list-entity-data';
import {RequestBase} from '../../services/rest/requests/request-base';
import {Url} from '../url';

export class EntityPermissionList extends EntityBase<PermissionListOptions> {
    private processedEntities: string[] = [];
    private entityGroups: { [entityName: string]: PermissionGroup } = {};
    private permissionsData: PermissionListData;
    private permissions: { [name: string]: Permission } = {};
    private groups: { [title: string]: PermissionGroup } = {};

    constructor(data: PermissionListEntityData<PermissionListOptions>, private serviceHolder: RequestServiceHolder,
                private generalConfig: GeneralConfigService) {
        super(data, serviceHolder.entitiesFinder);
    }

    getPermissions(id): Observable<PermissionGroup> {
        this.groups = {};
        this.processedEntities = [];
        this.permissions = {};
        this.groups = {};

        return new Observable((observer) => {
            new PermissionListRequest(id, this, this.serviceHolder).execute().subscribe(result => {
                this.permissionsData = result.data;

                this.generalConfig.config.subscribe(config => {
                    const menu = new MenuItem(config.menu, this.entitiesFinder);

                    this.createGroupsFromMenu(menu, []);
                    this.createGroupsFromEntities();
                    this.createGroupsFromOrphanEntities();

                    for (const group of Object.values(this.groups)) {
                        if (group.permissions.length > 0) {
                            observer.next(group);
                        }
                    }
                });
            }, error => observer.error(error));
        });
    }

    private createGroupsFromMenu(menu: MenuItem, parentNames: string[]) {
        if (menu.isCategory()) {
            if (!!menu.name) {
                parentNames.push(menu.name);
            }

            menu.children.map(item => this.createGroupsFromMenu(item, parentNames.slice()));
        } else {
            menu.entity.subscribe(entity => this.createPermissionForEntity(entity, menu.name, parentNames.slice()));
        }
    }

    private createPermissionForEntity(entity: Entity, name: string, parentNames: string[]) {
        /*     if (entity.constructor.name === EntityPermissionList.name) {
                 console.log(entity.name);

                 for (const childEntityName in entity.linkedEntitiesNames) {
                     this.processedEntities.push(childEntityName);
                 }
                 return;
             }*/
        const group = this.getGroup(parentNames);
        const permission = this.getPermission(entity, name);

        group.permissions.push(permission);
        group.checkPermissions();
        if (this.processedEntities.indexOf(entity.name) < 0) {
            this.entityGroups[entity.name] = group;
        }

        parentNames.push(entity.visualName);

        this.addRequiredPermisisons(entity, permission, parentNames.slice());
    }

    private getGroup(parentNames: string[]): PermissionGroup {
        const title = parentNames.join(' => ');

        if (!(title in this.groups)) {
            this.groups[title] = new PermissionGroup(title);
        }

        return this.groups[title];
    }

    private getPermission(entity: Entity, visualName: string, independent: boolean = true): Permission {
        const permissionName = entity.data.permissionName;
        let permission: Permission = this.permissions[permissionName];

        if (permission === undefined) {
            const permissionEnabled = this.permissionsData.hasOwnProperty(permissionName) && this.permissionsData[permissionName];

            permission = new Permission(permissionName, visualName, permissionEnabled);
            this.permissions[permissionName] = permission;
        }

        if (independent) {
            permission.independent = independent;
        }

        return permission;
    }

    private addRequiredPermisisons(entity: Entity, permission: Permission, parentNames: string[]) {
        entity.requiredEntitiesNames.map(requiredEntityName => {
            this.entitiesFinder.findEntityByName(requiredEntityName).subscribe(requiredEntity => {
                const requiredPermission = this.getPermission(requiredEntity, '', false);
                requiredPermission.requiredBy.push(permission);

                const group = this.getGroup(parentNames);

                if (this.processedEntities.indexOf(requiredEntity.name) < 0) {
                    this.entityGroups[requiredEntity.name] = group;
                }
            });
        });
    }

    private createGroupsFromEntities() {
        while (Object.keys(this.entityGroups).length > 0) {
            const entityName = Object.keys(this.entityGroups)[0];
            const group = this.entityGroups[entityName];
            delete this.entityGroups[entityName];

            this.entitiesFinder.findEntityByName(entityName).subscribe(entity => {
                this.processedEntities.push(entityName);

                for (const childEntityName in entity.linkedEntitiesNames) {
                    if (!entity.linkedEntitiesNames.hasOwnProperty(childEntityName)) {
                        continue;
                    }

                    this.entitiesFinder.findEntityByName(childEntityName).subscribe(childEntity => {
                        const parentNames = [];

                        if (group.title) {
                            parentNames.push(group.title);
                        }

                        parentNames.push(entity.visualName);

                        if (!this.isGroupFromEntityCreated(childEntity)) {
                            this.createPermissionForEntity(childEntity, entity.linkedEntitiesNames[childEntityName], parentNames);
                        }
                    });
                }
            });
        }
    }

    isGroupFromEntityCreated(entity: Entity) {
        return this.entityGroups.hasOwnProperty(entity.name) || this.processedEntities.indexOf(entity.name) >= 0;
    }

    private createGroupsFromOrphanEntities() {
        for (const entityName of this.orphanEntities) {
            this.serviceHolder.entitiesFinder.findEntityByName(entityName).subscribe(entity => {
                this.createPermissionForEntity(entity, entity.visualName, []);
            });
        }

        this.createGroupsFromEntities();
    }

    private get orphanEntities(): string[] {
        return this.entitiesFinder.entitiesNames.filter(entityName => this.processedEntities.indexOf(entityName) < 0);
    }

    getIndividualActions(id, router: Router, component: any): Observable<IndividualAction> {
        return new Observable<IndividualAction>((observer) => {
            if (this.options.actionsIndividual) {
                for (const actionData of this.options.actionsIndividual) {
                    this.entitiesFinder.findEntityByName(actionData.entity_name).subscribe(entity => {
                        observer.next(new IndividualAction(actionData, router, this.serviceHolder, entity, component, id, this.urlParams));
                    });
                }
            }
        });
    }

    get restMethod(): string {
        return 'PUT';
    }

    save(id): Observable<void> {
        return new Observable<void>(observer => {
            const request = new PermissionSaveRequest(id, this, this.serviceHolder, Object.values(this.permissions));
            request.url.geolocation = this.geolocation;

            request.execute().subscribe(() => observer.next());
        });

    }

    setAllNull(id): Observable<void> {
        return new Observable<void>(observer => {
            const request = new PermissionSaveRequest(id, this, this.serviceHolder, null);
            request.url.geolocation = this.geolocation;

            request.execute().subscribe(() => observer.next());
        });

    }

    get linkedEntitiesNames(): Params {
        const result = {};
        if (this.options.actionsIndividual) {
            for (const actionData of this.options.actionsIndividual) {
                result[actionData.entity_name] = '';
            }
        }

        return result;
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

    getSaveRestUrl(replacements = {}): Url {
        let prefix = this.data.restPrefixUrl;

        if (typeof prefix === 'undefined') {
            prefix = RequestBase.restPrefixUrl;
        }

        const mData = this.data as PermissionListEntityData<PermissionListOptions>;

        var url = new Url([prefix, mData.saveRestUrl], true, replacements);
        url.geolocation = this.geolocation;
        return url;
    }
}
