import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {EntityInbox} from '../model/entities/entity-inbox';
import {RestService} from '../services/rest/rest.service';
import {RequestBase} from '../services/rest/requests/request-base';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {Url} from '../model/url';
import {SlideInOutAnimation} from './animation';
import {Observable, Subject} from 'rxjs/Rx';
import {TranslateService} from '@ngx-translate/core';
import {MailData} from '../model/remote/responses/structures/data/mail/mail-data';
import {BaseResponse} from '../model/remote/responses/base-response';
import {ListData} from '../model/remote/responses/structures/data/list/list-data';
import {RowData} from '../model/remote/responses/structures/data/list/row-data';

@Component({
    templateUrl: 'inbox.component.html',
    animations: [SlideInOutAnimation]
})
export class InboxComponent implements OnInit {
    private _entity: EntityInbox;
    showRecivers: boolean;
    private urlReplacements: Params;
    mailList: MailData[] = null;
    actualMails: MailData = null;
    userToSend: string[] = [];
    writingMail = false;
    mail = '';
    title = '';
    urlAutoComplete: Url;
    urlCompose: Url;
    selectedID: number;
    private isReply = false;
    private noLoadMore = false;
    private selectedTab = 0;

    constructor(private activatedRoute: ActivatedRoute, private location: Location, private router: Router, private restService: RestService, private serviceholder: RequestServiceHolder,
                private bodyAttributesSetter: BodyAttributesSetter, private translateService: TranslateService) {
        this._entity = activatedRoute.snapshot.data['entity'];
        this.urlReplacements = {...this.restService.globalRequestParams, ...this.urlReplacements};
        this.activatedRoute.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.serviceholder.entitiesFinder.findEntityByName(this._entity.options.entity_user_autocomplete).subscribe(entity => {
            this.urlAutoComplete = entity.getRestUrl();
        });
        this.serviceholder.entitiesFinder.findEntityByName(this._entity.options.entity_compose).subscribe(entity => {
            this.urlCompose = entity.getRestUrl();
        });
    }

    ngOnInit(): void {
        /*   this.bodyAttributesSetter.loadingVisible = true;*/
        this.receivedMails();
    }

    receivedMails(limit = 10, offset = 0) {
        this.actualMails = null;
        this.mailList = null;
        var request = new RequestBase<MailData[]>(this._entity.getRestUrl(this.urlReplacements), 'GET', this.serviceholder);
        request.addRequestValue('limit', limit);
        request.addRequestValue('offset', offset);
        request.addRequestValue('sent', this.selectedTab);
        request.url.geolocation = this._entity.geolocation;

        request.execute().subscribe(it => {
                this.bodyAttributesSetter.loadingVisible = false;
                if (it.data.length > 0) {
                    this.mailList = it.data;
                }
                this.noLoadMore = it.data.length >= 10;
            }, e => {
            }, () => {

                this.bodyAttributesSetter.loadingVisible = false;
            }
        );
    }

    reply() {
        this.userToSend = this.actualMails.recipients;
        this.writingMail = true;
        this.isReply = true;
    }

    sendEmail(): void {
        this.bodyAttributesSetter.loadingVisible = true;
        const request = new RequestBase<any>(this.urlCompose, 'POST', this.serviceholder);
        request.url.geolocation = this._entity.geolocation;

        const params = {
            'subject': this.title,
            'body': this.mail,
            'emails': this.userToSend.join(','),
        };
        request.addRequestValues(params);
        if (this.isReply) {
            request.addRequestValue('id_parent', this.selectedID);
        }
        request.execute().subscribe(it => {
                this.closeEmail();

            }, e => {
            }, () => {

                this.bodyAttributesSetter.loadingVisible = false;
            }
        );
    }


    getMail(value: MailData) {
        this.closeEmail();
        this.bodyAttributesSetter.loadingVisible = true;
        this.urlReplacements['id'] = value.id;
        this.urlReplacements['limit'] = 9999;

        this.serviceholder.entitiesFinder.findEntityByName(this._entity.options.entity_get).subscribe(it => {

                new RequestBase<MailData>(it.getRestUrl(this.urlReplacements), 'GET', this.serviceholder).execute().subscribe(mail => {
                    this.actualMails = mail.data;
                    this.selectedID = value.id_parent !== null ? value.id_parent : value.id;
                });
            }, e => {
            }, () => {

                this.bodyAttributesSetter.loadingVisible = false;
            }
        );
    }

    back() {
        this.location.back();
    }

    save() {
    }

    trackByFn(i: number) {
        return i;
    }

    getToMails(to: string[]) {
        return to.join(', ');
    }

    writeMail() {
        this.writingMail = true;

    }

    closeEmail() {
        this.writingMail = false;
        this.mail = '';
        this.title = '';
        this.userToSend = [];
        this.isReply = false;
    }


    source() {
        let timeout = null;
        return (keyword: any): Observable<any[]> => {

            const request = this.search(this.urlAutoComplete, keyword);
            if (timeout !== null) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                timeout = null;
                this.search(this.urlAutoComplete, keyword);
            }, 250);


            return request;
        };


    }

    private createRequest(url: Url, value: any) {
        const request = new RequestBase<ListData>(url, 'GET', this.serviceholder);
        request.lockScreen = false;
        request.addRequestValue('email', value);
        return request;
    }

    private search(url: Url, value: any = '') {
        let test: Subject<any[]> = new Subject();
        this.createRequest(url, value).execute().subscribe((response: BaseResponse<ListData>) => {
                const rows: RowData[] = response.data.rows;
                test.next(rows);
            },
            (error) => test.error(error),
            () => {
                test.complete();
            });
        return test;
    }

    selected($event: any) {
        if (typeof $event.id === 'number') {
            this.userToSend.push($event.email);
        }
    }

    isValid() {
        return true;
    }

    removeEmail(value: number) {
        this.userToSend.splice(value, 1);
    }

    getMails() {
        const mails: MailData[] = [];
        mails.push(this.actualMails);
        mails.push(...this.actualMails.parents);
        /* mails.push(this.actualMails.children);*/
        return mails;
    }

    replaceString(body: string) {
        return body.replace(/<[^>]+>/gm, '');

    }

    setSelectedTab($event: number) {
        this.selectedTab = $event;
        this.receivedMails();
    }
}
