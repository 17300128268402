import {Component, Input} from '@angular/core';
import {DashboardTextItem} from '../../model/remote/responses/structures/data/dashboard/dashboard-text-item';
@Component({
    selector: 'dashboard-text',
    templateUrl: './dashboard-text-item.component.html'
})
export class DashboardTextItemComponent {
    entries: {key: string, value: any}[] = [];

    @Input() set item(values: DashboardTextItem) {
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                this.entries.push({key: key, value: values[key]});
            }
        }
    }
}
