import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router, Routes} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {HomeComponent} from './home/home.component';
import {AddComponent} from './form/add.component';
import {TableComponent} from './table/table.component';
import {RequestBase} from '../services/rest/requests/request-base';
import {MessageError} from '../services/messages/message-error';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {Url} from '../model/url';
import {LoginData} from '../model/remote/responses/structures/data/login-data';
import {RoutesEntitiesCreator} from '../services/entities/routes-entities-creator';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';
import {CookiesService} from '../services/cookies.service';
import {LangData} from '../model/remote/responses/structures/data/lang-data';
import {LocationService} from '../services/location.service';

/**
 * Created by angro on 10/05/2017.
 */

enum LoginSection {
    LOGIN, RECOVER_PASSWORD, CHANGE_PASSWORD, LOADING
}

declare var indexHeaders: { [name: string]: string };

declare var loginUrl;

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['../css/custom.less', '../css/login.css', '../css/custom_responsive.less'],
    encapsulation: ViewEncapsulation.None,
    entryComponents: [TableComponent, HomeComponent, AddComponent],
    host: {'[class]': 'bodyClasses'},
    providers: [RoutesEntitiesCreator]
})
export class LoginComponent {
    bodyClasses = 'login';
    username: string;
    password: string;
    rememberMe = false;
    sectionVisible: LoginSection = LoginSection.LOADING;
    recoverPasswordData: { id?: any, token?: string, password1?: string, password2?: string } = {};
    private resetPasswordBaseUrl = 'reset_password/';
    private langUrl = 'lang/';

    constructor(bodyClassesSetter: BodyAttributesSetter, private router: Router, private auth: AuthService, private route: ActivatedRoute,
                private routesCreator: RoutesEntitiesCreator, private serviceHolder: RequestServiceHolder,
                private cookieservice: CookiesService, private translateService: TranslateService) {
        RequestBase.addGlobalHeaders(indexHeaders);
        bodyClassesSetter.inLogin = true;
        this.recoverPasswordData = {
            id: this.route.snapshot.queryParams['id'],
            token: this.route.snapshot.queryParams['token_recover']
        };
        this.auth.checkAuth();
        /*        this.locationService.getPosition().then(pos => {
                    console.log(`Positon: ${pos.lng} ${pos.lat}`);
                }, onreject => {
                    console.log('Fallo', onreject.code); // 1 permiso denegado
                    console.log('mensaje', onreject.message); // 1 permiso denegado
                });
                this.locationService.getIpAddress().toPromise().then(it => console.log(it['ip']));*/
        if (this.isRecovery()) {
            this.showChangePasswordForm();
        } else if (this.auth.loggedIn) {
            this.onLoggedIn();
        } else {
            this.callLangConfig();

        }
    }

    private showLogin() {
        const queryParams = this.getQueryParams();
        const currentPathName = window.location.pathname;

        if (!queryParams['continue'] && currentPathName != '/') {
            queryParams['continue'] = currentPathName;

            this.router.navigate(['/'], {queryParams: queryParams});
        }

        this.sectionVisible = LoginSection.LOGIN;
    }

    private isRecovery() {
        return typeof this.recoverPasswordData.token !== 'undefined';
    }

    private onLoggedIn() {
        const routesPromise = this.routesCreator.routes;
        routesPromise.then((routes: Routes) => {
            this.router.resetConfig(routes);
            let next = this.route.snapshot.queryParams['continue'];
            if (next === undefined) {
                next = window.location.pathname;
            }
            if (next.includes('/panel/')) {
                next = next.substring(next.indexOf('/panel/') + 6);
            }
            if (next.includes('/web/')) {
                next = next.substring(next.indexOf('/web/') + 4);
            }
            if (next.includes('/panel/web/')) {
                next = next.substring(next.indexOf('/panel/web/') + 10);
            }
            this.router.navigate([next], {
                queryParams: this.getQueryParams(['continue'])
            });
        });

        routesPromise.catch(() => {
            this.showLogin();
        });
    }

    private getQueryParams(exclude?: string[]): { [index: string]: any } {
        const params = {};

        if (typeof exclude === 'undefined') {
            exclude = [];
        }

        for (const key of Object.keys(this.route.snapshot.queryParams)) {
            if (exclude.includes(key)) {
                continue;
            }

            if (this.route.snapshot.queryParams.hasOwnProperty(key)) {
                params[key] = this.route.snapshot.queryParams[key];
            }
        }

        return params;
    }

    public login() {
        this.showLoading();
        const twoHours = 2 * 60;
        const oneYear = 365 * 24 * 60;

        const url = new Url(loginUrl, true);


        const params = {
            '_username': this.username,
            '_password': this.password,
            'expiration': this.rememberMe ? oneYear * 60 : twoHours * 60
        };

        const request = new RequestBase<LoginData>(url, 'POST', this.serviceHolder);
        request.afterPreconditions = false;
        request.addRequestValues(params);
        request.handle401Error = false;

        request.execute().subscribe((response) => {
            this.auth.expiration = this.rememberMe ? oneYear : twoHours;
            this.auth.sessionToken = response.data.session_token;
            this.cookieservice.setCookie('profileName', this.username);
            this.onLoggedIn();
        }, () => this.showLogin());
    }

    get resetPasswordUrl(): string {
        return 'user/' + this.recoverPasswordData.id + '/' + 'change_password_panel';
    }

    requestPasswordEmailUrl() {
        const url = new Url(this.resetPasswordBaseUrl, true);
        const request = new RequestBase(url, 'POST', this.serviceHolder);
        request.afterPreconditions = false;
        request.handle401Error = false;
        request.addRequestValue('email', this.username);
        request.execute().subscribe(e => this.showLoginForm(), error1 => console.log(error1));
    }

    showLostPasswordForm() {
        this.sectionVisible = LoginSection.RECOVER_PASSWORD;
    }

    showLoginForm() {
        this.router.navigate(['/'], {queryParams: this.getQueryParams(['id', 'token_recover'])});
        this.sectionVisible = LoginSection.LOGIN;
    }

    showLoading() {
        this.sectionVisible = LoginSection.LOADING;
    }

    showChangePasswordForm() {
        this.auth.sessionToken = this.recoverPasswordData.token;
        this.sectionVisible = LoginSection.CHANGE_PASSWORD;
    }

    get loginVisible(): boolean {
        return this.sectionVisible === LoginSection.LOGIN;
    }

    get lostPasswordVisible(): boolean {
        return this.sectionVisible === LoginSection.RECOVER_PASSWORD;
    }

    get changePasswordVisible(): boolean {
        return this.sectionVisible === LoginSection.CHANGE_PASSWORD;
    }

    get loadingVisible(): boolean {
        return this.sectionVisible === LoginSection.LOADING;
    }

    changePassword() {
        if (!this.recoverPasswordData.password1) {
            new MessageError('Password cannot be empty').show();
            return;
        }

        if (this.recoverPasswordData.password1 !== this.recoverPasswordData.password2) {
            new MessageError('Passwords doesn\'t match').show();
            return;
        }

        const url = new Url(this.resetPasswordUrl, true);
        const request = new RequestBase(url, 'POST', this.serviceHolder);
        request.afterPreconditions = false;
        request.handle401Error = false;
        request.addRequestValue('user__password', this.recoverPasswordData.password1);
        this.showLoading();
        request.execute().subscribe((response) => {
            this.showLoginForm();
        }, (error) => {
            this.showLoginForm();
        });
    }

    private callLangConfig() {
        this.showLoading();

        const url = new Url(this.langUrl, true);
        const request = new RequestBase<LangData>(url, 'GET', this.serviceHolder);
        request.afterPreconditions = false;
        request.execute().subscribe((response) => {
            this.translateService.setDefaultLang(response.data.default_lang);
            this.translateService.use(response.data.default_lang);
            this.cookieservice.setCookie('lang', response.data.default_lang);
            this.showLogin();
        }, () => this.showLogin());
    }
}
