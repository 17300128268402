import {Params} from '@angular/router';
import {EntityResourceBase} from './entity-resource-base';
import {BackendOptions} from '../remote/responses/structures/data/config/entity/options/backend-options';
import {Observable} from 'rxjs';
import {Item} from '../item';
import {SingleResourceRequest} from '../../services/rest/requests/single-resource-request';
import {SingleResourceResponse} from '../remote/responses/single-resource-response';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {EntityResource} from './entity-resource';
import {TranslateService} from '@ngx-translate/core';

export class EntitySingleResource extends EntityResourceBase<BackendOptions> {
    private serviceHolder: RequestServiceHolder;
    private translate: TranslateService;

    constructor(data: EntityData<BackendOptions>, serviceHolder: RequestServiceHolder, translateservice: TranslateService) {
        super(data, serviceHolder, translateservice, false);
        this.translate = translateservice;
        this.serviceHolder = serviceHolder;
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

    getItem(id: number): Observable<Item> {
        return new Observable<Item>((observer) => {
            const request = new SingleResourceRequest(id, this.serviceHolder, this, this.translate);
            request.url.geolocation = this.geolocation;

            request.execute().subscribe((response: SingleResourceResponse) => {
                observer.next(response.row);
            });
        });
    }
}
