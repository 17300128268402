import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EntityInfo} from '../model/entities/entity-info';

@Component({
    selector: 'ol-info',
    templateUrl: 'info.component.html'
})
export class InfoComponent {
    _entity: EntityInfo;

    constructor(activatedRoute: ActivatedRoute) {
        this._entity = activatedRoute.snapshot.data['entity'];
    }

}
