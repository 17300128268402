import {Filter} from './filter';
import * as moment from 'moment';
import {Column} from './column';
import {Params} from '@angular/router/router';
import {DateRangePickerOptions} from '../date-range-picker-options';
import {TranslateService} from '@ngx-translate/core';

export class FilterDate extends Filter {

    options: DateRangePickerOptions;
    protected translateservice: TranslateService;
     restformat = 'YYYYMMDD';

    constructor(column: Column, params: Params, translateservice: TranslateService) {
        super(column, params);
        this.translateservice = translateservice;
        this.createOptions();
    }

    protected parseInternalToString(internal: any): string {
        return 'start' in internal ?
            (internal.start.format(this.options.restParameterFormat) + '-' + internal.end.format(this.options.restParameterFormat)) :
            '';
    }

    protected parseStringToInternal(value: string): any {
        let dates = value.split('-');

        if (dates.length == 2) {
            return {start: this.getDate(dates[0]), end: this.getDate(dates[1])};
        } else {
            return {};
        }
    }

    private getDate(value: string): any {
        return moment(value, this.restformat);
    }

    protected get baseOptions(): DateRangePickerOptions {
        return new DateRangePickerOptions('DD/MM/YYYY', false, true, false, this.translateservice).setRestParameterFormat(this.restformat);
    }

    createOptions() {
        let options: DateRangePickerOptions = this.baseOptions;
        if ('start' in this.internalValue) {
            options.startDate = this.internalValue.start;
            options.endDate = this.internalValue.end;
        }

        this.options = options;
    }


    protected parseInternalToVisualValue(internal: any): string {
        let format = this.baseOptions.locale.format;

        if ('start' in internal) {
            return internal.start.format(format) + ' - ' + internal.end.format(format);
        }

        return '';
    }
}
