import {EntityBase} from './entity-base';
import {InfoOptions} from '../remote/responses/structures/data/config/entity/options/info-options';
import {Params} from '@angular/router';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {InboxOptions} from '../remote/responses/structures/data/config/entity/options/inbox-options';

export class EntityInbox extends EntityBase<InboxOptions> {


    constructor(data: EntityData<InboxOptions>, serviceHolder: RequestServiceHolder) {
        super(data, serviceHolder.entitiesFinder);

    }

    get linkedEntitiesNames(): Params {
        return undefined;
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

    get restMethod(): string {
        return 'GET';
    }
}
