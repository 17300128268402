import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable()
export class LocationService {
    private _askLocation = false;

    constructor(private http: HttpClient) {
    }


    get askLocation(): boolean {
        return this._askLocation;
    }

    set askLocation(value: boolean) {
        this._askLocation = value;
    }

    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this._askLocation) {
                navigator.geolocation.getCurrentPosition(resp => {

                        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
                    },
                    err => {
                        reject(err);
                    });
            } else {
                resolve({lng: null, lat: null});
            }

        });

    }
/*
    getIpAddress() {
        return this.http
            .get('https://api.ipify.org/?format=json')
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {

        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }*/


}
