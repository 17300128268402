import {RestResponse} from './rest-response';
import {SingleResourceData} from './structures/data/single-resource-data';
import {ResponseStructure} from './structures/response-structure';
import {Item} from '../../item';
import {Column} from '../../columns/column';
import {Value} from '../../values/value';
import {ColumnType} from '../../columns/column-type';
import {ValueDate} from '../../values/value-date';
import {ValueDateTime} from '../../values/value-date-time';
import {EntityResource} from '../../entities/entity-resource';
import {ValueImage} from '../../values/value-image';
import {TranslateService} from '@ngx-translate/core';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';

export class SingleResourceResponse extends RestResponse<SingleResourceData> {
    row: Item;
    private translate: TranslateService;

    constructor(data: ResponseStructure<SingleResourceData>, private entity: EntityResource, translateservice: TranslateService, serviceHolder: RequestServiceHolder) {
        super(data, serviceHolder);
        this.translate = translateservice;
        this.createRow();
    }

    private createRow() {
        this.row = new Item();

        this.entity.columns.subscribe((columns: Column[]) => {
            for (const column of columns) {
                this.row.addValue(column.createValue(this.translate, this.data[column.name]));
            }
        });
    }
}
