import {Component, ViewEncapsulation} from '@angular/core';
import {Column} from '../../model/columns/column';
import {AuthService} from '../../services/auth.service';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EntitiesHandlerService} from '../../services/entities/entities-handler.service';
import {GeneralConfigService} from '../../services/general-config.service';
import {ConfigStyle} from '../../model/remote/responses/structures/data/config/config-style';

@Component({
    templateUrl: './menu-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: []
})
export class MenuSelectorComponent {
    top_selector: Column;

    constructor(private auth: AuthService, bodyClassesSetter: BodyAttributesSetter, private router: Router,
                private entities: EntitiesHandlerService, private generalConfig: GeneralConfigService) {
        entities.columnsTop.subscribe(it => {
            this.top_selector = it;
        });
    }
}
