import {Column} from './column';
import {ColumnType} from './column-type';
import {TranslateService} from '@ngx-translate/core';

export class SimpleTextColumn extends Column {

    constructor(name: string, translateService: TranslateService) {
        super({name: name, visualName: '', primary: false, type: ColumnType.text.name}, translateService);
    }
}
