import {Observable} from 'rxjs';
import {Column} from '../columns/column';
import {EntityBase} from './entity-base';
import {AutocompleteEntityData} from './autocomplete-entity-data';
import {BackendOptions} from '../remote/responses/structures/data/config/entity/options/backend-options';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {EntityResource} from './entity-resource';
import {Url} from '../url';
import {ChangeOptions} from '../remote/responses/structures/data/config/entity/options/change-options';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {Params} from '@angular/router';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';

export class EntityAutocomplete extends EntityBase<ChangeOptions> implements EntityResource {
    private translate: TranslateService;

    get restMethod(): string {
        return 'GET';
    }

    constructor(url: Url, entityFinder: EntitiesFinderService, translateService: TranslateService) {
        super(new AutocompleteEntityData<ChangeOptions>(url.pathParts.join('/'), 'update') as EntityData<BackendOptions>, entityFinder);
        this.translate = translateService;
    }

    get columns(): Observable<Column[]> {
        return new Observable<Column[]>((obs) => {
            const columnId = new Column({name: 'id', primary: true, visualName: '', type: 'text'}, this.translate);
            const columnValue = new Column({name: 'value', primary: false, visualName: '', type: 'text'}, this.translate);

            obs.next([columnId, columnValue]);
            obs.complete();
        });
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}
