import {EntityBase} from './entity-base';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {UpdatableComponent} from '../../components/updatable.component';
import {Params} from '@angular/router';

export class EntityDialogInfo extends EntityBase<BaseOptions> {
    get restMethod(): string {
        return 'GET';
    }

    getMethodOnActionOk(component: UpdatableComponent): (response) => any {
        return component.showDialog;
    }


    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}
