import {Injectable} from '@angular/core';

@Injectable()
export class BodyAttributesSetter {
    private _bodyClasses = {
        loading: false,
        'nav-md': false,
        'nav-sm': false,
        login: false
    };
    private _bodyClassesChangedCallback: (classes: any) => void;
    private timeoutHandles = [];
    private lateralMenuOpen = true;
    idChangedCallback: (id: string) => void;

    set loadingVisible(value: boolean) {
        if (value) {
            this.timeoutHandles.push(setTimeout(() => {
                this.setBodyClass('loading', value);
            }, 400));
        } else {
            if (this.timeoutHandles.length > 0) {
                const handle = this.timeoutHandles.splice(0, 1);
                clearTimeout(handle[0]);
            }

            if (this.timeoutHandles.length === 0) {
                this.setBodyClass('loading', value);
            }
        }
    }

    set inLogin(value: boolean) {
        if (value) {
            this.inHome = false;
            this.id = 'login';
        }

        this.setBodyClass('login', value);

    }

    set inHome(value: boolean) {
        if (value) {
            this.inLogin = false;
        }

        const currentHomeClassName = this.lateralMenuOpen ? 'nav-md' : 'nav-sm';

        this.setBodyClass(currentHomeClassName, value);
    }

     setBodyClass(className: string, visible: boolean) {
        this._bodyClasses[className] = visible;
        this._bodyClassesChangedCallback(this._bodyClasses);
    }

    set bodyClassesChangedCallBack(callback: (classes: any) => void) {
        this._bodyClassesChangedCallback = callback;
    }

    toggleMenu() {
        this.lateralMenuOpen = !this.lateralMenuOpen;
        this.setBodyClass('nav-sm', !this._bodyClasses['nav-sm']);
        this.setBodyClass('nav-md', !this._bodyClasses['nav-md']);
    }

    set id(id: string) {
        this.idChangedCallback(id);
    }
}
