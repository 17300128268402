import {InputBase} from './input-base';
import {Component, Input, OnInit} from '@angular/core';
import {ChangeOptions} from '../../../model/remote/responses/structures/data/config/entity/options/change-options';
import {EntityChange} from '../../../model/entities/entity-change';
import {OlsfCompleterDataService} from '../../../services/olsf-completer-data.service';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';
import {Item} from '../../../model/item';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {ColumnType} from '../../../model/columns/column-type';

@Component({
    selector: 'ol-input-autocomplete',
    templateUrl: './input-autocomplete.component.html'

})
export class InputAutocompleteComponent extends InputBase implements OnInit {
    @Input() entity: EntityChange<ChangeOptions>;
    @Input() item: Item;
    enabled = false;
    placeHolder;
    noFoundText;
    invalid = false;
    private translate: TranslateService;
    private router: Router;

    constructor(private serviceHolder: RequestServiceHolder, translate: TranslateService, router: Router) {
        super();
        this.translate = translate;
        this.router = router;
        this.translate.get('app.component.loading').subscribe((value => this.placeHolder = value));
        this.translate.get('noFoundText').subscribe((value => this.noFoundText = value));
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.control.disable();

        this.value.visualValue = '';

        if (this.item === undefined) {
            throw new Error('Attribute item is required');

        }
        this.setInitialValue();

    }

    setInitialValue() {
        if (!this.value.value && this.value.value !== 0) {
            this.checkValitations();
            this.enable();
            return;
        }

        const completerDataService = new OlsfCompleterDataService(this.column, this.serviceHolder, this.translate, this.item);
        completerDataService.subscribe(rows => {


            this.value.visualValue = this.column.required ? rows[0]['value'] : rows[1]['value'];
            this.value.visualValue = rows.find(it => it.id === this.value.value)['value'];
            this.checkValitations();
            this.enable();
            OlsfCompleterDataService.preloadCache(this.column, this.serviceHolder, this.translate, this.item);
        }, () => {
            this.checkValitations();
            this.enable();
        }, () => {
            this.checkValitations();
            this.enable();
        });
        completerDataService.search(this.value.value, false);
    }

    private enable() {
        this.placeHolder = this.placeholder;
    }


    private navigateAddEntity() {
        this.serviceHolder.entitiesFinder.findEntityByName(this.column.addEntityName).subscribe(it => window.open(it.getActionUrl().path));
    }

    readonlycheck() {
        if (this.column.type === ColumnType.listquery) {
            return !this.control.disabled;
        } else {
            return false;
        }
    }

    resetIfInvalid() {
        if (typeof this.innerValue.value !== 'number') {
            this.innerValue.reset();
            if (this.column.required) {
                this.invalid = true;
            }
        } else {
            this.invalid = false;
        }

    }
}
