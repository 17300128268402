import {Column} from '../columns/column';
import {Observable, Observer} from 'rxjs';
import {Item} from '../item';
import {EntityChange} from './entity-change';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {ChangeOptions} from '../remote/responses/structures/data/config/entity/options/change-options';
import {BaseResponse} from '../remote/responses/base-response';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params} from '@angular/router';

export class EntityUploadFiles extends EntityChange<ChangeOptions> {

    constructor(data: EntityData<ChangeOptions>, protected serviceHolder: RequestServiceHolder, protected translatesService: TranslateService, activedrouter: ActivatedRoute) {
        super(data, serviceHolder, translatesService, activedrouter);
    }


    save(item: Item, id: any): Observable<BaseResponse<any>> {
        return undefined;
    }

    get restMethod(): string {
        return 'POST';
    }

    get relativeRoutePath(): string {
        return 'new';
    }

    getItem(id): Observable<Item> {

        return new Observable<Item>((obs: Observer<Item>) => {
            this.columns.subscribe((columns: Column[]) => {
                const item = new Item();
                for (const column of columns) {
                    this.addURLParams(column);

                    item.addValue(column.createValue(this.translatesService, ''));

                }
                this.checkDynamicFieldsAndAddIt(item);
                obs.next(item);
                obs.complete();
            });
        });
    }


    get requiredEntitiesNames(): string[] {
        return [];
    }
}
