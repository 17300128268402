import {Filter} from './filter';
import * as moment from 'moment';
import {Column} from './column';
import {Params} from '@angular/router/router';
import {DateRangePickerOptions} from '../date-range-picker-options';
import {TranslateService} from '@ngx-translate/core';
import {FilterDate} from './filter-date';

export class FilterTime extends FilterDate {
    constructor(column: Column, params: Params, translateservice: TranslateService) {
        super(column, params, translateservice);
        this.restformat = 'HH:mm:ss';
    }

    protected get baseOptions(): DateRangePickerOptions {
        return new DateRangePickerOptions('HH:mm', true, false, false).setRestParameterFormat(this.restformat);
    }
}
