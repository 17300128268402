import {Item} from '../item';
import {Observable} from 'rxjs';
import {Column} from '../columns/column';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {BackendOptions} from '../remote/responses/structures/data/config/entity/options/backend-options';
import {OlsfCompleterDataService} from '../../services/olsf-completer-data.service';
import {BaseResponse} from '../remote/responses/base-response';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {ActivatedRoute, Params} from '@angular/router';
import {EntityResourceBase} from './entity-resource-base';
import {TranslateService} from '@ngx-translate/core';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {ColumnType} from '../columns/column-type';
import {Url} from '../url';
import {RequestBase} from '../../services/rest/requests/request-base';
import {ColumnData} from '../remote/responses/structures/data/config/entity/options/column-data';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';

export abstract class EntityChange<T extends BackendOptions> extends EntityResourceBase<T> {
    item: Item;
    uploader: FileUploader = new FileUploader({});
    private urlReplacements: Params;

    constructor(data: EntityData<T>, protected serviceHolder: RequestServiceHolder, translateService: TranslateService, protected activedrouter: ActivatedRoute) {
        super(data, serviceHolder, translateService, false, activedrouter);
        this.initUploader();

    }

    private initUploader() {
        this.urlReplacements = {...this.serviceHolder.restService.globalRequestParams, ...this.urlReplacements};
        this.activedrouter.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.activedrouter.queryParams.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        const fileOptions: FileUploaderOptions = {};
        fileOptions.url = this.getRestUrl(this.activedrouter.params).path;
        fileOptions.additionalParameter = this.urlReplacements;
        fileOptions.headers = [{name: 'X-Auth-Token', value: this.requestServiceHolder.authService.sessionToken}, {
            name: 'lang',
            value: this.requestServiceHolder.authService.lang
        }];
        fileOptions.isHTML5 = true;
        this.uploader = new FileUploader(fileOptions);
    }

    dataSource(column: Column): any {
        let timeout = null;
        return (keyword: any): Observable<any[]> => {
            const request = new OlsfCompleterDataService(column, this.serviceHolder, this.translateService, this.item
                )
            ;
            if (timeout !== null) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                timeout = null;
                request.search(keyword);
            }, 250);

            return request;
        };
    }

    getServiceHolder(): RequestServiceHolder {
        return this.serviceHolder;
    }

    protected checkDynamicFieldsAndAddIt(item, itemSingler: Item = null) {
        if (this.data.options.dynamicFieldsEntity !== undefined) {
            this.entitiesFinder.findEntityByName(this.data.options.dynamicFieldsEntity).subscribe((it) => {
                    let called = false;
                    const paramsObserver = this.activedrouter.params.subscribe(params => {
                        const queryParamsObserver = this.activedrouter.queryParams.subscribe(queryparams => {
                            if (!called) {
                                const url = new Url(it.routePath, true);
                                url.geolocation = this.geolocation;

                                const request = new RequestBase<ColumnData>(url, 'GET', this.requestServiceHolder);
                                request.afterPreconditions = false;
                                request.addRequestValues(this.urlParams);
                                request.addRequestValues(queryparams);
                                request.addRequestValues(params);
                                request.handle401Error = false;
                                called = true;
                                request.execute().subscribe(r => {
                                        if (Object.entries(r.data).length !== 0) {
                                            if (r.data instanceof Array) {
                                                for (const column of r.data) {
                                                    const columnResult = new Column(column, this.translateService, this.entitiesFinder);
                                                    const value = columnResult.createValue(this.translateService, itemSingler !== null ? itemSingler.getValue(columnResult) : '');
                                                    item.addValue(value);
                                                }
                                            }

                                        }
                                    }
                                );
                            } else {
                                queryParamsObserver.unsubscribe();
                                paramsObserver.unsubscribe();
                            }
                        });
                    });


                }
            );
        }
    }

    protected addURLParams(column: Column) {
        if (column.type === ColumnType.autocomplete) {
            if (column.data.foreignListEntityParams === undefined) {
                column.data.foreignListEntityParams = new Array<string>();
            }
            for (const key in this.activedrouter.snapshot.queryParams) {
                if (!this.activedrouter.snapshot.queryParams.hasOwnProperty(key)) {
                    continue;
                }
                if (column.data.foreignListEntityParams[key] === undefined) {
                    column.data.foreignListEntityParams[key] = this.activedrouter.snapshot.queryParams[key];
                }
            }
        }

    }

    get linkedEntitiesNames(): Params {
        const result = {};

        for (const column of this.data.options.columns) {
            result[column.foreignListEntityName] = column.visualName + ' (Autocomplete)';
        }

        return result;
    }


    abstract getItem(id): Observable<Item>;

    abstract save(item: Item, id: any): Observable<BaseResponse<any>>;
}
