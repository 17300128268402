import {Observable, Observer} from 'rxjs';
import {Column} from '../columns/column';
import {Item} from '../item';
import {Value} from '../values/value';
import {ListOptions} from '../remote/responses/structures/data/config/entity/options/list-options';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {GenericAction} from '../actions/generic-action';
import {Params, Router} from '@angular/router';
import {SelectionAction} from '../actions/selection-action';
import {IndividualAction} from '../actions/individual-action';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {ListRequest} from '../../services/rest/requests/list-request';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {FilterList} from '../filters/filter-list';
import {EntityResourceBase} from './entity-resource-base';
import {TranslateService} from '@ngx-translate/core';

/**
 * Created by angro on 22/05/2017.
 */

export class EntityList extends EntityResourceBase<ListOptions> {

    request: ListRequest;
    private translateservice: TranslateService;
    private _filters: FilterList;
    private _fromActionBack: boolean = false;

    constructor(data: EntityData<ListOptions>, protected serviceHolder: RequestServiceHolder, translateService: TranslateService) {
        super(data, serviceHolder, translateService, true);
    }


    createRequest(params, queryParams: Params, translateservice: TranslateService) {
        this.request = new ListRequest(this.serviceHolder, this, params);
        this.request.url.geolocation = this.geolocation;
        this.request.limit = parseInt(queryParams['limit'], 10);
        this.translateservice = translateservice;
        if (!this.request.limit || this.itemsPerPageAvailable.indexOf(this.request.limit) === -1) {
            this.request.limit = this.itemsPerPageAvailable[0];
        }
        const page = localStorage.getItem(this.name);
        if (page && this.checkFromAction()) {
            this.request.page = parseInt(page, null);
        } else {
            this._fromActionBack = false;
        }
    }

    public checkFromAction(): boolean {
        const page = localStorage.getItem('last_entity');
        if (page) {
            for (const action of [...this.options.actionsIndividual, ...this.options.actionsToSelection, ...this.options.actionsGeneric]) {
                if (action.entity_name === page) {
                    this._fromActionBack = true;
                    return true;
                }
            }
        }
        return false;
    }

    get fromActionBack(): boolean {
        return this._fromActionBack;
    }

    getGenericActions(router: Router, queryParams: Params): Observable<GenericAction> {
        return new Observable<GenericAction>((observer) => {
            for (const action of this.options.actionsGeneric) {

                if (!!action.dynamicParams) {
                    action.params = {...action.params, ...this.getFilteredParams(queryParams, action.dynamicParams, action.dynamicParamsCorrespondences, null)};
                }
                this.entitiesFinder.findEntityByName(action.entity_name).subscribe((entity) => {
                    entity.setPageName(this.pageName);
                    observer.next(new GenericAction(action, router, this.serviceHolder, entity, this.component, this.urlParams));
                });
            }
        });
    }

    getSelectionActions(router: Router, queryParams: Params): Observable<SelectionAction> {
        return new Observable<SelectionAction>((observer) => {
            for (const action of this.options.actionsToSelection) {
                if (!!action.dynamicParams) {
                    action.params = {...action.params, ...this.getFilteredParams(queryParams, action.dynamicParams, action.dynamicParamsCorrespondences, null)};
                }
                this.entitiesFinder.findEntityByName(action.entity_name).subscribe((entity) => {
                    entity.setPageName(this.pageName);
                    observer.next(new SelectionAction(action, router, this.serviceHolder, entity, this.component, this.urlParams));
                });
            }
        });
    }

    getIndividualActions(router: Router, item: Item, queryParams: Params): Observable<IndividualAction> {
        return new Observable<IndividualAction>((observer) => {
            for (const actionData of this.options.actionsIndividual) {
                this.entitiesFinder.findEntityByName(actionData.entity_name).subscribe((entity) => {
                    let pagename = item.getNameOrNull(this.data.options.crumbFields);
                    const action = new IndividualAction(actionData, router, this.serviceHolder, entity, this.component, item.getPrimary(), this.urlParams);
                    if (!!actionData.dynamicParams) {
                        const filteredParams = this.getFilteredParams(queryParams, actionData.dynamicParams, actionData.dynamicParamsCorrespondences, item.getValues(actionData.dynamicParams));
                        action.params = {...action.params, ...filteredParams};
                    }
                    pagename = pagename ? pagename : this.pageName;
                    if (pagename !== undefined) {
                        action.params['entity_pagename'] = (pagename ? pagename : this.pageName);
                    }
                    observer.next(action);
                });
            }
        });
    }

    private getFilteredParams(queryParams: Params, actionDynamicParams: any[], dynamicParamsCorrespondences: any[], params: Params) {
        const filteredParams = [];

        for (const key in actionDynamicParams) {
            if (!actionDynamicParams.hasOwnProperty(key)) {
                continue;
            }

            let actionDynParam = actionDynamicParams[key];
            if (dynamicParamsCorrespondences !== undefined) {
                actionDynParam = dynamicParamsCorrespondences[actionDynamicParams[key]];
            }
            if (params !== null) {
                filteredParams[actionDynParam] = params[actionDynamicParams[key]];
            }
            const value = queryParams[actionDynamicParams[key]];
            if (value !== undefined) {
                filteredParams[actionDynParam] = value;
            }
        }

        return filteredParams;
    }

    createFilterList(params: Params): Observable<FilterList> {
        return new Observable<FilterList>(observer => {
            this.columns.subscribe(columns => {
                const filterList = new FilterList(columns, this.serviceHolder, params, this.translateservice, this.filtersEnabled);

                observer.next(filterList);
            });
        });
    }

    get restMethod(): string {
        return 'GET';
    }

    get itemsPerPageAvailable(): number[] {
        if ('itemsPerPageAvailable' in this.options) {
            return this.options.itemsPerPageAvailable;
        }

        return [10, 20, 50, 100];
    }

    get columnsAsRowItem(): Observable<Item> {
        return new Observable((obs: Observer<Item>) => {
            this.columns.subscribe((columns: Column[]) => {
                const item = new Item();
                for (const column of columns) {
                    item.addValue(new Value(column, column.visualName));
                }

                obs.next(item);
            });
        });
    }

    get filtersEnabled(): boolean {
        let filtersEnabled = this.data.options.filtersEnabled;

        if (typeof filtersEnabled === 'undefined') {
            filtersEnabled = true;
        }

        return filtersEnabled;
    }

    get listInfoVisible(): boolean {
        let listInfoVisible = this.data.options.listInfoVisible;

        if (typeof listInfoVisible === 'undefined') {
            listInfoVisible = true;
        }

        return listInfoVisible;
    }


    get linkedEntitiesNames(): Params {
        let result: Params = {};

        result = this.getEntitiesNamesFromActions(this.data.options.actionsIndividual);
        result = {...result, ...this.getEntitiesNamesFromActions(this.data.options.actionsToSelection)};
        result = {...result, ...this.getEntitiesNamesFromActions(this.data.options.actionsGeneric)};

        for (const column of this.data.options.columns) {
            if (column.visible) {
                result[column.foreignListEntityName] = column.visualName + ' (Filter)';
            }
        }

        return result;
    }

    getEntitiesNamesFromActions(actions: ActionData[]): Params {
        const result: Params = {};

        for (const action of actions) {
            result[action.entity_name] = action.visualName;
        }

        return result;
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

    setFilters(filterList: FilterList) {
        this._filters = filterList;

    }

    get filters(): FilterList {
        return this._filters;
    }

}
