import {MenuItem} from '../model/menu-item';
import {EntitiesHandlerService} from './entities/entities-handler.service';
import {GeneralConfigService} from './general-config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class Menu {
    private _menu: MenuItem;

    constructor(private entitiesHandler: EntitiesHandlerService, private generalConfig: GeneralConfigService) {

    }

    get menu(): Observable<MenuItem> {
        return new Observable<MenuItem>(observer => {
            if (this._menu !== undefined) {
                observer.next(this._menu);
                return;
            }

            this.generalConfig.config.subscribe(config => {
                this._menu = new MenuItem(config.menu, this.entitiesHandler);
                observer.next(this._menu);
            });
        });
    }
}