import {EntityBase} from './entity-base';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {Params} from '@angular/router';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {IframeOptions} from '../remote/responses/structures/data/config/entity/options/iframe-options';

export class EntityIframe extends EntityBase<IframeOptions> {

    constructor(data: EntityData<IframeOptions>, entitiesFinder: EntitiesFinderService) {
        super(data, entitiesFinder);
    }

    get restMethod(): string {
        return 'GET';
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

}
