import {MenuItemData} from './remote/responses/structures/data/config/menus/menu-item-data';
import {EntitiesFinderService} from '../services/entities/entities-finder.service';
import {Entity} from './entities/entity';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/Rx';

/**
 * Created by angro on 12/05/2017.
 */

export class MenuItem {


    private _name = '';
    private _url: string = undefined;
    private _children: MenuItem[] = [];
    private _icon;
    private _icon_menu_selector;
    private _params: { [index: string]: any } = {};
    private _entity: Entity;
    private entityName: string;
    private default: boolean;
    private _submenuOpen: boolean;
    private _animationState = 'close';
    private _observableActiveClassItem = new Subject<boolean>();

    constructor(data: MenuItemData, private entitiesFinder: EntitiesFinderService, name?: string) {
        this._icon = data._icon;
        this._icon_menu_selector = data._icon_menu_selector;
        this._name = name;
        this.default = !!data._default;

        if (typeof data._entity_name === 'undefined') {
            this.entityName = undefined;
            this._url = name !== '' && name !== undefined ? name.toLowerCase().replace(' ', '_') : undefined;
            this.findChildren(data);
        } else {
            this.entityName = data._entity_name;
            this.setupLinkItem(data);
        }
    }

    get observableActiveClassItem(): Subject<boolean> {
        return this._observableActiveClassItem;
    }


    get animationState(): string {
        return this._animationState;
    }

    set animationState(value: string) {
        this._animationState = value;
    }

    get submenuOpen(): boolean {
        return this._submenuOpen;
    }

    set submenuOpen(value: boolean) {
        this._submenuOpen = value;
    }

    private findChildren(data: MenuItemData) {
        for (const key in data) {
            if (!data.hasOwnProperty(key) || key.startsWith('_')) {
                continue;
            }

            const value = data[key];

            this.addChildren(new MenuItem(value, this.entitiesFinder, key));
        }
    }

    get entity(): Observable<Entity> {
        return new Observable<Entity>((observer) => {
            if (!!this._entity) {
                observer.next(this._entity);
                return;
            }

            this.entitiesFinder.findEntityByName(this.entityName).subscribe(entity => {
                this._entity = entity;
                observer.next(entity);
            });
        });
    }

    private setupLinkItem(data: MenuItemData) {
        this.entity.subscribe(entity => {
            this._url = '/' + this._entity.getVisualUrl().path;

            if (typeof data._params !== 'undefined') {
                this.params = data._params;
            }
        });
    }

    public addChildren(child: MenuItem) {
        this._children.push(child);
    }

    get icon() {
        return this._icon;
    }

    get iconMenuAdvanced() {
        if (this._icon_menu_selector) {
            return this._icon_menu_selector;
        } else {
            return this._icon;
        }
    }

    hasIcon(): boolean {
        return typeof this.icon !== 'undefined';
    }

    get name(): string {
        return this._name;
    }

    get url(): string {
        return this._url;
    }

    get children(): MenuItem[] {
        return this._children;
    }

    public isCategory(): boolean {
        return typeof this.entityName === 'undefined';
    }

    get numberChildren(): number {
        return this.children.length;
    }

    get params(): {} {
        return this._params;
    }

    get paramsString(): string {
        const params: string[] = [];

        for (const key in this._params) {
            if (!this._params.hasOwnProperty(key)) {
                continue;
            }

            params.push(encodeURIComponent(key) + '=' + encodeURIComponent(this.params[key]));
        }

        return params.join('&');
    }

    set params(value: {}) {
        this._params = value;
    }

    get defaultItem(): MenuItem {
        let found: MenuItem;
        this.children.map(item => {
            if (found !== undefined) {
                return;
            }

            if (item.isCategory()) {
                found = item.defaultItem;
            } else if (item.default) {
                found = item;
            }
        });

        return found;
    }
}
