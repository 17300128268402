import {ColumnData} from '../remote/responses/structures/data/config/entity/options/column-data';
import {ColumnType} from './column-type';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {ValueDate} from '../values/value-date';
import {ValueDateTime} from '../values/value-date-time';
import {ValueImage} from '../values/value-image';
import {Value} from '../values/value';
import {Url} from '../url';
import {FilterPosition} from '../filters/filter-position';
import {TranslateService} from '@ngx-translate/core';
import {ValueTime} from '../values/value-time';
import {formatDate} from '@angular/common';

export enum SortMethod {
    asc, desc, none
}

export class Column {
    readonly data: ColumnData;
    private _type: ColumnType = ColumnType.text;
    private _sortMethod: SortMethod = SortMethod.none;
    private static columnOrdered: Column = null;
    private _values: { id: number | string, value?: string }[] = [];
    private _foreignListUrl: Url;
    cssName: string;
    position: FilterPosition;


    constructor(columnData: ColumnData, translateService: TranslateService, entityFinder?: EntitiesFinderService) {
        this.data = columnData;
        this.cssName = columnData.name.replace(/[\-.]/, '_');
        if (this.data.type) {
            this._type = ColumnType.types[this.data.type];
        }

        this.data.sortable = Column.getBoolean(this.data.sortable, true);
        this.data.required = Column.getBoolean(this.data.required, false);
        this.data.visible = Column.getBoolean(this.data.visible, true);

        if (columnData.values) {
            this.values = columnData.values;
        }
        /*     if (!columnData.required && !(this.type === ColumnType.autocomplete || this.type === ColumnType.select)) {
                 this.values.unshift({id: '', value: ''});
             }*/
        if (this.data.type == ColumnType.checkbox.name) {
            translateService.get('Yes').subscribe((res: string) => {

                this.values = [{id: 0, value: 'No'}, {id: 1, value: res}];

            });
        }

        this.position = FilterPosition[columnData.filterPosition];

        if (this.position === undefined) {
            this.position = FilterPosition.TABLE_HEAD;
        }

        this.setAutocompleteUrl(entityFinder);
    }

    private setAutocompleteUrl(entityFinder: EntitiesFinderService) {
        if (this.data.foreignListEntityName) {
            entityFinder.findEntityByName(this.data.foreignListEntityName).subscribe((entity) => {
                this._foreignListUrl = entity.getRestUrl();
            });
        }
    }

    get foreignListUrl(): Url {
        return this._foreignListUrl;
    }

    private static getBoolean(value: boolean, defaultValue: boolean) {
        if (typeof value === 'undefined') {
            return defaultValue;
        }

        return !!value;
    }

    get name(): string {
        return this.data.name;
    }

    get formControlName(): string {
        return this.name.replace('.', '__');
    }

    get primary(): boolean {
        return this.data.primary;
    }

    get visualName(): string {
        return this.data.visualName;
    }

    get addEntityName(): string {
        return this.data.addEntityName;

    }

    get type(): ColumnType {
        return this._type;
    }

    get toolTip(): string {
        return this.data.toolTip;
    }

    get placeholder(): string {
        // return this.data.placeholder ? this.data.placeholder : '';
        return this.data.placeholder ? this.data.placeholder : this.visualName;
    }

    get sortable(): boolean {
        return this.data.sortable;
    }

    public changeOrder() {
        this.sortMethod = (this._sortMethod == SortMethod.none || this._sortMethod == SortMethod.desc) ? SortMethod.asc : SortMethod.desc;
    }

    set sortMethod(value: SortMethod) {
        if (Column.columnOrdered !== null && value != SortMethod.none) {
            Column.columnOrdered.sortMethod = SortMethod.none;
        }

        if (value != SortMethod.none) {
            Column.columnOrdered = this;
        }

        this._sortMethod = value;
    }

    get sortMethod(): SortMethod {
        return this._sortMethod;
    }

    get headerClasses(): any {
        const sorted = this._sortMethod != SortMethod.none;

        const headerClasses = {
            sorting: this.sortable && !sorted,
            sorting_asc: false,
            sorting_desc: false
        };

        if (sorted) {
            headerClasses.sorting_asc = this._sortMethod == SortMethod.asc;
            headerClasses.sorting_desc = this._sortMethod == SortMethod.desc;
        }

        return headerClasses;
    }


    get required(): boolean {
        return this.data.required;
    }

    set values(values: { id: number | string, value?: string }[]) {
        for (const value of values) {
            if (!('value' in value)) {
                value.value = value.id.toString();
            }

            this._values.push(value);
        }
    }

    get values(): { id: number | string, value?: string }[] {
        return this._values;
    }

    get visible(): boolean {
        return this.data.visible;
    }

    getValueById(id: any) {
        for (const item of this.values) {
            if (item.id == id) {
                return item.value;
            }
        }

        return id;
    }

    createValue(translateservice: TranslateService, value?: any) {
        if (typeof value === 'undefined') {
            value = '';
        }
        switch (this.type.name) {
            case ColumnType.date.name:
                return new ValueDate(this, value === 'now' ? formatDate(new Date(), 'dd/MM/yyyy', 'en') : value, translateservice);
            case ColumnType.datenorange.name:
                return new ValueDate(this, value, translateservice);
            case ColumnType.datetime.name:
                return new ValueDateTime(this, value === 'now' ? formatDate(new Date(), 'dd/MM/yyyy HH:mm', 'en') : value, translateservice);
            case ColumnType.time.name:
                return new ValueTime(this, value === 'now' ? formatDate(new Date(), 'HH:mm', 'en') : value, translateservice);
            case ColumnType.image.name:
                return new ValueImage(this, value);
            default:
                return new Value(this, value);
        }
    }

    getDefaultValue() {
        return this.data.default !== undefined ? this.data.default : '';
    }
}
