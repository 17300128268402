import {AddData} from '../../../model/remote/responses/structures/data/add-data';
import {EntityAdd} from '../../../model/entities/entity-add';
import {Item} from '../../../model/item';
import {ResponseStructure} from '../../../model/remote/responses/structures/response-structure';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {AddResponse} from '../../../model/remote/responses/add-response';
import {RequestServiceHolder} from '../request-service-holder';
import {ChangeRequest} from './change-request';

export class AddRequest extends ChangeRequest<AddData> {

    constructor(serviceHolder: RequestServiceHolder, private entity: EntityAdd, private item: Item) {
        super(entity.getRestUrl(), entity.restMethod, serviceHolder);

        for (let value of item.values) {
            this.addRequestValue(value.column.name.replace('.', '__'), value.parameterValue);
        }
    }

    protected createResponse(data: ResponseStructure<AddData>): BaseResponse<AddData> {
        return new AddResponse(data, this.serviceHolder);
    }
}
