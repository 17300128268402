import {Component} from '@angular/core';
import {InputBase} from './input-base';

@Component({
    selector: 'ol-input-textarea',
    template: '<ng-container [formGroup]="form">' +
    '<textarea [formControlName]="formControlName" class="form-control" [placeholder]="placeholder" [value]="inputValue" [id]="name" [name]="name"' +
    '           (keyup.enter)="onEnterPressed()" (keyup.esc)="onEscapePressed()"></textarea>' +
    '</ng-container>'

})
export class InputTextareaComponent extends InputBase {
}
