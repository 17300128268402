import {RequestBase} from './request-base';
import {SingleResourceData} from '../../../model/remote/responses/structures/data/single-resource-data';
import {EntityResource} from '../../../model/entities/entity-resource';
import {ResponseStructure} from '../../../model/remote/responses/structures/response-structure';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {SingleResourceResponse} from '../../../model/remote/responses/single-resource-response';
import {RequestServiceHolder} from '../request-service-holder';
import {TranslateService} from '@ngx-translate/core';

export class SingleResourceRequest extends RequestBase<SingleResourceData> {
    private entity: EntityResource;
    private translate: TranslateService;

    constructor(id: any, serviceHolder: RequestServiceHolder, entity: EntityResource, translateservice: TranslateService) {
        super(entity.getRestUrl({id: id}), 'GET', serviceHolder);
        this.translate = translateservice;
        this.entity = entity;
    }

    protected createResponse(data: ResponseStructure<SingleResourceData>): BaseResponse<SingleResourceData> {
        return new SingleResourceResponse(data, this.entity, this.translate, this.serviceHolder);
    }
}
