import {TableComponent} from './table.component';
import {Component, OnInit} from '@angular/core';
import {FilterStepValues} from '../../model/filter-step/filter-step-values';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityTableClassifier} from '../../model/entities/entity-table-classifier';
import {Item} from '../../model/item';
import {SelectionAction} from '../../model/actions/selection-action';
import {FilterStep} from '../../model/remote/responses/structures/data/config/entity/options/filter-step';
import {FilterStepParentPath} from '../../model/filter-step/filter-step-parent-path';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {TranslateService} from '@ngx-translate/core';
import {RestService} from '../../services/rest/rest.service';
import {MatDialog} from '@angular/material';

@Component({
    templateUrl: './table.component.html'
})
export class TableClassifierComponent extends TableComponent implements OnInit {
    stepValues: FilterStepValues[] = [];
    private currentStepId = -1;
    private itemsSelected: { [id: number]: Item } = {};
    private nextAction: SelectionAction;

    constructor(router: Router, activatedRoute: ActivatedRoute, bodyAttributesSetter: BodyAttributesSetter, protected translateService: TranslateService, http: RestService, dialog: MatDialog) {
        super(router, activatedRoute, http, dialog, bodyAttributesSetter, translateService);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.setAction();
        this.setupStep(this.entityClassifier.options.steps);
        this.next();
    }

    protected addItemSelected(item: Item): any {
        super.addItemSelected(item);
        this.itemsSelected[item.getPrimary()] = item;
    }

    protected removeSelectedItem(item: Item): any {
        super.removeSelectedItem(item);
        delete this.itemsSelected[item.getPrimary()];
    }

    get entityClassifier(): EntityTableClassifier {
        return <EntityTableClassifier>this.entity;
    }

    setAction() {
        this.entityClassifier.createAction(() => {
            if (this.isLastStep()) {
                this.send();
            } else {
                this.next();
            }
        }, this).subscribe((action) => {
            this.nextAction = action;
            this.actions.selection.push(action);
        });
    }

    isLastStep(): boolean {
        return this.currentStepId >= this.stepValues.length - 1;
    }

    setupStep(step: FilterStep, parent?: FilterStepParentPath) {
        const id = this.stepValues.length;

        this.stepValues.push({
            id,
            step,
            parent,
            checked: [],
            unchecked: []
        });

        if (typeof step.checked === 'object') {
            this.setupStep(<FilterStep>step.checked, {id, checked: true});
        }

        if (typeof step.unchecked === 'object') {
            this.setupStep(<FilterStep>step.unchecked, {id, checked: false});
        }
    }

    get currentStepValue(): FilterStepValues {
        return this.getValue(this.currentStepId);
    }

    getValue(id: number) {
        return this.stepValues[id];
    }

    get unselectedItems(): Item[] {
        const allItems = this.getParentItems(this.currentStepValue);

        const unselected = [];
        for (const item of allItems) {
            if (!this.itemsSelected.hasOwnProperty(item.getPrimary())) {
                unselected.push(item);
            }
        }

        return unselected;
    }

    private getParentItems(step: FilterStepValues): Item[] {
        if (!step.parent) {
            return [];
        }

        const parentValue = this.getValue(step.parent.id);

        if (!parentValue) {
            return [];
        }

        return step.parent.checked ? parentValue.checked : parentValue.unchecked;
    }

    get selectedItems(): Item[] {
        const result: Item[] = [];

        for (const key in this.itemsSelected) {
            if (this.itemsSelected.hasOwnProperty(key)) {
                result.push(this.itemsSelected[key]);
            }
        }

        return result;
    }

    next() {
        const nextStepValue = this.getValue(this.currentStepId + 1);

        if (this.currentStepValue) {
            this.saveCurrentValues();
            this.items = this.getParentItems(nextStepValue);
            this.totalRows = this.items.length;
            this.filters.enabled = false;
            this.itemsPerPageAvailable = [];
            this.refreshable = false;
            this.actions = {
                general: [],
                individual: [],
                selection: [this.nextAction]
            };
            this.allSelected = false;
        }

        this.selectedIds = [];
        this.itemsSelected = [];
        this.entity.visualName = nextStepValue.step.headerMessage;
        this.currentStepId++;

        if (this.currentStepId > 0) {
            this.itemsPerPage = this.totalRows;
        }

        if (this.isLastStep()) {
            this.nextAction.data.visualName = 'Send';
        }
    }


    updatePage(): void {
        if (this.currentStepId <= 0) {
            super.updatePage();
        }
    }

    private saveCurrentValues() {
        this.currentStepValue.checked = this.selectedItems;
        this.currentStepValue.unchecked = this.unselectedItems;
    }

    send() {
        this.saveCurrentValues();

        const result: { [name: string]: any[] } = {};
        for (const value of this.stepValues) {
            if (typeof value.step.checked === 'string') {
                result[value.step.checked] = [];
                value.checked.map(item => result[<string>value.step.checked].push(item.getPrimary()));
            }

            if (typeof value.step.unchecked === 'string') {
                result[value.step.unchecked] = [];
                value.unchecked.map(item => result[<string>value.step.unchecked].push(item.getPrimary()));
            }
        }

        this.nextAction.params = result;
        this.nextAction.onOk = () => {
            this.entityClassifier.onFinished();
        };
        this.nextAction.doAjax();
    }
}
