import {Component, OnInit} from '@angular/core';
import {InputBase} from './input-base';

@Component({
    selector: 'ol-input-text',
    template: '<ng-container [formGroup]="form">' +
    '<input [formControlName]="formControlName" class="form-control" [placeholder]="placeholder" [id]="name" type="text" [name]="name" ' +
    '   (keyup.enter)="onEnterPressed()" (keyup.esc)="onEscapePressed()"/>' +
    '</ng-container>'
})
export class InputTextComponent extends InputBase {

}
