import {Component, OnInit} from '@angular/core';
import {Item} from '../../model/item';
import {FormComponent} from './form.component';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {EntityUploadFiles} from '../../model/entities/entity-upload-files';
import {AuthService} from '../../services/auth.service';
import {RestService} from '../../services/rest/rest.service';

@Component({
    templateUrl: './upload.component.html',
})
export class UploadComponent extends FormComponent<EntityUploadFiles> implements OnInit {
    uploader: FileUploader;
    private urlReplacements: Params;
    public hasBaseDropZoneOver = true;

    constructor(activatedRoute: ActivatedRoute, location: Location, bodyAttributesSetter: BodyAttributesSetter, private authService: AuthService, private restService: RestService) {
        super(activatedRoute, location, bodyAttributesSetter);

        this.urlReplacements = {...this.restService.globalRequestParams, ...this.urlReplacements};
        this.activatedRoute.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });


    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    ngOnInit() {
        const fileOptions: FileUploaderOptions = {};
        fileOptions.url = this.entity.getRestUrl(this.activatedRoute.params).path;
        fileOptions.additionalParameter = this.urlReplacements;
        fileOptions.headers = [{name: 'X-Auth-Token', value: this.authService.sessionToken}, {name: 'lang', value: this.authService.lang}];
        fileOptions.isHTML5 = true;
        this.uploader = new FileUploader(fileOptions);
        this.uploader.onCompleteAll = () => {
            this.location.back();
        };
        this.uploader.onCancelItem = (ir: FileItem) => {

        };
    }

    getItem(): Observable<Item> {
        return this.entity.getItem(null);
    }


    isAddComponent(): boolean {
        return true;
    }
}
