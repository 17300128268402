import {Entity} from '../model/entities/entity';
import {ActivatedRoute} from '@angular/router';
import {Input} from '@angular/core';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';

export class BaseEntityComponent<T extends Entity> {
    private _entity: T;

    get entity(): T {
        return this._entity;
    }

    @Input()
    set entity(value: T) {
        this.onSetEntity(value);
        this._entity = value;
    }

    protected onSetEntity(entity: T) {

    }

    protected initializeEntity(entity: T) {

    }

    constructor(protected activatedRoute: ActivatedRoute, bodyAttributesSetter: BodyAttributesSetter) {
        this._entity = activatedRoute.snapshot.data['entity'] as T;
        this._entity.setPageName(activatedRoute.snapshot.queryParams['entity_pagename']);
        this._entity.urlParams = activatedRoute.snapshot.params;
        this.initializeEntity(this._entity);
        bodyAttributesSetter.id = 'entity_' + this.entity.name;
    }
}
