import {EntityBase} from './entity-base';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {DetailEditableValue} from '../remote/responses/structures/data/detail/detail-editable-value';
import {RequestBase} from '../../services/rest/requests/request-base';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {Observable} from 'rxjs';
import {DetailData} from '../remote/responses/structures/data/detail/detail-data';
import {DetailCellList} from '../remote/responses/structures/data/detail/detail-cell-list';
import {DetailRow} from '../remote/responses/structures/data/detail/detail-row';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {GenericAction} from '../actions/generic-action';
import {Params, Router} from '@angular/router';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';

export class EntityDetail extends EntityBase<BaseOptions> {
    private actionsData: ActionData[] = [];

    constructor(data: EntityData<BaseOptions>, protected serviceHolder: RequestServiceHolder) {
        super(data, serviceHolder.entitiesFinder);
    }

    get restMethod(): string {
        return 'GET';
    }

    updateField(cell: DetailEditableValue, entityName: string): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.entitiesFinder.findEntityByName(entityName).subscribe(entity => {
                const request = new RequestBase(entity.getRestUrl({id: cell.id}), entity.restMethod, this.serviceHolder);
                request.url.geolocation = this.geolocation;
                request.addRequestValue(cell.fieldName, cell.value);
                request.execute().subscribe(() => observer.next(true), () => observer.next(false));
            });
        });
    }

    getDetail(params): Observable<DetailRow> {
        return new Observable<DetailRow>(observer => {
            const request = new RequestBase<DetailData>(this.getRestUrl(params), this.restMethod, this.serviceHolder);
            request.execute().subscribe((response) => {
                this.actionsData = response.data.actions;

                for (const rowData of response.data.rows) {
                    observer.next({
                        _title: rowData._title,
                        _cells: this.cells(rowData),
                        _entity_name: rowData._entity_name
                    });
                }

                observer.complete();
            });
        });
    }

    getActions(router: Router): Observable<GenericAction> {
        return new Observable<GenericAction>((observer) => {
            if (!this.actionsData) {
                return;
            }

            for (const action of this.actionsData) {
                this.entitiesFinder.findEntityByName(action.entity_name).subscribe((entity) => {
                    observer.next(new GenericAction(action, router, this.serviceHolder, entity, this.component, this.urlParams));
                });
            }
        });
    }

    private cells(row): DetailCellList {
        const cells: DetailCellList = {};

        for (const key in row) {
            if (row.hasOwnProperty(key) && key[0] !== '_') {
                const value = row[key];
                cells[key] = value;
            }
        }

        return cells;
    }

    isEditable(value: any) {
        return typeof value === 'object' && value !== null && 'fieldName' in value;
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}
