import {Permission} from './permission';

export class PermissionGroup {
    readonly title: string;
    readonly permissions: Permission[] = [];
    allEnabled = true;


    constructor(title: string) {
        this.title = title;

    }

    checkPermissions() {
        this.allEnabled = true;
        for (const permissions of this.permissions) {
            if (!permissions.enabled) {
                this.allEnabled = false;
                break;
            }
        }
    }

    enableDisableAll() {
        this.allEnabled = !this.allEnabled;
        for (const permissions of this.permissions) {
            permissions.enabled = this.allEnabled;
        }
    }
}
