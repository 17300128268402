import {InputBase} from './input-base';
import {Component, Directive, OnInit} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';


@Component({
    selector: 'ol-input-image',
    templateUrl: './input-image.component.html',
})
// class FileSelectDirective
export class InputImageComponent extends InputBase implements OnInit {

    uploader: FileUploader = new FileUploader({});

    ngOnInit(): void {
        this.uploader.onAfterAddingFile = (file) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                this.inputValue = fileReader.result;
                this.touch();
            };

            fileReader.readAsDataURL(file._file);
        };
    }

    dragFileAccepted(acceptedFile: File) {
        const fileReader = new FileReader();

        fileReader.onload = () => {
            this.inputValue = fileReader.result;
            this.touch();
        };

        fileReader.readAsDataURL(acceptedFile);
    }

     files: NgxFileDropEntry[] = [];

    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
        for (const droppedFile of files) {

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.dragFileAccepted(file);
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }
}
