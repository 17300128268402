import {Subject} from 'rxjs';
import {BaseResponse} from '../model/remote/responses/base-response';
import {ListData} from '../model/remote/responses/structures/data/list/list-data';
import {RowData} from '../model/remote/responses/structures/data/list/row-data';
import {EntityAutocomplete} from '../model/entities/entity-autocomplete';
import {Column} from '../model/columns/column';
import {Item} from '../model/item';
import {ListRequest} from './rest/requests/list-request';
import {RequestServiceHolder} from './rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';
import {ColumnType} from '../model/columns/column-type';
import {Filter} from '../model/columns/filter';

declare interface RowCache {
    [id: string]: RowData;
}

export class OlsfCompleterDataService extends Subject<any[]> {
    private static _cache: { [url: string]: RowCache } = {};
    private translate: TranslateService;

    constructor(private column: Column, private serviceHolder: RequestServiceHolder, translateService: TranslateService, private item?: Item, private filters?: Filter[]) {
        super();
        this.translate = translateService;
        if (!this.linked && !(this.cacheKey in OlsfCompleterDataService._cache)) {
            OlsfCompleterDataService._cache[this.cacheKey] = {};
        }
    }

    get cacheKey(): string {
        let url = this.column.foreignListUrl.path;
        if (this.column.data.foreignListEntityParams !== undefined) {
            url = url.concat('/' + this.column.data.foreignListEntityParams['id']);
        }

        if (this.linked) {
            const linkedColumn = this.column.data.linkedToColumn;
            const foreignValue = this.item.getValue(linkedColumn.local);

            url += '_' + linkedColumn.foreign + '_' + foreignValue;
        }

        return url;
    }

    private get linked(): boolean {
        return OlsfCompleterDataService.isLinked(this.column, this.item);
    }

    private static isLinked(column: Column, item: Item) {
        return column.data.linkedToColumn && !!item;
    }

    static preloadCache(column, serviceHolder: RequestServiceHolder, translateService: TranslateService, item?: Item) {
        if (OlsfCompleterDataService.isLinked(column, item)) {
            return;
        }

        const completer = new OlsfCompleterDataService(column, serviceHolder, translateService, item);
        completer.search('');
        completer.subscribe();
    }


    search(value: any, inValue?: boolean) {
        if (typeof inValue === 'undefined') {
            inValue = true;
        }


        const dependencyListValue: { [id: string]: string; } = {};
        this.dependencyCheck(dependencyListValue);

        this.createRequest(value, inValue, dependencyListValue).execute().subscribe((response: BaseResponse<ListData>) => {
                const rows: RowData[] = response.data.rows;

                if (!this.column.required) {
                    rows.unshift({id: '', value: ''});
                }

                this.next(rows);

            },
            (error) => this.error(error),
            () => {
                this.complete();
            });
    }

    private dependencyCheck(dependencyListValue: { [p: string]: string }) {
        if (this.column.data.dependencyColumns !== undefined && this.column.data.dependencyColumns !== null && this.column.data.dependencyColumns.length > 0) {
            for (const id in  this.column.data.dependencyColumns) {
                if (!this.column.data.dependencyColumns.hasOwnProperty(id)) {
                    continue;
                }
                if (this.item) {
                    dependencyListValue[this.column.data.dependencyColumns[id]] = this.item.getValue(this.column.data.dependencyColumns[id]);
                } else if (this.filters && this.filters.find(filter => filter.column.name === this.column.data.dependencyColumns[id])) {
                    dependencyListValue[this.column.data.dependencyColumns[id]] = this.filters.find(filter => filter.column.name === this.column.data.dependencyColumns[id]).internalValue;

                }
            }
        }
    }

    private createRequest(value: any, inValue: boolean, dependencyListValue: { [id: string]: string; }): ListRequest {
        const request = new ListRequest(this.serviceHolder, new EntityAutocomplete(this.column.foreignListUrl, this.serviceHolder.entitiesFinder, this.translate), []);
        request.lockScreen = false;
        request.addRequestValue('autocomplete', 1);

        const linkedColumn = this.column.data.linkedToColumn;

        if (this.item && linkedColumn) {
            const foreignValue = this.item.getValue(linkedColumn.local);

            if (foreignValue) {
                request.addRequestValue('filter_' + linkedColumn.foreign, foreignValue);
            }
        }

        if (value && this.column.type !== ColumnType.listquery) {
            request.addRequestValue(inValue ? 'filter_value' : 'filter_id', value);
        } else if (this.column.type !== ColumnType.listquery) {
            request.addRequestValue('limit', 30);
        }
        if (dependencyListValue) {
            for (const key in dependencyListValue) {
                if (dependencyListValue.hasOwnProperty(key)) {
                    request.addRequestValue(key, dependencyListValue[key]);
                }
            }
        }
        if (this.column.data.foreignListEntityParams !== null) {
            request.addRequestValues(this.column.data.foreignListEntityParams);
        }

        return request;
    }

    get cache(): RowCache {
        let cache = OlsfCompleterDataService._cache[this.cacheKey];

        if (!cache) {
            OlsfCompleterDataService._cache[this.cacheKey] = cache = {};
        }

        return cache;
    }

    private saveInCache(rows: RowData[]) {
        for (const row of rows) {
            this.cache[row['id']] = row;
        }
    }

    private searchInCache(value, inValue: boolean): boolean {
        if (inValue) {
            return this.searchInCacheByValue(value);
        } else {
            return this.searchInCacheById(value);
        }
    }

    private searchInCacheByValue(term: string): boolean {
        const rowsToReturn: RowData[] = [];

        for (const id in this.cache) {
            if (!this.cache.hasOwnProperty(id)) {
                continue;
            }

            const row = this.cache[id];

            if (!term || row['value'].indexOf(term) !== -1) {
                if (!row['id']) {
                    rowsToReturn.unshift(row);
                } else {
                    rowsToReturn.push(row);
                }
            }
        }

        this.next(rowsToReturn);

        return rowsToReturn.length > 0;
    }

    private searchInCacheById(id: any): boolean {
        const row: RowData = this.cache[id];
        const found: boolean = !!row;

        if (found) {
            this.next([row]);
        }

        return found;
    }

    cancel(): void {
    }

}
