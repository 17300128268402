import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DashboardElementGroup} from '../../model/remote/responses/structures/data/dashboard/dashboard-element-group';
import {EntityDashboard} from '../../model/entities/entity-dashboard';
import {DashboardSummaryItem} from '../../model/remote/responses/structures/data/dashboard/dashboard-summary-item';
import {BaseEntityComponent} from '../base-entity.component';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'olcharts',
    templateUrl: './dashboard.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent extends BaseEntityComponent<EntityDashboard> implements OnInit {
    summary: DashboardSummaryItem[] = [];
    groups: DashboardElementGroup[] = [];

    constructor(protected activatedRoute: ActivatedRoute, bodyAttributeSetter: BodyAttributesSetter) {
        super(activatedRoute, bodyAttributeSetter);
    }

    ngOnInit(): void {
        this.entity.urlReplacements = this.activatedRoute.snapshot.params;

        this.entity.requestData.subscribe(result => {
            this.groups = result.groups;
            this.summary = result.summary;
        });
    }

    getClassName(group: DashboardElementGroup): string {
        // se añade +2 para que reduzca el número de columnas, ahora detecta 3 y queremos que actue como si tuviera 2
        return "col-md-" + Math.floor(12 / group.columns + 2);
    }
}
