import {Value} from './value';
import * as moment from 'moment';
import {Column} from '../columns/column';
import {DateRangePickerOptions} from '../date-range-picker-options';
import {TranslateService} from '@ngx-translate/core';

export class ValueDate extends Value {
    options: DateRangePickerOptions;
    protected translateService: TranslateService;

    constructor(column: Column, value: any, translateservice: TranslateService) {
        super(column, value);
        this.translateService = translateservice;
        this.createOptions();
    }

    createOptions() {
        this.options = this.baseOptions;

        if (this.value) {
            this.options.startDate = this.value;
        }
    }

    protected get baseOptions(): DateRangePickerOptions {
        return new DateRangePickerOptions('DD/MM/YYYY', false, true, true, this.translateService).setRestParameterFormat('YYYY/MM/DD');
    }

    set selectedDate(value: any) {
        this.value = value.start.format(this.options.locale.format);
    }

    get parameterValue(): any {
        return moment(this.value, this.options.locale.format).format(this.options.restParameterFormat);
    }
}
