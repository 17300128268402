import {Item} from '../../model/item';
import {FormComponent} from './form.component';
import {Location} from '@angular/common';
import {EntityUpdate} from '../../model/entities/entity-update';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Component} from '@angular/core';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {filter, map} from 'rxjs/operators';

@Component({
    templateUrl: './form.component.html'
})
export class EditComponent extends FormComponent<EntityUpdate> {
    constructor(activatedRoute: ActivatedRoute, location: Location, bodyAttributesSetter: BodyAttributesSetter) {
        super(activatedRoute, location, bodyAttributesSetter);
    }

    getItem(): Observable<Item> {
        return new Observable<Item>((observer) => {
            this.activatedRoute.params.subscribe(params => {
                this.id = params['id'];

                this.entity.getItem(this.id).subscribe(item => {
                    observer.next(item);
                });
            });
        });
    }

    saveAndStillAdding() {
        this.buttonDisable = true;
        this.entity.save(this.item, this.id).subscribe((response) => {
            this.buttonDisable = false;
        }, (error) => this.buttonDisable = false, () =>
            this.buttonDisable = false);
    }

    isAddComponent(): boolean {
        return false;
    }
}
