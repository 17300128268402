import {EntityBase} from './entity-base';
import {Observable} from 'rxjs';
import {RequestBase} from '../../services/rest/requests/request-base';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {DashboardData} from '../remote/responses/structures/data/dashboard/dashboard-data';
import {DashboardEntityItem} from '../remote/responses/structures/data/dashboard/dashboard-entity-item';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {Params} from '@angular/router';

export class EntityDashboard extends EntityBase<BaseOptions> {
    private _dashboardData: DashboardData;
    public urlReplacements: Params = {};

    get restMethod(): string {
        return "GET";
    }

    constructor(data: EntityData<BaseOptions>, private serviceHolder: RequestServiceHolder) {
        super(data, serviceHolder.entitiesFinder);
    }

    get requestData(): Observable<DashboardData> {
        return new Observable<DashboardData>(observer => {
            new RequestBase<DashboardData>(this.getRestUrl(this.urlReplacements), this.restMethod, this.serviceHolder).execute().subscribe((result) => {
                this._dashboardData = result.data;
                observer.next(this._dashboardData);
                this.findListEntities();

            });
        });
    }

    findListEntities() {
        this._dashboardData.groups.map(group => group.items.map(item => {
            if ('entityName' in item) {
                this.entitiesFinder.findEntityByName((<DashboardEntityItem>item).entityName).subscribe(entity => (<DashboardEntityItem>item).entity = entity);
            }
        }));
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

}
