import {Filter} from './filter';
import {Column} from './column';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {RestService} from '../../services/rest/rest.service';
import {OlsfCompleterDataService} from '../../services/olsf-completer-data.service';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';

export class FilterAutocomplete extends Filter {
    readonly foreignListUrl: string;

    dataSource: (keyword: any) => Observable<any[]>;
    private timeout;
    private translate: TranslateService;
    private colums: Filter[];

    constructor(column: Column, params: Params, private serviceHolder: RequestServiceHolder, translateService: TranslateService, columns: Filter[]) {
        super(column, params);
        this.colums = columns;
        this.translate = translateService;
        this.foreignListUrl = column.foreignListUrl.path;
        this.createDataSource(column);
        this.dataSource.bind(this);
    }

    protected parseInternalToString(internal: { id: any, value: any }): string {
        return String(internal.id);
    }

    protected parseStringToInternal(value: string): { id: any, value: any } {
        return {id: value, value: value};
    }

    protected parseInternalToVisualValue(internal: { id: any, value: any }) {
        return internal.value;
    }

    private createDataSource(column: Column): void {
        OlsfCompleterDataService.preloadCache(column, this.serviceHolder, this.translate);

        this.dataSource = (keyword: any): Observable<any[]> => {
            const request = new OlsfCompleterDataService(column, this.serviceHolder, this.translate, undefined, this.colums);

            if (!!this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.timeout = undefined;
                request.search(keyword);
            }, 250);

            return request;
        };
    }

}
