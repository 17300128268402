import {Component, Input, OnInit} from '@angular/core';
import {EntityDetail} from '../../model/entities/entity-detail';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DetailRow} from '../../model/remote/responses/structures/data/detail/detail-row';
import {GenericAction} from '../../model/actions/generic-action';
import {UpdatableComponent} from '../updatable.component';
import {BaseEntityComponent} from '../base-entity.component';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {BaseResponse} from '../../model/remote/responses/base-response';
import {DialogInfoComponent} from '../dialog/dialog-info.component';
import {MatDialog} from '@angular/material';

@Component({
    selector: 'ol-detail',
    templateUrl: './detail.component.html'
})
export class DetailComponent extends BaseEntityComponent<EntityDetail> implements OnInit, UpdatableComponent {
    rows: DetailRow[] = [];
    entityName: string;
    values: any[];
    @Input() urlReplacements: Params;
    actions: GenericAction[] = [];
    private dialog: MatDialog;

    constructor(activatedRoute: ActivatedRoute, private router: Router, bodyAttributesSetter: BodyAttributesSetter, dialog: MatDialog) {
        super(activatedRoute, bodyAttributesSetter);
        this.dialog = dialog;
    }


    protected initializeEntity(entity: EntityDetail) {
        this.onSetEntity(entity);
    }

    protected onSetEntity(entity: EntityDetail) {
        entity.component = this;
        this.entityName = entity.visualName;
    }

    updatePage() {
        this.actions = [];
        this.rows = [];
        this.doRequest();
    }

    showDialog(response: BaseResponse<any>) {
        this.dialog.open(DialogInfoComponent, {
            data: {
                title: response.data['title'],
                info: response.data['info']
            },
            disableClose: false

        });
    }

    ngOnInit(): void {
        localStorage.setItem('last_entity', this.entity.name);
        this.activatedRoute.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }

            this.urlReplacements = {...params, ...this.urlReplacements};

            this.updatePage();
        });
    }

    private doRequest() {
        this.entity.getDetail(this.urlReplacements).subscribe(row => this.rows.push(row), () => {
        }, () => {
            this.entity.getActions(this.router).subscribe(action => this.actions.push(action));
        });
    }

    getCellsKeys(object) {
        return Object.keys(object);
    }

    getCell(row: DetailRow, key: string) {
        return row._cells[key];
    }
}
