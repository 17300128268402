import {RestRequest} from '../../services/rest/requests/rest-request';
import {ItemsActionBase} from './items-action-base';

export class SelectionAction extends ItemsActionBase {

    protected getFinalUrl(redirect: boolean) {
        if (redirect) {
            this.url.queryParams = {ids: this.ids.join(',')};
        }

        return this.url;
    }

    protected customizeAjaxRequest(request: RestRequest<any>) {
        request.addRequestValue('ids', this.ids);
    }
}
