import {EntityBase} from './entity-base';
import {BackendOptions} from '../remote/responses/structures/data/config/entity/options/backend-options';
import {Observable, Observer} from 'rxjs';
import {Column} from '../columns/column';
import {EntityResource} from './entity-resource';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {TranslateService} from '@ngx-translate/core';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {ActivatedRoute} from '@angular/router';

export abstract class EntityResourceBase<T extends BackendOptions> extends EntityBase<T> implements EntityResource {
    private _columns: Column[];
    private primaryNeeded = false;
    protected translateService: TranslateService;

    constructor(data: EntityData<T>, protected requestServiceHolder: RequestServiceHolder, translateService: TranslateService,
                primaryNeeded: boolean, private activatedrouter: ActivatedRoute = null) {
        super(data, requestServiceHolder.entitiesFinder);
        this.translateService = translateService;
        this.primaryNeeded = primaryNeeded;
    }

    get restMethod(): string {
        return 'GET';
    }

    get columns(): Observable<Column[]> {
        return new Observable((obs: Observer<Column[]>) => {
            let primary = false;

            if (this._columns !== undefined) {
                obs.next(this._columns);
                return;
            }

            this._columns = [];

            for (const column of this.options.columns) {
                this._columns.push(new Column(column, this.translateService, this.entitiesFinder));
                if (column.primary) {
                    primary = true;
                }
            }

            if (this.primaryNeeded && primary === false) {
                obs.error('Primary fields not found in ' + this.name + ' ' + this.visualName);
            }

            obs.next(this._columns);
        });
    }

    private getColunmsAfterCheck(): Observable<Column[]> {
        return new Observable((obs: Observer<Column[]>) => {
            let primary = false;

            if (this._columns !== undefined) {
                obs.next(this._columns);
                return;
            }

            this._columns = [];

            for (const column of this.options.columns) {
                this._columns.push(new Column(column, this.translateService, this.entitiesFinder));
                if (column.primary) {
                    primary = true;
                }
            }

            if (this.primaryNeeded && primary === false) {
                obs.error('Primary fields not found in ' + this.name + ' ' + this.visualName);
            }

            obs.next(this._columns);
        });
    }
}
