import {Component, OnInit} from '@angular/core';
import {FileElement} from '../model/remote/responses/structures/data/file/file-element';
import {MatDialog, MatMenuTrigger} from '@angular/material';
import {FileService, IFileService} from '../services/file.service';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';
import {EntityFileManager} from '../model/entities/entity-file-manager';
import {RestService} from '../services/rest/rest.service';
import {RequestBase} from '../services/rest/requests/request-base';
import {Url} from '../model/url';
import {AreYouSureDialogComponent} from './dialog/are-you-sure-dialog-component';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {PutNameDialogComponent} from './dialog/put-name-dialog-component';
import {DialogInfoComponent} from './dialog/dialog-info.component';
import {MessageSuccess} from '../services/messages/message-success';
import {BaseEntityComponent} from './base-entity.component';

@Component({
    selector: 'ol-file-manager',
    templateUrl: 'file-manager-component.html'
})
export class FileManagerComponent extends BaseEntityComponent<EntityFileManager> implements OnInit {
    createFolderUrl: Url;
    movetoUrl: Url;
    renameUrl: Url;
    deleteUrl: Url;
    uploadUrl: Url;
    createZipUrl: Url;
    downloadUrl: Url;
    iframeUrl: Url;

    createZipActivated = false;
    currentRoot: FileElement;
    fileElements: FileElement[];
    currentPath: string;
    canNavigateUp = false;
    private urlReplacements: Params;
    fileService: IFileService;
    copyFiles: FileElement[] = [];

    constructor(public dialog: MatDialog, protected serviceHolder: RequestServiceHolder, private bodyClassesSetter: BodyAttributesSetter,
                private router: Router,
                translateService: TranslateService, protected activatedRoute: ActivatedRoute, private restService: RestService) {
        super(activatedRoute, bodyClassesSetter);
        this.fileService = new FileService();
        this.urlReplacements = {...this.restService.globalRequestParams, ...this.urlReplacements};
        this.activatedRoute.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};

        });
        if (this.entity.options.entity_create_folder) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_create_folder).subscribe(folderurl => {
                this.createFolderUrl = folderurl.getRestUrl();
            });
        }

        if (this.entity.options.entity_move_to) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_move_to).subscribe(movetoUrl => {
                this.movetoUrl = movetoUrl.getRestUrl();
            });
        }
        if (this.entity.options.entity_rename) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_rename).subscribe(renameUrl => {
                this.renameUrl = renameUrl.getRestUrl();
            });
        }
        if (this.entity.options.entity_delete) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_delete).subscribe(deleteUrl => {
                this.deleteUrl = deleteUrl.getRestUrl();
            });
        }
        if (this.entity.options.entity_upload_file) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_upload_file).subscribe(uploadUrl => {
                this.uploadUrl = uploadUrl.getRestUrl();
            });
        }
        if (this.entity.options.entity_zip) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_zip).subscribe(createzip => {
                this.createZipUrl = createzip.getRestUrl();
            });
        }
        if (this.entity.options.entity_download) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_download).subscribe(download => {
                this.downloadUrl = download.getRestUrl();
            });
        }
        if (this.entity.options.entity_viewer) {
            this.serviceHolder.entitiesFinder.findEntityByName(this.entity.options.entity_viewer).subscribe(iframe => {
                this.iframeUrl = iframe.getActionUrl();
            });
        }


    }

    deleteElement(element: FileElement) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent);
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.removeElement(element);
            }
        });
    }

    navigate(element: FileElement) {
        if (element.isFolder) {
            this.navigateToFolder(element);
        } else if (this.iframeUrl) {
            const ext = element.name.split('.').pop().toLowerCase();
            if (ext === 'jpg' || ext === 'png' || ext === 'pdf' || ext === 'jpeg') {
                this.urlReplacements['path'] = element.path;
                this.iframeUrl.queryParams = this.urlReplacements;
                this.router.navigateByUrl(this.iframeUrl.path);
            }
        }
    }


    moveElement(element: FileElement, moveTo: FileElement) {
        this.movetoUrl.addUrlParams(this.urlReplacements);
        this.movetoUrl.queryParams = this.urlReplacements;
        const request = new RequestBase<any>(this.movetoUrl, 'PUT', this.serviceHolder);
        request.addRequestValue('file_path', element.path);
        request.addRequestValue('new_path', !moveTo ? '/' : moveTo.path);
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe(it => {
            const root = new FileElement();
            root.path = '';
            root.id = '';
            this.moveElemento({element: element, moveTo: !moveTo ? root : moveTo});
        });

    }


    openMenu(event: MouseEvent, element: FileElement, viewChild: MatMenuTrigger) {
        event.preventDefault();
        viewChild.openMenu();
    }

    openRenameDialog(element: FileElement) {
        const dialogRef = this.dialog.open(PutNameDialogComponent, {
            data: {
                title: 'file-manager.rename_dialog',
                placeholder: 'file-manager.dialog_new_folder_placeholder'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const result = res.split('.').slice(0, -1).join('.');
                res = result ? result : res;
                if (!element.isFolder) {
                    res += '.' + element.name.split('.').pop();
                }
                this.renameElement(element, res);
            }
        });
    }

    openNewFolderDialog() {
        const dialogRef = this.dialog.open(PutNameDialogComponent, {
            data: {
                title: 'file-manager.dialog_new_folder_title_name',
                placeholder: 'file-manager.dialog_new_folder_placeholder'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.addFolder({name: res});
            }
        });
    }

    addFolder(folder: { name: string }) {
        this.createFolderUrl.addUrlParams(this.urlReplacements);
        this.createFolderUrl.queryParams = this.urlReplacements;
        const request = new RequestBase<any>(this.createFolderUrl, 'POST', this.serviceHolder);
        folder.name = folder.name.replace('/', '');
        request.addRequestValue('path', (this.currentRoot ? this.currentRoot.path : '') + '/' + folder.name);
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe(it => {
            this.fileService.add({
                isFolder: true,
                name: folder.name,
                parent: this.currentRoot ? this.currentRoot.id : '',
                path: (this.currentRoot ? this.currentRoot.path : '') + '/' + folder.name,
                checked: false
            });
            this.updateFileElementQuery();
        });

    }

    addFolderInit(folder: { name: string, path: string, id: string }) {
        const file = this.fileService.add({
            isFolder: true,
            name: folder.name,
            parent: folder.id,
            path: folder.path + '/' + folder.name,
            checked: false

        });
        this.updateFileElementQuery();
        return file;
    }

    addFileInit(file: { name: string, path: string, id: string }) {
        this.fileService.add({
            isFolder: false,
            name: file.name,
            parent: file.id,
            path: file.path + '/' + file.name,
            checked: false

        });
        this.updateFileElementQuery();
    }

    addFile(file: { name: string }) {
        let element_not_exit = true;
        if (this.fileElements && this.fileElements.length > 0) {
            this.fileElements.forEach((it, index, arra) => {
                if (it.name === file.name) {
                    element_not_exit = false;
                }
            });
        }
        if (element_not_exit) {
            this.fileService.add({
                isFolder: false,
                name: file.name,
                parent: this.currentRoot ? this.currentRoot.id : '',
                path: (this.currentRoot ? this.currentRoot.path : '') + '/' + file.name,
                checked: false

            });
            this.updateFileElementQuery();
        }
    }

    removeElement(element: FileElement) {
        this.deleteUrl.addUrlParams(this.urlReplacements);
        this.deleteUrl.queryParams = this.urlReplacements;
        const request = new RequestBase<any>(this.deleteUrl, 'DELETE', this.serviceHolder);
        request.addRequestValue('file_path', element.parent + '/' + element.name);
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe(it => {
            this.fileService.delete(element.id);
            this.updateFileElementQuery();
        });

    }

    moveElemento(event: { element: FileElement; moveTo: FileElement }) {
        this.fileService.update(event.element.id, {parent: event.moveTo.id, path: event.moveTo.path + '/' + event.element.name});
        this.updateFileElementQuery();
    }

    renameElement(element: FileElement, res: string) {
        this.renameUrl.addUrlParams(this.urlReplacements);
        this.renameUrl.queryParams = this.urlReplacements;
        const request = new RequestBase<any>(this.renameUrl, 'PUT', this.serviceHolder);
        request.addRequestValue('file_path', element.path);
        request.addRequestValue('new_name', res);
        request.url.geolocation = this.entity.geolocation;
        request.execute().subscribe(it => {
            element.name = res;
            this.fileService.update(element.id, {
                name: element.name,
                path: (this.currentRoot ? this.currentRoot.id : '') + '/' + element.name
            });
            this.updateFileElementQuery();
        });

    }

    updateFileElementQuery() {
        this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : '').subscribe(it => {
            this.fileElements = it;
        });
    }

    navigateUp() {
        if (this.currentRoot && this.currentRoot.parent === '') {
            this.currentRoot = null;
            this.canNavigateUp = false;
            this.updateFileElementQuery();
        } else {
            this.currentRoot = this.fileService.get(this.currentRoot.parent);
            this.updateFileElementQuery();
        }
        this.currentPath = this.popFromPath(this.currentPath);
    }

    navigateToFolder(element: FileElement) {
        this.currentRoot = element;
        this.updateFileElementQuery();
        this.currentPath = this.pushToPath(this.currentPath, element.name);
        this.canNavigateUp = true;
    }

    pushToPath(path: string, folderName: string) {
        let p = path ? path : '';
        p += `${folderName}/`;
        return p;
    }

    popFromPath(path: string) {
        let p = path ? path : '';
        const split = p.split('/');
        split.splice(split.length - 2, 1);
        p = split.join('/');
        return p;
    }

    canZip(element: FileElement) {
        return element.isFolder;
    }

    createZip(element: FileElement) {
        const dialogRef = this.dialog.open(PutNameDialogComponent, {
            data: {
                title: 'file-manager.dialog_zip_title_name',
                placeholder: 'file-manager.dialog_zip_placeholder'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const result = res.split('.').slice(0, -1).join('.');
                res = result ? result : res;
                const name = res + '.zip';
                this.createZipUrl.queryParams = this.urlReplacements;
                this.createZipUrl.addUrlParams(this.urlReplacements);
                const request = new RequestBase<any>(this.createZipUrl, 'POST', this.serviceHolder);
                request.addRequestValue('file_paths', element.path);
                request.addRequestValue('zip_path', (this.currentRoot ? this.currentRoot.path : '') + '/' + name);
                request.url.geolocation = this.entity.geolocation;

                request.execute().subscribe(it => {
                    this.addFile({name: name});
                }, error => {
                    this.dialog.open(DialogInfoComponent, {
                        data: {
                            title: 'Error',
                            info: 'The folder has not files or has empty folders'
                        },
                        disableClose: false

                    });
                });
            }
        });

    }

    download(element) {
        this.downloadUrl.addUrlParams(this.urlReplacements);
        this.downloadUrl.queryParams = this.urlReplacements;
        const request = new RequestBase<any>(this.downloadUrl, 'GET', this.serviceHolder);
        request.addRequestValue('path', element.path);
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe(it => {
            this.restService.downloadFile(it.data.url, it.data.name, it.data.mime_type);
        });

    }

    private initUploader() {
        this.urlReplacements = {...this.serviceHolder.restService.globalRequestParams, ...this.urlReplacements};
        this.activatedRoute.params.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        this.activatedRoute.queryParams.subscribe(params => {
            if (typeof this.urlReplacements === 'undefined') {
                this.urlReplacements = {};
            }
            this.urlReplacements = {...params, ...this.urlReplacements};
        });
        if (this.uploadUrl) {
            this.uploadUrl.addUrlParams(this.urlReplacements);
            this.uploadUrl.queryParams = this.urlReplacements;
            const fileOptions: FileUploaderOptions = {};
            fileOptions.url = this.uploadUrl.path;
            fileOptions.additionalParameter = Object.assign({}, this.urlReplacements);
            fileOptions.additionalParameter['path'] = this.currentRoot ? this.currentRoot.path : '/';
            fileOptions.headers = [{name: 'X-Auth-Token', value: this.serviceHolder.authService.sessionToken}, {
                name: 'lang',
                value: this.serviceHolder.authService.lang
            }];
            fileOptions.isHTML5 = true;
            fileOptions.autoUpload = true;

            const uploader = new FileUploader(fileOptions);
            uploader.onBeforeUploadItem = (fileItem) => {
                this.bodyClassesSetter.loadingVisible = true;
            };
            uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
                this.addFile({name: item.file.name});
                uploader.destroy();
            };
            uploader.onCompleteAll = () => {
                this.bodyClassesSetter.loadingVisible = false;

            };
            return uploader;
        }
    }

    ngOnInit(): void {
        const url = this.entity.getRestUrl(this.urlReplacements);
        url.queryParams = this.urlReplacements;
        url.geolocation = this.entity.geolocation;

        new RequestBase<any>(url, 'GET', this.serviceHolder).execute().subscribe(it => {
            this.iterate(it.data, '', '');
        });
    }

    iterate(obj, path, id) {
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (obj[property] !== null && typeof obj[property] === 'object') {
                    const folder = this.addFolderInit({name: property, path, id});
                    this.iterate(obj[property], path + '/' + property, folder.id);
                } else {

                    this.addFileInit({name: obj[property], path, id});

                }
            }
        }
    }

    createZipMultiple() {
        const dialogRef = this.dialog.open(PutNameDialogComponent, {
            data: {
                title: 'file-manager.dialog_zip_title_name',
                placeholder: 'file-manager.dialog_zip_placeholder'
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                const result = res.split('.').slice(0, -1).join('.');
                res = result ? result : res;
                const name = res + '.zip';
                const request = new RequestBase<any>(this.createZipUrl, 'POST', this.serviceHolder);
                const elements: FileElement[] = [];
                for (const ele of this.fileElements) {
                    if (ele.checked) {
                        elements.push(ele);
                    }
                }
                request.addRequestValue('file_paths', elements.map((item) => item.path).join(','));
                request.addRequestValue('zip_path', (this.currentRoot ? this.currentRoot.path : '') + '/' + name);
                request.url.geolocation = this.entity.geolocation;

                request.execute().subscribe(it => {
                    this.addFile({name: name});
                }, error => {
                    this.dialog.open(DialogInfoComponent, {
                        data: {
                            title: 'Error',
                            info: 'file-manager.dialog_info_error_zip'
                        },
                        disableClose: false

                    });
                });
            }
        });
    }

    canDownload() {
        return this.fileElements.filter(it => {
            return it.checked;
        }).length > 0;
    }

    elementType(element: FileElement) {
        const ext = element.name.split('.').pop();
        switch (ext) {
            case 'jpg':
                return 'image';
            case 'png':
                return 'image';
            case 'pdf':
                return 'picture_as_pdf';
            default:
                return 'insert_drive_file';
        }

    }

    copy(element: FileElement) {
        this.copyFiles[0] = element;
        new MessageSuccess(this.serviceHolder.translateService.instant('filemanager.copy.successfully'),
            this.serviceHolder.translateService.instant('message.success')).setPositionCenter().show();
    }

    pasteFiles() {
        this.moveElement(Object.assign({}, this.copyFiles[0]), this.currentRoot);
        this.copyFiles = [];

    }

    getCols() {
        if (window.innerWidth >= 1024) {
            return 8;
        } else {
            if (window.innerWidth > 767) {
                return 6;
            } else {
                return 4;
            }
        }
    }
}



