import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Item} from '../../model/item';
import {Column} from '../../model/columns/column';
import {EntitiesHandlerService} from '../../services/entities/entities-handler.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ColumnType} from '../../model/columns/column-type';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {ListLink} from '../../model/list-link';
import {ComposedListValue} from '../../model/remote/responses/structures/data/list/composed-list-value';
import {CellLinkData} from '../../model/remote/responses/structures/data/list/cell-link-data';
import {ListColumnLink} from '../../model/remote/responses/structures/data/config/entity/options/list-column-link';
import {Entity} from '../../model/entities/entity';
import {FormGroup} from '@angular/forms';
import {Value} from '../../model/values/value';
import {EntityUpdate} from '../../model/entities/entity-update';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {SimpleTextColumn} from '../../model/columns/simple-text-column';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: '.ol-td',
    templateUrl: './table-cell.component.html'
})
export class TableCellComponent implements OnInit {
    value: ComposedListValue;
    @Input() item: Item;
    @Input() column: Column;
    links: ListLink[] = [];
    fullCellLink: boolean;
    iconLinks: boolean;
    valueLink: ListLink;
    editing = false;
    form = new FormGroup({});
    editEntity: EntityUpdate;
    private editable = false;
    private editColumn: Column;
    @Output() valueChanged: EventEmitter<Value> = new EventEmitter<Value>();

    constructor(private entitiesHandler: EntitiesHandlerService, private sanitization: DomSanitizer,
                private bodyClassesSetter: BodyAttributesSetter, private serviceHolder: RequestServiceHolder, private translateService: TranslateService) {

    }

    ngOnInit(): void {
        this.setValue();
        this.item.getValueModel(this.column).addOnValueChanged(() => this.setValue());
        this.findEditEntity();
        this.createLinks();
    }


    private setValue() {
        this.value = this.getComposedListValue(this.item.getVisualValue(this.column));

        if (this.value.value && this.column.type.equals(ColumnType.image)) {
            this.value.value = this.sanitization.bypassSecurityTrustStyle(`url('${this.value.value}')`);
        }
    }

    private getComposedListValue(value: any): ComposedListValue {
        const result: ComposedListValue = this.isComposedListValue(value) ? value : {value, links: []};

        this.fixOldLinkValues(result);

        return result;
    }

    private isComposedListValue(value: any): value is ComposedListValue {
        return !!value && (value.link_values !== undefined || value.links !== undefined);
    }

    private fixOldLinkValues(value: ComposedListValue): void {
        if (value.link_values) {
            value.links.push({linkValues: value.link_values});
        }
    }

    private findEditEntity() {
        this.editColumn = this.column;
        const editEntityName = this.getEditEntityName();

        if (editEntityName === undefined) {
            return;
        }

        this.entitiesHandler.findEntityByName(editEntityName).subscribe(entity => {
            if (!(entity instanceof EntityUpdate)) {
                throw new Error('entity must be an instance of EntityUpdate');
            }
            this.editEntity = <EntityUpdate>entity;
            this.editable = true;
        });
    }

    private getEditEntityName() {
        const editEntityName = this.editColumn.data.editEntityName;

        if (editEntityName === undefined) {
            const linkedColumn = this.editColumn.data.useFilterOf;

            if (linkedColumn !== undefined) {
                this.editColumn = this.item.getValueModel(linkedColumn).column;
                return this.getEditEntityName();
            }
        }

        return editEntityName;
    }

    private createLinks() {
        const linkData = this.column.data.link;

        if (!linkData || !!this.value.disabledLink) {
            return;
        }

        this.entitiesHandler.findEntityByName(linkData.entityName).subscribe(entity => {
            for (const link of this.value.links) {
                this.createLink(link, linkData, entity);
            }

            if (!this.valueLink) {
                this.createValueLink(linkData, {}, entity);
            }
        });

        this.setFullCellLink();
        this.iconLinks = this.links.length > 0 && !this.fullCellLink;
    }

    setFullCellLink() {
        if (this.links.length === 1 && !this.links[0].icon) {
            this.fullCellLink = true;
        } else if (this.links.length === 0) {
            this.fullCellLink = this.valueLink && (!this.valueLink.replacementsRequired || this.valueLink.withReplacements);
        } else {
            this.fullCellLink = false;
        }
    }

    private createLink(link: CellLinkData, linkData: ListColumnLink, entity: Entity) {
        if (!link.icon) {
            this.createValueLink(linkData, link, entity);
        } else {
            this.links.push(new ListLink(entity, linkData, link, this.item));
        }
    }

    private createValueLink(linkData: ListColumnLink, link: CellLinkData, entity: Entity) {
        if (!!this.valueLink) {
            throw new Error('Only is allowed 1 link without "icon" property per cell');
        }

        this.valueLink = new ListLink(entity, linkData, link, this.item);

        if (this.valueLink.replacementsRequired) {
            this.valueLink = undefined;
        }
    }

    onClick(link: ListLink) {
        if (!!link) {
            link.execute(this.serviceHolder);
        }
    }

    get editableValue(): Value {
        this.editColumn.data.visualName = '';
        return this.item.getValueModel(this.editColumn);
    }

    saveValue() {
        this.bodyClassesSetter.loadingVisible = true;
        const item = this.getItemForSaving();

        this.editEntity.save(item, this.item.getPrimary(), false).subscribe(() => {
            this.updateValueAfterEditing();

            this.editing = false;
            this.bodyClassesSetter.loadingVisible = false;
        }, () => {
            this.editing = false;
            this.bodyClassesSetter.loadingVisible = false;
        });
    }

    updateValueAfterEditing() {
        const value = this.item.getValueModel(this.column);
        if (this.editColumn.name !== this.column.name) {
            value.visualValue = this.editableValue.visualValue;
            value.value = this.editableValue.visualValue;
        } else {
            value.visualValue = this.editableValue.value;
        }

        this.valueChanged.emit(value);

        this.setValue();
    }

    private getItemForSaving() {
        const item = new Item();
        item.addValue(this.editableValue);

        if (this.editColumn.data.onEditSendAllFields) {
            const others = {};
            for (const value of this.item.values) {
                if (value.column.name === this.editableValue.column.name) {
                    continue;
                }

                others[value.column.name.replace('.', '__')] = value.value;
            }

            item.addValue(new Value(new SimpleTextColumn('other_values', this.translateService), others));
        }

        return item;
    }

    edit() {
        this.editing = true;
    }

    cancelEditing() {
        this.item.getValueModel(this.editColumn).visualValue = this.value.value;
        this.item.getValueModel(this.editColumn).value = this.value.value;
        this.editing = false;
    }
}
