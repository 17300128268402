/**
 * Created by angro on 24/05/2017.
 */

import {NgModule} from '@angular/core';
import {AddComponent} from '../components/form/add.component';
import {TableComponent} from '../components/table/table.component';
import {EditComponent} from '../components/form/edit.component';
import {TableRowComponent} from '../components/table/table-row.component';
import {TableCellComponent} from '../components/table/table-cell.component';
import {PaginationComponent} from '../components/table/pagination.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterComponent} from '../components/table/filter.component';
import {RouterModule} from '@angular/router';
import {ActionButtonComponent} from '../components/action-button.component';
import {NguiAutoCompleteModule} from '@ngui/auto-complete/dist/auto-complete.module';
import {PermissionListComponent} from '../components/permission-list.component';
import {SummaryComponent} from '../components/table/summary.component';
import {DetailComponent} from '../components/detail/detail.component';
import {Daterangepicker} from 'ng2-daterangepicker';
import {ImageCreatorComponent} from '../components/image-creator.component';
import {TableClassifierComponent} from '../components/table/table-classifier.component';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {ChartComponent} from '../components/dashboard/chart.component';
import {DashboardTextItemComponent} from '../components/dashboard/dashboard-text-item.component';
import {InputTextComponent} from '../components/form/inputs/input-text.component';
import {InputPasswordComponent} from '../components/form/inputs/input-password.component';
import {InputSelectComponent} from '../components/form/inputs/input-select.component';
import {InputRadioComponent} from '../components/form/inputs/input-radio.component';
import {InputAutocompleteComponent} from '../components/form/inputs/input-autocomplete.component';
import {InputTextareaComponent} from '../components/form/inputs/input-textarea.component';
import {InputCheckboxComponent} from '../components/form/inputs/input-checkbox.component';
import {InputDateComponent} from '../components/form/inputs/input-date.component';
import {InputImageComponent} from '../components/form/inputs/input-image.component';
import {DetailCellComponent} from '../components/detail/detail-cell.component';
import {InputSelectorComponent} from '../components/form/inputs/input-selector.component';
import {SharedModule} from './shared.module';
import {InputHtmlComponent} from '../components/form/inputs/input-html.component';
import {InputTimeComponent} from '../components/form/inputs/input-time.component';
import {FileUploadModule} from 'ng2-file-upload';
import {IframeComponent} from '../components/Iframe.component';

import {ImageDialogComponent, ImagePositionComponent} from '../components/image-position.component';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatToolbarModule,
    MatTreeModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ColorPickerModule} from 'ngx-color-picker';
import {InputRichTextEditorComponent} from '../components/form/inputs/input-rich-text-editor.component';
import {InfoComponent} from '../components/info.component';
import {UploadComponent} from '../components/form/upload.component';
import {QuillModule} from 'ngx-quill';
import {MenuEntitySelectorComponent} from '../components/menu-entity-selector.component';
import {InputUploadComponent} from '../components/form/inputs/input-upload.component';
import {DialogInfoComponent} from '../components/dialog/dialog-info.component';
import {InputColorComponent} from '../components/form/inputs/input-color.component';
import {InboxComponent} from '../components/inbox.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FileManagerComponent} from '../components/file-manager.component';
import {AreYouSureDialogComponent} from '../components/dialog/are-you-sure-dialog-component';
import {PutNameDialogComponent} from '../components/dialog/put-name-dialog-component';
import {DataTablesModule} from 'angular-datatables';
import {ActionDialogComponent} from '../components/table/action-dialog.component';
import {TreeListComponent} from '../components/tree-list.component';
import {ChartsModule} from 'ng2-charts';
import {NgxFileDropModule} from 'ngx-file-drop';
import {InputCanvasComponent} from '../components/form/inputs/input-canvas.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, NguiAutoCompleteModule, QuillModule, FlexLayoutModule, NgxFileDropModule,
        Daterangepicker, ChartsModule, ReactiveFormsModule, SharedModule, FileUploadModule, MatDialogModule, BrowserAnimationsModule, ColorPickerModule, MatTabsModule,
        MatButtonModule, MatFormFieldModule, MatInputModule, MatMenuModule, MatGridListModule, MatIconModule, MatToolbarModule, MatCheckboxModule, DataTablesModule, MatTreeModule],
    exports: [
        FileManagerComponent
    ],
    declarations: [AddComponent, TableComponent, EditComponent, TableRowComponent, TableCellComponent, UploadComponent, TreeListComponent,
        PaginationComponent, FilterComponent, FileManagerComponent, AreYouSureDialogComponent, PutNameDialogComponent, ActionDialogComponent,
        ActionButtonComponent, PermissionListComponent, SummaryComponent, DetailComponent, ImageCreatorComponent,
        TableClassifierComponent, InboxComponent,
        DashboardComponent, ChartComponent, DashboardTextItemComponent, DetailCellComponent,
        InputTextComponent, InputPasswordComponent, InputSelectComponent, InputRadioComponent,InputCanvasComponent,
        InputAutocompleteComponent, InputTextareaComponent, InputUploadComponent, InputColorComponent,
        InputCheckboxComponent, InputDateComponent, InputTimeComponent, InputImageComponent, InputSelectorComponent, InputHtmlComponent, IframeComponent,
        ImagePositionComponent, ImageDialogComponent, DialogInfoComponent, InputRichTextEditorComponent, InfoComponent, MenuEntitySelectorComponent],
    entryComponents: [ImageDialogComponent, DialogInfoComponent, AreYouSureDialogComponent, PutNameDialogComponent, ActionDialogComponent]
})
export class BackendModule {

}
