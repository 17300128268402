import {EmptyData} from '../../../model/remote/responses/structures/data/empty-data';
import {EntityUpdate} from '../../../model/entities/entity-update';
import {Item} from '../../../model/item';
import {ResponseStructure} from '../../../model/remote/responses/structures/response-structure';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {UpdateResponse} from '../../../model/remote/responses/update-response';
import {RequestServiceHolder} from '../request-service-holder';
import {ChangeRequest} from './change-request';

export class UpdateRequest extends ChangeRequest<EmptyData> {

    constructor(serviceHolder: RequestServiceHolder, private entity: EntityUpdate, private item: Item, id) {
        super(entity.getRestUrl({id: id}), entity.restMethod, serviceHolder);
        for (let value of item.values) {
            this.addRequestValue(value.column.name, value.parameterValue);
        }
    }

    protected createResponse(data: ResponseStructure<EmptyData>): BaseResponse<EmptyData> {
        return new UpdateResponse(data, this.serviceHolder) as BaseResponse<EmptyData>;
    }
}
