import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
export class AutocompleteEntityData<T extends BaseOptions> implements EntityData<T> {
    options: T;
    name: string;
    type: string;
    url: string;
    visualName = '';
    restPrefixUrl = '';
    geolocation: boolean;

    constructor(url: string, type: string) {
        this.type = type;
        this.name = type + url;
        this.url = url;
    }


}
