import {Injectable} from '@angular/core';
import {RestPrecondition} from './rest-preconditions';

@Injectable()
export class RestPreconditionsService {
    private observers: (() => void)[] = [];
    private preconditionsDone: { [precondition: number]: boolean } = {};
    private allPreconditionsDone = true;

    constructor() {
        Object.keys(RestPrecondition).map(key => {
            if (!isNaN(Number(key))) {
                this.allPreconditionsDone = false;
                this.preconditionsDone[key] = false;
            }
        });
    }

    setPreconditionDone(precondition: RestPrecondition) {
        this.preconditionsDone[precondition] = true;

        this.allPreconditionsDone = Object.keys(this.preconditionsDone).filter(key => !this.preconditionsDone[key]).length === 0;

        if (this.allPreconditionsDone) {
            this.observers.map(observer => observer());
            this.observers = [];
        }
    }

    onAllPreconditionsDone(observer: () => void) {
        if (this.allPreconditionsDone) {
            observer();
        }
        else {
            this.observers.push(observer);
        }

    }
}