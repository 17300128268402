import {Column} from './column';
import {Params} from '@angular/router';
import {FormGroup} from '@angular/forms';

export class Filter {
    readonly paramName: string;
    readonly typeName: string;
    readonly values: any;

    private _value: string;
    private _internalValue: any;

    visualColumnName = '';
    visualValue = '';
    valueChanged: () => void;
    visible = true;
    column: Column;
    form: FormGroup;

    constructor(column: Column, params: Params) {
        this.column = column;
        this.paramName = 'filter_' + column.name.replace('.', '__');
        this.typeName = column.type.name;
        this.values = column.values;

        if (this.typeName !== 'autocomplete' && this.typeName !== 'listquery') {
            this.value = this.paramName in params ? params[this.paramName] : this.column.getDefaultValue();
        } else {
            this.value = '';
        }
        this.visualColumnName = column.visualName;
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        this._value = value;
        this._internalValue = this.parseStringToInternal(value);
        this.visualValue = this.parseInternalToVisualValue(this._internalValue);
    }

    get internalValue(): any {
        return this._internalValue;
    }

    set internalValue(value: any) {
        this._internalValue = value;
        this._value = this.parseInternalToString(value);
        this.visualValue = this.parseInternalToVisualValue(value);

        if (this.valueChanged) {
            this.valueChanged();
        }
    }

    protected parseInternalToString(internal: any): string {
        return String(internal);
    }

    protected parseStringToInternal(value: string): any {
        return value;
    }

    protected parseInternalToVisualValue(internal: any) {
        return this.parseInternalToString(internal);
    }

    getNowDate() {
        let returnDate = '';
        const today = new Date();
        const dd = today.getDate();
        const mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        returnDate += yyyy;
        if (mm < 10) {
            returnDate += `0${mm}`;
        } else {
            returnDate += `${mm}`;
        }
        if (dd < 10) {
            returnDate += `0${dd}`;
        } else {
            returnDate += `${dd}`;
        }
        return returnDate;
    }
}
