import {Component, EventEmitter, Output} from '@angular/core';
import {Notification} from '../../model/remote/responses/structures/data/notifications/notification';
import {ColorHandler} from '../../services/color-handler';
import {GeneralConfigService} from '../../services/general-config.service';
import {Url} from '../../model/url';
import {NotificationsRequest} from '../../services/rest/requests/notifications-request';
import {PushNotificationService} from 'ng-push-notification/ng-push-notification';
import {Notifications} from '../../model/remote/responses/structures/data/config/notifications/notificaitons';
import {RequestBase} from '../../services/rest/requests/request-base';
import {EmptyData} from '../../model/remote/responses/structures/data/empty-data';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';

@Component({
    selector: '.notifications-component',
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent {
    notifications: Notification[];
    private request: NotificationsRequest;
    private urlDelete: string;
    private _badgeVisible: boolean = true;
    @Output() badgeVisibleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() countChanged: EventEmitter<number> = new EventEmitter<number>();

    constructor(private colorHandler: ColorHandler, private configService: GeneralConfigService, private serviceHolder: RequestServiceHolder,
                private pushNotification: PushNotificationService) {
        configService.config.subscribe(config => {
            const notifications = config.notifications;

            if (!notifications) {
                return;
            }

            this.urlDelete = notifications.urlDelete;

            const url = [RequestBase.restPrefixUrl, notifications.urlGet];

            this.request = new NotificationsRequest(new Url(url, true), 'GET', this.serviceHolder);

            this.pushNotification.requestPermission();

            this.doRequest();
            this.setupRequestInterval(notifications);
        });
    }

    get badgeVisible(): boolean {
        return this._badgeVisible;
    }

    set badgeVisible(value: boolean) {
        this.badgeVisibleChanged.emit(value);
        this._badgeVisible = value;
    }

    private setupRequestInterval(notifications: Notifications) {
        var interval = notifications.interval;

        if (!interval)
            interval = 10;

        window.setInterval(() => this.doRequest(), interval * 1000);
    }

    doRequest() {
        this.request.clear();
        this.request.execute().subscribe(response => {
            const notificationsData = response.data;

            if (this.notifications && this.count < notificationsData.notifications.length) {
                new Audio("assets/notifications.mp3").play();
                this.pushNotification.show('New notifications!', {icon: 'assets/exclamation.png'}, 30);
            }

            this.notifications = notificationsData.notifications;
            this.countChanged.emit(this.count);
        });
    }

    get count(): number {
        return this.notifications ? this.notifications.length : 0;
    };

    getTextColor(notification: Notification) {
        if (!notification.color)
            return false;

        return this.colorHandler.getTextColor(notification.color);
    }

    getBackgroundColor(notification: Notification) {
        if (!notification.color)
            return false;

        return notification.color;
    }

    deleteNotification(id: number, event: Event) {
        event.stopPropagation();

        const url = new Url([RequestBase.restPrefixUrl, this.urlDelete], true, {id: id});

        new RequestBase<EmptyData>(url, "DELETE", this.serviceHolder).execute().subscribe(() => {
            this.notifications = this.notifications.filter(notification => {
                return notification.id !== id;
            });
        });
    }
}