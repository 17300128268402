import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Item} from '../../model/item';
import {Column, SortMethod} from '../../model/columns/column';
import {IndividualAction} from '../../model/actions/individual-action';
import {ActivatedRoute, Router} from '@angular/router';
import {ColorHandler} from '../../services/color-handler';
import {Observable} from 'rxjs';
import {EntityList} from '../../model/entities/entity-list';
import {ValueChanged} from '../../model/values/value-changed';
import {Value} from '../../model/values/value';
import {ColumnType} from '../../model/columns/column-type';
import {DialogInfoComponent} from '../dialog/dialog-info.component';
import {MatDialog} from '@angular/material';
import {ActionDialogComponent} from './action-dialog.component';

@Component({
    selector: '.ol-tr',
    templateUrl: './table-row.component.html',
    host: {
        '[class]': 'classes'
    }
})
export class TableRowComponent implements OnInit {

    @Input() item: Item = null;
    @Input() header = false;
    _even: boolean;
    @Input() selectable: boolean;
    private _selected = false;
    classes = '';
    @Output() sortChanged: EventEmitter<Column> = new EventEmitter();
    @Output() rowLoaded: EventEmitter<Item> = new EventEmitter();
    private _actions: IndividualAction[] = [];
    @Output() selectedChange: EventEmitter<boolean> = new EventEmitter();
    private _entity: EntityList;
    @Output() valueChanged: EventEmitter<ValueChanged> = new EventEmitter<ValueChanged>();

    constructor(private route: ActivatedRoute, private colorHandler: ColorHandler, private router: Router,public dialog: MatDialog) {

    }
    get actions(): IndividualAction[] {
        return this._actions;
    }
    @Input() set entity(entity: EntityList) {
        this._entity = entity;
        if (entity) {
            this.actionsIndividual();
        }
    }

    actionsIndividual() {
        if (typeof this.item !== 'undefined') {
            this._entity.getIndividualActions(this.router, this.item, {...this.route.snapshot.params, ...this.route.snapshot.queryParams}).subscribe((action) => {
                action.setFilters(this._entity.filters);
                this._actions.push(action);
            });
        }
    }

    @Input() set actionsObservable(value: Observable<IndividualAction>) {
        this._actions = [];
        value.subscribe(action => this._actions.push(action));
    }

    private setColumnsDefaultOrder() {
        let sort = this.route.snapshot.queryParams['sort'];
        if (typeof sort === 'undefined') {
            return;
        }

        let columnName = sort.substr(sort.indexOf('_') + 1);
        let sortMethod = sort.indexOf('asc_') === 0 ? SortMethod.asc : SortMethod.desc;
        for (let column of this.item.columns) {
            if (column.name.replace('.', '__') == columnName) {
                column.sortMethod = sortMethod;
                break;
            }
        }
    }

    ngOnInit(): void {
        this.setColumnsDefaultOrder();
        this.rowLoaded.emit(this.item);
    }

    private setClasses() {
        this.classes = '';

        this.classes += this._even ? 'even' : 'odd';

        if (this.selected) {
            this.classes += ' selected';
        }
    }

    @Input() set even(value: boolean) {
        this._even = value;
        this.setClasses();
    }

    public onChangedSelection(): void {
        this.setClasses();
        this.selectedChange.emit(this.selected);
    }

    @Input() set selected(value: boolean) {
        this._selected = value;
    }

    get selected(): boolean {
        return this._selected;
    }

    public clickSelectionCell() {
        this._selected = !this._selected;
        this.onChangedSelection();
    }

    changeOrder(column: Column) {
        if (!column.sortable) {
            return;
        }

        column.changeOrder();
        this.sortChanged.emit(column);
    }

    getValueBackgroundColor(column: Column): string {
        const value = this.item.getValue(column);

        let color;

        if (typeof value === 'object' && !!value) {
            color = 'value.color';
        // celda si tiene color asignado
        } else if (column.data.valueColors) {
            color = column.data.valueColors[value];
        }
        // celda sin color asignado
        // si el color a devolver es blanco lo cambio por inherit para que actue el valor css
        if (!color) {
            return 'inherit';
        }

        return this.colorHandler.normalize(color);
    }

    getValueColor(column: Column): string {
        if (this.getValueBackgroundColor(column) === 'inherit') {
            if (this.item.columns.indexOf(column) % 2 === 0) {
                return this.colorHandler.getTextColor('#FFFFFF');
            } else {
                return this.colorHandler.getTextColor('#f8f9fa');
            }
        }
        return this.colorHandler.getTextColor(this.getValueBackgroundColor(column));
    }

    onValueChanged(value: Value) {
        this.valueChanged.emit({value, item: this.item});
    }

    isHidden(action: IndividualAction): boolean {
        if (action.data.visibilityColumn !== undefined && action.data.visibilityColumn !== null) {
            return !this.item.getValue(action.data.visibilityColumn);
        } else {
            return false;
        }
    }

    checkIfColor(column: Column) {
        return column.type !== ColumnType.color;
    }

    openDialog() {
        this.dialog.open(ActionDialogComponent, {
            data: {
                item: this.item,
               _entity_name: this._entity.name,
                actions: this.actions
            },
            disableClose: false

        });
    }

    getClass(column: Column) {
        if (this.item.columns.indexOf(column) > 3) {
            return 'desktop tablet';
        }
    }
}
