import {OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {EntityChange} from '../../model/entities/entity-change';
import {Item} from '../../model/item';
import {Observable} from 'rxjs';
import {ChangeOptions} from '../../model/remote/responses/structures/data/config/entity/options/change-options';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BaseEntityComponent} from '../base-entity.component';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {RequestBase} from '../../services/rest/requests/request-base';
import {Entity} from '../../model/entities/entity';
import {BaseResponse} from '../../model/remote/responses/base-response';
import {DownloadComponent} from '../download.component';

export abstract class FormComponent<T extends EntityChange<ChangeOptions>> extends BaseEntityComponent<T> implements OnInit, DownloadComponent {
    protected id: any;
    item: Item = new Item();
    saveButtonValue = 'Save';
    saveButtonStyle = 'success';
    form = new FormGroup({});
    formSecondaryColumsDependency: [FormGroup[], string[][]] = [[], []];
    protected translate: TranslateService;
    buttonDisable = false;
    onOk: (response: BaseResponse<any>) => any;

    protected constructor(activatedRoute: ActivatedRoute, protected location: Location,
                          bodyAttributesSetter: BodyAttributesSetter) {
        super(activatedRoute, bodyAttributesSetter);
        this.setupSaveButton();
    }

    private setupSaveButton() {
        const saveButton = this.entity.data.options.saveButton;
        if (!saveButton) {
            return;
        }

        if (saveButton.name) {
            this.saveButtonValue = saveButton.name;
        }

        if (saveButton.style) {
            this.saveButtonStyle = saveButton.style;
        }
    }

    ngOnInit() {
        localStorage.setItem('last_entity', this.entity.name);
        this.getItem().subscribe((item) => {
            this.item = item;
            this.entity.item = item;
            this.dependencyColumnsValidator();
        });
    }

    protected dependencyColumnsValidator() {
        for (const column in this.item.columns) {
            if (this.item.columns[column].data.dependencyColumns !== undefined && this.item.columns[column].data.dependencyColumns !== null
                && this.item.columns[column].data.dependencyColumns.length > 0) {
                this.formSecondaryColumsDependency[0][this.item.columns[column].formControlName] = new FormGroup({});
                this.formSecondaryColumsDependency[1][this.item.columns[column].formControlName] = this.item.columns[column].data.dependencyColumns;
            }
        }

    }

    abstract getItem(): Observable<Item>;

    abstract isAddComponent(): boolean;

    save() {
        this.buttonDisable = true;

        this.entity.save(this.item, this.id).subscribe((response) => {
            this.buttonDisable = false;
            if (response.isSuccess()) {
                this.uploadFiles(response);
                if (response.data.saveEntityUrl !== undefined) {
                    this.entity.data.saveEntityUrl = response.data.saveEntityUrl;
                }
                if (this.entity.data.saveEntityUrl !== undefined) {
                    this.entity.getServiceHolder().entitiesFinder.findEntityByName(this.entity.data.saveEntityUrl)
                        .subscribe(value => {
                                value.getRestUrl() ? this.doAjax(value, response, true) : this.doRedirect(value);
                            }
                        );
                } else {
                    this.location.back();
                }
            }
        }, (error) => this.buttonDisable = false);
    }

    doRedirect(value: Entity) {
        const url = value.getVisualUrl();
        url.queryParams = {...url.queryParams, ...this.activatedRoute.snapshot.params, ...this.activatedRoute.snapshot.queryParams};
        this.entity.getServiceHolder().router.navigateByUrl(url.path);
    }

    doAjax(value: Entity, response: BaseResponse<any>, still = false) {
        this.onOk = value.getMethodOnActionOk(this).bind(this);
        const url = value.getRestUrl({id: response.data[Object.keys(response.data)[0]]});
        url.queryParams = {...this.activatedRoute.snapshot.params, ...this.activatedRoute.snapshot.queryParams, ...response.data};
        url.params = {...this.activatedRoute.snapshot.params, ...this.activatedRoute.snapshot.queryParams, ...response.data};
        const request = new RequestBase(url, value.restMethod, this.entity.getServiceHolder());
        request.addRequestValues(this.entity.urlParams);
        request.type = value.restMethod;
        request.execute().subscribe((response2) => {
            if (response2.isSuccess()) {
                this.onOk(response2);
            }
        });
    }

    private uploadFiles(response) {
        for (const param in response.data) {
            if (response.data.hasOwnProperty(param)) {
                this.entity.uploader.options.additionalParameter[param] = response.data[param];
            }
        }
        this.entity.uploader.onCompleteAll = () => {
            this.entity.uploader.clearQueue();
        };
        this.entity.uploader.uploadAll();
    }

    back() {
        this.location.back();
    }

    saveAndStillAdding() {
        this.buttonDisable = true;
        this.entity.save(this.item, this.id).subscribe((response) => {
                this.buttonDisable = false;
                if (response.isSuccess()) {
                    this.uploadFiles(response);
                    if (response.data.saveEntityUrl !== undefined) {
                        this.entity.data.saveEntityUrl = response.data.saveEntityUrl;
                    }
                    if (this.entity.data.saveEntityUrl !== undefined) {
                        this.entity.getServiceHolder().entitiesFinder.findEntityByName(this.entity.data.saveEntityUrl)
                            .subscribe(value => {
                                    value.getRestUrl() ? this.doAjax(value, response, true) : this.doRedirect(value);
                                }
                            );
                    } else {
                        this.item.resetValues();
                    }
                }
            }, (error) => this.buttonDisable = false,
            () =>
                this.buttonDisable = false
        );
    }

    downloadFile(filename, fileurl, mimetype): void {
        this.entity.getServiceHolder().restService.downloadFile(fileurl, filename, mimetype);
    }
}
