import {Component, HostBinding, Input} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {GeneralConfigService} from '../../services/general-config.service';
import {BodyAttributesSetter} from '../../services/body-attributes-setter.service';
import {RestPreconditionsService} from '../../services/rest/preconditions/rest-preconditions-service';
import {RestPrecondition} from '../../services/rest/preconditions/rest-preconditions';
import {TranslateService} from '@ngx-translate/core';
import {CookiesService} from '../../services/cookies.service';
import {Column} from '../../model/columns/column';
import {RestService} from '../../services/rest/rest.service';

/**
 * Created by angro on 12/05/2017.
 */

@Component({
    selector: 'top-nav',
    templateUrl: './home-top.component.html'
})
export class HomeTopComponent {

    hasNotifications = false;
    profileName = ' ';
    profileMenuDisplayed = false;
    private notificationsBadgeVisible: boolean = true;
    private _notificationsCount: number;
    @HostBinding('class') classHost = 'top_nav';
    menuEntities: string[] = [];
    menusRemaining: number;
    menuLang: string[];
    @Input() top_selector: Column[];
    @Input() showMenu = true;

    constructor(private auth: AuthService, private router: Router, private generalConfig: GeneralConfigService, private restservice: RestService,
                private bodySetter: BodyAttributesSetter, private restPreconditions: RestPreconditionsService,
                protected translate: TranslateService, private cookieservice: CookiesService) {
        this.profileName = this.cookieservice.getCookie('profileName');
        generalConfig.config.subscribe((config) => {
            if (config.topMenusEntityNames) {
                this.menuEntities = config.topMenusEntityNames;
            }
            if (config.available_lang) {
                this.menuLang = config.available_lang;
            }
            if (config.topSecondaryMenu !== undefined) {
                this.menuEntities.push(undefined);
            }

            this.menusRemaining = this.menuEntities.length;
            if (this.menusRemaining === 0) {
                this.onMenuLoaded();
            }

            this.hasNotifications = !!config.notifications;
        });
    }

    onMenuLoaded() {
        this.menusRemaining--;

        if (this.menusRemaining <= 0) {
            this.restPreconditions.setPreconditionDone(RestPrecondition.TOP_MENU);
        }
    }

    get notificationsCount(): number {
        return this._notificationsCount;
    }

    set notificationsCount(value: number) {
        if (value > this._notificationsCount && typeof this.notificationsCount !== 'undefined') {
            this.blinkNotificationsBadge();
        } else if (value == 0) {
            this.notificationsBadgeVisible = false;
        }

        this._notificationsCount = value;
    }

    private blinkNotificationsBadge(count = 0) {
        window.setTimeout(() => {
            this.notificationsBadgeVisible = !this.notificationsBadgeVisible;

            if (count + 1 < 10) {
                this.blinkNotificationsBadge(count + 1);
            }
        }, 200);
    }

    public logout() {
        this.profileMenuDisplayed = false;
        this.auth.logout();
        this.router.navigate(['/'], {queryParams: {'continue': window.location.pathname}});
    }

    toggleLateralMenu() {
        this.bodySetter.toggleMenu();
    }

    setlang(lang: string) {
        window.location.reload();
        this.translate.use(lang);
        this.cookieservice.setCookie('lang', lang);
    }

    selectedTop(event: EventTarget, value: Column) {
        // @ts-ignore
        for (var valu of event) {
            if (valu.selected) {
                for (var col of value.values) {
                    if (col.value === valu.label) {
                        this.cookieservice.setCookie(value.name, col.id.toString());
                        this.restservice.addGlobalRequestParam(value.name, col.id.toString());
                        window.location.reload();
                    }
                }
            }


        }
    }

    isSelected(name: string, option: { id: number | string; value?: string }, i: number) {
        let value = this.cookieservice.getCookie(name);
        let exist = false;
        if (value !== '') {
            for (const column of this.top_selector) {
                if (column.name === name) {
                    for (const val of column.values) {
                        if (val.id.toString() === value) {
                            exist = true;
                        }
                    }
                }
            }
        }
        if (!exist) {
            value = '';
        }
        if (value !== '') {
            if (value === option.id.toString()) {
                this.restservice.addGlobalRequestParam(name, option.id.toString());
                return true;
            } else {
                return false;
            }
        } else if (i === 0) {
            this.restservice.addGlobalRequestParam(name, option.id.toString());
            this.cookieservice.setCookie(name, option.id.toString());
            return true;
        } else {
            return false;
        }
    }

    isMenuStyle(): boolean {
        return this.showMenu;
    }
}
