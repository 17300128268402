import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SecondaryMenuItem} from '../../../model/secondary-menu/secondary-menu-item';

@Component({
    selector: 'ol-top-secondary-menu-item',
    templateUrl: './top-secondary-menu-item.component.html'
})
export class TopSecondaryMenuItemComponent implements OnInit {
    @Input() selectedKey: number | string;
    @Input() item: SecondaryMenuItem;
    @Output() itemSelected: EventEmitter<SecondaryMenuItem> = new EventEmitter<SecondaryMenuItem>();
    @Output() itemChecked: EventEmitter<SecondaryMenuItem> = new EventEmitter<SecondaryMenuItem>();

    constructor() {
    }

    ngOnInit(): void {
        if (this.selectedKey === this.item.key) {
            this.onItemSelected(this.item);
        }
    }

    onCheckboxChanged(item: SecondaryMenuItem, current: boolean) {
        if (current) {
            if (item.submenu) {
                item.children.map(childItem => this.onCheckboxChanged(childItem, true));
                return;
            }

            item.checked = !item.checked;
        }

        this.itemChecked.emit(item);
    }

    onItemSelected(item: SecondaryMenuItem) {
        if (!item.submenu) {
            this.itemSelected.emit(item);
        }
    }
}