import {Component, ViewChild} from '@angular/core';

interface Layer {
    text: string;
    x: number;
    y: number;
    familyFont: any;
    font: string;
    sizeFont: number;
    color: { red: number, green: number, blue: number };
    opacity: number;
    width: number;
    height: number;
    shadow: boolean;
    deleted: boolean;
}

@Component({
    templateUrl: './image-creator.component.html',
    styleUrls: [
        '../css/imageCreator.less'
    ]
})
export class ImageCreatorComponent {
    private mouseLastX: number;
    private mouseLastY: number;
    private draggingLayer: Layer;
    private layerActive: Layer;
    private _text: string;
    private _fontSize: string;
    private _fontFamily: string;
    private _color: any;
    private _shadow: boolean;

    imageDataUrl: string;
    layers: Layer[] = [];
    modificationBarVisible = false;
    initialImage: string | ArrayBuffer = '';

    @ViewChild('canvas', {static: false}) canvas;
    @ViewChild('img', {static: false}) img;

    private get canvasContext(): CanvasRenderingContext2D {
        return this.canvas.nativeElement.getContext('2d');
    }

    get shadow(): boolean {
        return this._shadow;
    }

    set shadow(value: boolean) {
        this._shadow = value;
        this.redraw();
    }

    get color(): any {
        return this._color;
    }

    set color(value: any) {
        this._color = value;
        this.redraw();
    }

    get fontFamily(): string {
        return this._fontFamily;
    }

    set fontFamily(value: string) {
        this._fontFamily = value;
        this.redraw();
    }

    get fontSize(): string {
        return this._fontSize;
    }

    set fontSize(value: string) {
        this._fontSize = value;
        this.redraw();
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = value;
        this.redraw();
    }

    get layerActiveText() {
        return this.layerActive ? this.layerActive.text : '';
    }

    loadImage(event) {
        let reader = new FileReader();
        reader.onload = (e) => this.initialImage = reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }

    draw() {
        this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.canvasContext.drawImage(this.img.nativeElement, 0, 0, 920, 180);
        for (const layer of this.layers) {
            this.canvasContext.font = layer.sizeFont + 'px ' + layer.familyFont;
            this.canvasContext.textBaseline = 'alphabetic';
            this.canvasContext.fillStyle = 'rgba(' + layer.color.red + ',' + layer.color.green + ',' + layer.color.blue + ',' + layer.opacity + ')';

            if (layer.shadow) {
                this.canvasContext.shadowOffsetX = 2;
                this.canvasContext.shadowOffsetY = 2;
                this.canvasContext.shadowColor = 'rgba(0,0,0,0.5)';
                this.canvasContext.shadowBlur = 3;
            } else {
                this.canvasContext.shadowOffsetX = 0;
                this.canvasContext.shadowOffsetY = 0;
                this.canvasContext.shadowColor = 'rgba(0,0,0,0)';
                this.canvasContext.shadowBlur = 0;
            }

            this.canvasContext.fillText(layer.text, layer.x, layer.y);
            layer.width = this.canvasContext.measureText(this.text).width;
        }

        this.imageDataUrl = this.canvas.nativeElement.toDataURL('image/jpg');
    }

    private isTextHitted(x: number, y: number, text: Layer) {
        return (x >= text.x && x <= text.x + text.width && y >= text.y - text.height && y <= text.y);
    }

    getMousePosition(e): { top: number, left: number } {
        return {
            top: (e.clientY + window.pageYOffset) - this.canvas.nativeElement.offsetTop,
            left: (e.clientX + window.pageXOffset) - this.canvas.nativeElement.offsetLeft
        };
    }

    onMouseDown(e) {
        e.preventDefault();
        const mousePosition = this.getMousePosition(e);
        this.mouseLastX = mousePosition.left;
        this.mouseLastY = mousePosition.top;

        for (const layer of this.layers) {
            if (!this.isTextHitted(this.mouseLastX, this.mouseLastY, layer)) {
                continue;
            }

            this.draggingLayer = layer;
            this.layerActive = layer;

            this.modificationBarVisible = true;
            break;
        }
    }

    onMouseUp(e) {
        e.preventDefault();
        this.draggingLayer = undefined;
        this.selectLayer(this.layerActive);
    }

    onMouseOut(e) {
        e.preventDefault();
        this.draggingLayer = undefined;
    }

    onMouseMove(e) {
        if (!this.draggingLayer) {
            return;
        }

        e.preventDefault();
        let mousePosition = this.getMousePosition(e);
        let mouseX = mousePosition.left;
        let mouseY = mousePosition.top;

        let xDifference = mouseX - this.mouseLastX;
        let yDifference = mouseY - this.mouseLastY;
        this.mouseLastX = mouseX;
        this.mouseLastY = mouseY;

        this.draggingLayer.x += xDifference;
        this.draggingLayer.y += yDifference;
        this.draw();
    }

    createNewLayer() {
        let y = this.layers.length * 20 + 20;

        let layer = {
            text: 'Text',
            x: 20,
            y: this.layers.length * 20 + 20,
            familyFont: 'Arial',
            font: '18px Arial',
            sizeFont: 18,
            color: {red: 0, green: 0, blue: 0},
            opacity: 1,
            width: 0,
            height: 26,
            shadow: false,
            deleted: false
        };

        this.layers.push(layer);
        this.draw();
        this.selectLayer(layer);
    }

    erase() {
        this.layerActive.deleted = true;
        for (let i = 0; i < this.layers.length; i++) {
            if (this.layers[i].deleted) {
                this.layers.splice(i, 1);
                i--;
            }
        }
        if (this.layers.length == 0) {
            this.modificationBarVisible = false;
        }

        this.draw();
    }

    private selectLayer(layer) {
        if (!layer) {
            return;
        }

        this.layerActive = layer;

        this.modificationBarVisible = true;

        this._text = this.layerActive.text;
        this._fontSize = String(this.layerActive.sizeFont);
        this._fontFamily = this.layerActive.familyFont;
        this._shadow = this.layerActive.shadow;
        let redValue = this.layerActive.color.red;
        let greenValue = this.layerActive.color.green;
        let blueValue = this.layerActive.color.blue;
        this._color = this.rgbToHex(redValue, greenValue, blueValue);

    }

    redraw() {
        this.layerActive.text = this.text;
        this.layerActive.sizeFont = parseInt(this.fontSize);
        this.layerActive.familyFont = this.fontFamily;
        this.layerActive.color = this.hexToRgb(this.color);
        this.layerActive.shadow = this.shadow;
        this.draw();
    }

    private hexToRgb(hex): { red: number, green: number, blue: number } {
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (fullTextFound, red, green, blue) => {
            return red + red + green + green + blue + blue;
        });

        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            red: parseInt(result[1], 16),
            green: parseInt(result[2], 16),
            blue: parseInt(result[3], 16)
        } : null;
    }

    private rgbToHex(r, g, b) {
        return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

}
