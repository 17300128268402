import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {HomeComponent} from './home/home.component';
import {EditComponent} from './form/edit.component';
import {AddComponent} from './form/add.component';
import {TableComponent} from './table/table.component';
import {PermissionListComponent} from './permission-list.component';
import {DetailComponent} from './detail/detail.component';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {ImageCreatorComponent} from './image-creator.component';
import {TableClassifierComponent} from './table/table-classifier.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotificationsComponent} from './home/notifications.component';
import {TranslateService} from '@ngx-translate/core';
import {CookiesService} from '../services/cookies.service';
import {IframeComponent} from './Iframe.component';
import {ImagePositionComponent} from './image-position.component';
import {InfoComponent} from './info.component';
import {UploadComponent} from './form/upload.component';
import {MenuSelectorComponent} from './home/menu-selector.component';
import {MenuEntitySelectorComponent} from './menu-entity-selector.component';
import {GuardsCheckEnd, Router} from '@angular/router';
import {InboxComponent} from './inbox.component';
import {FileManagerComponent} from './file-manager.component';
import {TreeListComponent} from './tree-list.component';
import {MessageError} from '../services/messages/message-error';


@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    entryComponents: [HomeComponent, TableComponent, AddComponent, EditComponent, PermissionListComponent, UploadComponent, MenuSelectorComponent, TreeListComponent,
        MenuEntitySelectorComponent, InboxComponent, FileManagerComponent,
        DetailComponent, ImageCreatorComponent, TableClassifierComponent, DashboardComponent, NotificationsComponent, IframeComponent, ImagePositionComponent, InfoComponent],
    styleUrls: [
        '../css/fonts/awesome/less/fontawesome.less',
        '../css/fonts/awesome/less/brands.less',
        '../css/fonts/awesome/less/regular.less',
        '../css/fonts/awesome/less/solid.less',
        '../css/pnotify.brighttheme.less',
        '../css/pnotify.buttons.less',
        '../css/pnotify.less',
        '../css/pnotify.nonblock.less',
        '../css/quill.bubble.css',
        '../css/quill.snow.css',
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [],
})
export class AppComponent implements OnInit {
    loaded = false;
    @HostBinding('class') public cssClass = '';
    @HostBinding('id') id = '';

    constructor(private bodyClassesSetter: BodyAttributesSetter, private translate: TranslateService, private cookieservice: CookiesService, private router: Router) {
        translate.use(cookieservice.getCookie('lang') === '' ? translate.getDefaultLang() : cookieservice.getCookie('lang'));
    }

    ngOnInit(): void {
        this.bodyClassesSetter.bodyClassesChangedCallBack = classes => this.setBodyClasses(classes);
        this.bodyClassesSetter.idChangedCallback = (id: string) => this.id = id as string;
        this.router.events.subscribe((event) => {
            if (event instanceof GuardsCheckEnd && event.url === this.router.url && event.id > 2) {
                window.location.reload();
            }
        });
    }

    setBodyClasses(classes: any) {
        const cssClass = [];
        for (const className in classes) {
            if (classes.hasOwnProperty(className) && classes[className]) {
                cssClass.push(className);
            }
        }
        this.cssClass = cssClass.join(' ');
    }


}
