import {InputBase} from './input-base';
import {Component} from '@angular/core';

@Component({
    selector: 'ol-input-select',
    template:
    '<ng-container [formGroup]="form">' +
    '<select class="form-control" [formControlName]="formControlName" [id]="name" [name]="name">' +
    '   <option *ngFor="let option of options" [value]="option.id" > ' +
    '       {{option.value}}' +
    '   </option>' +
    '</select>' +
    '</ng-container>'
})
export class InputSelectComponent extends InputBase {
    get options(): { id: number | string, value?: string }[] {
        return this.innerValue.column.values;
    }
}
