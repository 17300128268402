import {InputBase} from './input-base';
import {AfterViewInit, Component} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'ol-canvas',
    template: '<ng-container [formGroup]="form">' +

        '<canvas  id="signature-pad"  height="200" width="400" class=" signature-pad form-control" ' +
        '       ></canvas>' +
        '    <span class="input-group-btn" style="width: 100%">\n' +
        '<button type="button"  style="width: 100%" class="btn-clean-date btn btn-default fas fa-times"\n' +
        '        (click)="onCancel()"></button>' +
        '        </span>' +
        '</ng-container>'
})
export class InputCanvasComponent extends InputBase implements AfterViewInit {
    private signaturePad: SignaturePad;
    private canvas: HTMLCanvasElement;


    ngAfterViewInit(): void {
        this.canvas = document.getElementById('signature-pad') as HTMLCanvasElement;
        var it = this;
        this.signaturePad = new SignaturePad(this.canvas, {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            penColor: 'rgb(0, 0, 0)',
            onEnd: function () {
                it.inputValue = this.toDataURL();
            }
        });
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        function resizeCanvas() {
            var ratio = Math.max(window.devicePixelRatio || 1, 1);
            it.canvas.width = it.canvas.offsetWidth * ratio;
            it.canvas.height = it.canvas.offsetHeight * ratio;
            it.canvas.getContext('2d').scale(ratio, ratio);
            it.signaturePad.clear();
            it.signaturePad.fromDataURL(it.innerValue.value);

        }

    }

    private onCancel() {
        this.signaturePad.clear();
        this.value.reset();

    }


}
