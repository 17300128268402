import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {RestService} from '../services/rest/rest.service';
import {RequestBase} from '../services/rest/requests/request-base';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {SlideInOutAnimation} from './animation';
import {EntityTreeList} from '../model/entities/entity-tree-list';
import {TreeData} from '../model/remote/responses/structures/data/tree/tree-data';
import {MatTreeNestedDataSource} from '@angular/material';
import {NestedTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {Entity} from '../model/entities/entity';
import {BaseEntityComponent} from './base-entity.component';
import {Subscription} from 'rxjs';
import {EntitiesHandlerService} from '../services/entities/entities-handler.service';


@Component({
    templateUrl: 'tree-list.component.html',
    animations: [SlideInOutAnimation]
})
export class TreeListComponent extends BaseEntityComponent<EntityTreeList> implements OnInit, OnDestroy {

    private urlReplacements: Params;
    treeControl = new NestedTreeControl<TreeData>(node => node.children);
    dataSource = new MatTreeNestedDataSource<TreeData>();
    checklistSelection = new SelectionModel<TreeData>(true /* multiple */);
    subscriptions: Subscription[] = [];
    hasChild = (_: number, node: TreeData) => !!node.children && node.children.length > 0;
    getLevel = (node: TreeData) => node.level;
    entities: Entity[] = [];

    constructor(activatedRoute: ActivatedRoute, private location: Location, private router: Router, private restService: RestService, private serviceholder: RequestServiceHolder,
                private entityService: EntitiesHandlerService,
                private bodyAttributesSetter: BodyAttributesSetter) {
        super(activatedRoute, bodyAttributesSetter);
        this.urlReplacements = {...this.restService.globalRequestParams, ...this.urlReplacements};
        this.subscriptions = [
            this.activatedRoute.params.subscribe(params => {
                if (typeof this.urlReplacements === 'undefined') {
                    this.urlReplacements = {};
                }
                this.urlReplacements = {...params, ...this.urlReplacements};

            }),
            this.activatedRoute.queryParams.subscribe(params => {
                if (typeof this.urlReplacements === 'undefined') {
                    this.urlReplacements = {};
                }
                this.urlReplacements = {...params, ...this.urlReplacements};
            })
        ];
        if (this.entity.data.options.bottom_entity) {
            this.entity.data.options.bottom_entity.forEach(it => {
                console.log(it);
                this.entityService.findEntityByName(it.entity).subscribe(enty => {
                    this.entities.push(enty);

                });
            });

        }


    }

    ngOnInit(): void {
        this.initTree();
    }


    setLevels(treeData: TreeData[], level) {
        for (const item of treeData) {
            item.level = level;
            if (item.children && item.children.length > 0) {
                this.setLevels(item.children, item.level + 1);
            }
        }
    }

    changeItemFinalTree(node: TreeData): void {

        const parent = this.getNodeParent(this.dataSource.data, node);
        if (parent.required_checks && !this.checklistSelection.isSelected(node)) {
            const selectedChilderen = [];
            parent.children.forEach(child => {
                if (this.checklistSelection.isSelected(child)) {
                    selectedChilderen.push(child);
                }
            });
            if (!this.checklistSelection.isSelected(node)) {
                selectedChilderen.push(node);
            }
            if (selectedChilderen.length >= parent.required_checks) {
                for (let i = 0; i < (selectedChilderen.length - parent.required_checks); i++) {
                    this.checklistSelection.deselect(selectedChilderen[i]);
                    this.checkAllParentsSelection(selectedChilderen[i]);
                    //this.checkDescents(selectedChilderen[i]);

                }
            }
        }
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);

    }

    private getNodeParent(nodes: TreeData[], node: TreeData, parent = null): TreeData {
        let parFinal = null;
        nodes.forEach(no => {
            if (no === node) {
                parFinal = parent;
            }
            const par = this.getNodeParent(no.children, node, no);
            if (par) {
                parFinal = par;
            }

        });
        return parFinal;
    }

    initTree() {
        console.log(this.urlReplacements);
        const request = new RequestBase<TreeData[]>(this.entity.getRestUrl(this.urlReplacements), 'GET', this.serviceholder);
        request.url.geolocation = this.entity.geolocation;
        this.subscriptions.push(
            request.execute().subscribe(it => {
                    this.bodyAttributesSetter.loadingVisible = false;
                    this.setLevels(it.data, 0);
                    this.dataSource.data = it.data;
                    this.treeControl.dataNodes = it.data;
                    if (this.entity.options.expanded) {
                        this.treeControl.expandAll();
                    }
                    this.setSelected(this.dataSource.data);
                    this.setExpanded(this.dataSource.data);
                }, e => {
                }, () => {

                    this.bodyAttributesSetter.loadingVisible = false;
                }
            ));
    }

    setSelected(data: TreeData[]): any {
        data.forEach(value => {
            if (value.selected) {
                this.checklistSelection.select(value);
                //this.todoLeafItemSelectionToggle(value);
                //this.todoItemSelectionToggle(value);
            }
            this.setSelected(value.children);
        });
    }


    private setExpanded(data: TreeData[]) {
        data.forEach(value => {
            if (value.expanded !== undefined && value.expanded !== null) {
                value.expanded ? this.treeControl.expand(value) : this.treeControl.collapse(value);
            }
        });
    }

    descendantsAllSelected(node: TreeData): boolean {
        return false;
        /*  const descendants = this.treeControl.getDescendants(node);
          return descendants.every(child => {
                  this.checklistSelection.isSelected(child);
              }
          );*/
    }

    descendantsPartiallySelected(node: TreeData): boolean {
        return false;

        /*   const descendants = this.treeControl.getDescendants(node);
           const result = descendants.some(child => this.checklistSelection.isSelected(child));
           return result && !this.descendantsAllSelected(node);*/
    }

    changeItemWithChildren(node: TreeData): void {
        const parent = this.getNodeParent(this.dataSource.data, node);
        if (parent.required_checks && !this.checklistSelection.isSelected(node)) {
            const selectedChilderen = [];
            parent.children.forEach(child => {
                if (this.checklistSelection.isSelected(child)) {
                    selectedChilderen.push(child);
                }
            });
            if (!this.checklistSelection.isSelected(node)) {
                selectedChilderen.push(node);
            }
            if (selectedChilderen.length >= parent.required_checks) {
                for (let i = 0; i < (selectedChilderen.length - parent.required_checks); i++) {
                    this.checklistSelection.deselect(selectedChilderen[i]);
                    this.checkAllParentsSelection(selectedChilderen[i]);
                    //this.checkDescents(selectedChilderen[i]);

                }
            }
        }
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
        this.checkDescents(node);


    }

    private checkDescents(node) {
        var descendants = this.treeControl.getDescendants(node);
        if (node.spreadable === undefined || node.spreadable === true) {
            descendants = descendants.filter(it => it.selectable);
            this.checklistSelection.isSelected(node)
                ? this.checklistSelection.select(...descendants)
                : this.checklistSelection.deselect(...descendants);

            // Force update for the parent
            descendants.every(child =>
                this.checklistSelection.isSelected(child)
            );
        }
    }

    checkAllParentsSelection(node: TreeData): void {
        let parent: TreeData | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    checkRootNodeSelection(node: TreeData): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (node.selectable) {
            if (nodeSelected && !descAllSelected) {
                this.checklistSelection.deselect(node);
            } else if (!nodeSelected && descAllSelected) {
                this.checklistSelection.select(node);
            }
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TreeData): TreeData | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    buttonClick(action: { name: string; entity: string; style?: string }) {
        this.serviceholder.entitiesFinder.findEntityByName(action.entity).subscribe(it => {
            const values = this.checklistSelection.selected;
            const required = this.getRequired(this.dataSource.data);
            for (const va of required) {
                this.setClass(this.dataSource.data, va, 'required-tree');
                for (const child of va.children) {
                    if (values.indexOf(child) !== -1) {
                        this.setClass(this.dataSource.data, va, '');
                        break;
                    }
                }
            }
            if (this.getRequireClass(this.dataSource.data).length === 0) {
                it.getActionUrl().rest ? this.doAjax(it) : this.doRedirect(it);
            }
        });
    }

    private getRequired(data: TreeData[]): TreeData[] {
        let req: TreeData[] = [];
        for (const child of data) {
            if (child.child_required) {
                req.push(child);
            }
            req = req.concat(this.getRequired(child.children));
        }

        return req;
    }

    private getRequireClass(data: TreeData[]): TreeData[] {
        let req: TreeData[] = [];
        for (const child of data) {
            if (child.class === 'required-tree') {
                req.push(child);
            }
            req = req.concat(this.getRequired(child.children));
        }

        return req;
    }

    private setClass(data: TreeData[], va: TreeData, requiredTree: string) {
        for (const child of data) {
            if (child === va) {
                child.class = requiredTree;
            }
            this.setClass(child.children, va, requiredTree);
        }
    }

    doRedirect(it: Entity) {
        const url = it.getVisualUrl();
        const values = this.checklistSelection.selected;
        url.queryParams = {...url.queryParams, ...this.urlReplacements};
        if (this.entity.options.onlyId) {
            const param = {['tree_list']: values.map(map => map.id)};
            url.queryParams = {...url.queryParams, ...param};
        } else {
            const param = {['tree_list']: JSON.stringify(values)};
            url.queryParams = {...url.queryParams, ...param};
        }
        this.router.navigateByUrl(url.path);
    }

    doAjax(it: Entity) {
        const request = new RequestBase(it.getRestUrl(), it.restMethod, this.serviceholder);
        request.addRequestValues(this.urlReplacements);
        const values = this.checklistSelection.selected;
        if (this.entity.options.onlyId) {
            request.addRequestValue('tree_list', values.map(map => map.id));
        } else {
            request.addRequestValue('tree_list', JSON.stringify(values));
        }

        request.type = it.restMethod;
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe((response) => {
            if (response.isSuccess()) {
                it.getMethodOnActionOk(this).bind(this);
            }
        });
    }

    private changeSelected(values: TreeData[], node: TreeData, value: boolean) {
        values.forEach(data => {
            if (data === node) {
                data.selected = value;
            }
            this.changeSelected(data.children, node, value);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(it => {
            it.unsubscribe();
        });
    }


}




