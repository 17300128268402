import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    exports: [
        TranslateModule,
    ]
})

export class SharedModule { }
