import {Observable, Observer} from 'rxjs';
import {Column} from '../columns/column';
import {Item} from '../item';
import {Value} from '../values/value';
import {ListOptions} from '../remote/responses/structures/data/config/entity/options/list-options';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {GenericAction} from '../actions/generic-action';
import {Params, Router} from '@angular/router';
import {SelectionAction} from '../actions/selection-action';
import {IndividualAction} from '../actions/individual-action';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {ListRequest} from '../../services/rest/requests/list-request';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {FilterList} from '../filters/filter-list';
import {EntityResourceBase} from './entity-resource-base';
import {TranslateService} from '@ngx-translate/core';
import {EntityBase} from './entity-base';
import {TreeOptions} from '../remote/responses/structures/data/config/entity/options/tree-options';

/**
 * Created by angro on 22/05/2017.
 */

export class EntityTreeList extends EntityBase<TreeOptions> {

    request: ListRequest;

    constructor(data: EntityData<TreeOptions>, protected serviceHolder: RequestServiceHolder, translateService: TranslateService) {
        super(data, serviceHolder.entitiesFinder);
    }


    get linkedEntitiesNames(): Params {
        return undefined;
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }

    get restMethod(): string {
        return 'GET';
    }


}
