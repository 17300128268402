import {Component, Input, OnInit} from '@angular/core';
import {InputBase} from './input-base';
import {EntityChange} from '../../../model/entities/entity-change';
import {ChangeOptions} from '../../../model/remote/responses/structures/data/config/entity/options/change-options';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';

@Component({
    selector: 'ol-input-color',
    templateUrl: './input-color.component.html'

})
export class InputColorComponent extends InputBase implements OnInit {
    @Input() entity: EntityChange<ChangeOptions>;
    enabled = false;
    placeHolder;
    noFoundText;
    invalid = false;
    private translate: TranslateService;
    private router: Router;

    constructor(private serviceHolder: RequestServiceHolder, translate: TranslateService, router: Router) {
        super();
        this.translate = translate;
        this.router = router;
        this.translate.get('app.component.loading').subscribe((value => this.placeHolder = value));
        this.translate.get('noFoundText').subscribe((value => this.noFoundText = value));
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setInitialValue();

    }

    setInitialValue() {
        if (!this.value.value && this.value.value !== 0) {
            this.checkValitations();
            this.enable();
            return;
        }

    }

    private enable() {
        this.placeHolder = this.placeholder;
    }

    setColor(value: any) {
        this.control.setValue(value.value);
    }

}
