/**
 * Created by Zirialk on 09/08/2018.
 */
import {EntityBase} from './entity-base';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {UpdatableComponent} from '../../components/updatable.component';
import {Params} from '@angular/router';

export class EntityDeleteMultiple extends EntityBase<BaseOptions> {
    get restMethod(): string {
        return 'DELETE';
    }

    getMethodOnActionOk(component: UpdatableComponent): () => any {
        return component.updatePage;
    }


    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}