import {Column} from '../columns/column';
import {Filter} from '../columns/filter';
import {FilterDate} from '../columns/filter-date';
import {FilterAutocomplete} from '../columns/filter-autocomplete';
import {ColumnType} from '../columns/column-type';
import {FilterDateTime} from '../columns/filter-date-time';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {Params} from '@angular/router';
import {EventEmitter} from '@angular/core';
import {FilterPosition} from './filter-position';
import {TranslateService} from '@ngx-translate/core';
import {FilterDateNorange} from '../columns/filter-date-norange';
import {FilterTime} from '../columns/filter-time';
import {FilterTimeNorange} from '../columns/filter-time-norange';

export class FilterList {
    private columns: Column[];
    private params: Params;
    filterChanged: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
    enabled: boolean;
    filters: { [position: string]: Filter[] } = {};
    private translateService: TranslateService;

    constructor(columns: Column[], serviceHolder: RequestServiceHolder, params: Params, translateservice: TranslateService, enabled: boolean) {
        this.columns = columns;
        this.params = params;
        this.enabled = enabled;
        this.translateService = translateservice;

        if (enabled) {
            this.createFilters(serviceHolder);
        }
    }

    get listForTop(): Filter[] {
        return this.getFilterForPosition(FilterPosition.TOP);
    }

    get listForHead(): Filter[] {
        return this.getFilterForPosition(FilterPosition.TABLE_HEAD);
    }

    getFilterForPosition(position: FilterPosition) {
        let filters = this.filters[FilterPosition[position]];

        if (filters === undefined) {
            filters = this.filters[FilterPosition[position]] = [];
        }

        return filters;
    }

    private createFilters(serviceHolder: RequestServiceHolder) {
        for (const column of this.columns) {
            if (this.isUsedByAnotherColumn(column)) {
                continue;
            }

            let filter = this.createFilter(column, serviceHolder);
            if (filter.column.position === FilterPosition.HIDDEN) {
                filter = this.createHiddenFilter(filter.column);
            }
            if (filter.column.position === FilterPosition.TOP) {
                this.getFilterForPosition(filter.column.position).push(filter);

                filter = this.createHiddenFilter(filter.column);
            }
            if (filter.column.visible) {
                this.listForHead.push(filter);
            }
        }

        this.checkHeadAllHiddens();
    }

    private checkHeadAllHiddens() {
        let allHiddens = 0;

        this.listForHead.map(filter => {
            if (!filter.visible) {
                allHiddens += 1;
            }
        });
        if (allHiddens === this.listForHead.length) {
            this.filters[FilterPosition[FilterPosition.TABLE_HEAD]] = [];
        }
    }

    private isUsedByAnotherColumn(columnToFind: Column) {
        let found = false;

        this.columns.map(column => {
            if (column.data.useFilterOf === columnToFind.name) {
                found = true;
            }
        });

        return found;
    }

    private createHiddenFilter(column: Column): Filter {
        const emptyFilter = new Filter(column, this.params);
        emptyFilter.visible = false;

        return emptyFilter;
    }

    getFilterColumn(column: Column) {
        if (!column.data.useFilterOf) {
            return column;
        }

        return this.getFilterColumn(this.findColumn(column.data.useFilterOf));
    }

    private createFilter(column: Column, serviceHolder: RequestServiceHolder): Filter {
        const visualName = column.visualName;

        const filterColumn = this.getFilterColumn(column);

        let filter: Filter;

        switch (filterColumn.type.name) {
            case ColumnType.date.name:
                filter = new FilterDate(filterColumn, this.params, this.translateService);
                break;
            case ColumnType.datenorange.name:
                filter = new FilterDateNorange(filterColumn, this.params, this.translateService);
                break;
            case ColumnType.datetime.name:
                filter = new FilterDateTime(filterColumn, this.params, this.translateService);
                break;
            case ColumnType.time.name:
                filter = new FilterTime(filterColumn, this.params, this.translateService);
                break;
            case ColumnType.timenorange.name:
                filter = new FilterTimeNorange(filterColumn, this.params, this.translateService);
                break;
            case ColumnType.listquery.name:
            case ColumnType.autocomplete.name:
                filter = new FilterAutocomplete(filterColumn, this.params, serviceHolder, this.translateService, this.all);
                break;
            default:
                filter = new Filter(filterColumn, this.params);
                break;
        }

        filter.visualColumnName = column.visualName;
        filter.valueChanged = () => this.filterChanged.emit();

        return filter;
    }

    private findColumn(name: string): Column {
        let column: Column;

        for (const columnToCheck of this.columns) {
            if (columnToCheck.name === name) {
                column = columnToCheck;
            }
        }

        if (column === undefined) {
            throw new Error('Column' + name + ' not found');
        }

        return column;
    }

    get all(): Filter[] {
        let filters = [];

        for (const position in this.filters) {
            if (this.filters.hasOwnProperty(position)) {
                filters = [...filters, ...this.filters[position]];
            }
        }

        return filters;
    }

    filtersForSave(): Params {
        let params: Params = {};
        for (const position in this.filters) {
            if (this.filters.hasOwnProperty(position)) {
                for (const filter of this.filters[position]) {
                    if (filter.value !== '') {
                        params[filter.paramName] = filter.value;
                    }
                }
            }
        }
        return params;
    }

}
