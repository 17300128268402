import {RequestBase} from './request-base';
import {Url} from '../../../model/url';
import {NotificationsData} from '../../../model/remote/responses/structures/data/notifications/notifications-data';
import {RequestServiceHolder} from '../request-service-holder';

export class NotificationsRequest extends RequestBase<NotificationsData> {

    constructor(url: Url, method: string, serviceHolder: RequestServiceHolder) {
        super(url, method, serviceHolder);

        this.transparent = true;
        this.lockScreen = false;
    }
}
