import {Component, Input} from '@angular/core';
import {MenuItem} from '../../model/menu-item';
import {Menu} from '../../services/menu';

/**
 * Created by angro on 12/05/2017.
 */

@Component({
    selector: 'home-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent {
    @Input() title = 'General';
    rootCategory: MenuItem;

    constructor(menu: Menu) {
        menu.menu.subscribe((rootMenu) => this.rootCategory = rootMenu);
    }

    itemActivatedEvent(menuItem: MenuItem) {
        for (let item of this.rootCategory.children) {
            if (item !== menuItem)
            {
                item.submenuOpen = false;
                item.animationState = 'close';
                item.observableActiveClassItem.next(false);

            }
        }
    }
}
