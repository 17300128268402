import {RequestBase} from './request-base';
import {PermissionListData} from '../../../model/remote/responses/structures/data/permission-list-data';
import {EntityPermissionList} from '../../../model/entities/entity-permission-list';
import {RequestServiceHolder} from '../request-service-holder';

export class PermissionListRequest extends RequestBase<PermissionListData> {

    constructor(id, entity: EntityPermissionList, serviceHolder: RequestServiceHolder) {
        super(entity.getRestUrl({id: id}), 'GET', serviceHolder);
    }
}
