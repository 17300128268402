/**
 * Created by Zirialk on 15/01/2019.
 */
import {ValueDate} from './value-date';
import {DateRangePickerOptions} from '../date-range-picker-options';
export class ValueTime extends ValueDate {
    protected get baseOptions(): DateRangePickerOptions {
        return new DateRangePickerOptions('HH:mm', true, false, true).setRestParameterFormat('HH:mm:ss');
    }
}
