import {EntityBase} from './entity-base';
import {MenuOptions} from '../remote/responses/structures/data/config/entity/options/menu-options';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {MenuRequest} from '../../services/rest/requests/menu-request';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {EntitiesFinderService} from '../../services/entities/entities-finder.service';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {SecondaryMenuItemData} from '../remote/responses/structures/data/menu/secondary-menu-item-data';

export class EntityMenu extends EntityBase<MenuOptions> {

    constructor(data: EntityData<MenuOptions>, entitiesFinder: EntitiesFinderService, private requestService: RequestServiceHolder) {
        super(data, entitiesFinder);
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get restMethod(): string {
        return 'GET';
    }

    getItems(): Observable<SecondaryMenuItemData[]> {
        return new Observable<SecondaryMenuItemData[]>(observer => {
            new MenuRequest(this.getRestUrl(), this.restMethod, this.requestService).execute().subscribe(response => {
                observer.next(response.data.items);
            });
        });
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }
}