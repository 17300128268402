import {RestService} from './rest.service';
import {EntitiesFinderService} from '../entities/entities-finder.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../auth.service';
import {MatDialog} from '@angular/material';
import {LocationService} from '../location.service';

@Injectable()
export class RequestServiceHolder {
    restService: RestService;
    entitiesFinder: EntitiesFinderService;
    router: Router;
    translateService: TranslateService;
    authService: AuthService;
    dialog: MatDialog;
    locationService: LocationService;

    constructor(restService: RestService, router: Router, translate: TranslateService, authService: AuthService, dialog: MatDialog, locationService: LocationService) {
        this.restService = restService;
        this.router = router;
        this.translateService = translate;
        this.authService = authService;
        this.dialog = dialog;
        this.locationService = locationService;
    }
}
