import {Observable, Observer} from 'rxjs';
import {EntityChange} from './entity-change';
import {Item} from '../item';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {UpdateRequest} from '../../services/rest/requests/update-request';
import {ChangeOptions} from '../remote/responses/structures/data/config/entity/options/change-options';
import {BaseResponse} from '../remote/responses/base-response';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {EntitySingleResource} from './entity-single-resource';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Column} from '../columns/column';

export class EntityUpdate extends EntityChange<ChangeOptions> {
    private translate: TranslateService;

    constructor(data: EntityData<ChangeOptions>, serviceHolder: RequestServiceHolder, translateservice: TranslateService, activedrouter: ActivatedRoute) {
        super(data, serviceHolder, translateservice, activedrouter);
        this.translate = translateservice;
    }


    get linkedEntitiesNames(): Params {
        return;
    }

    save(item: Item, id = null, lockScreen = true): Observable<BaseResponse<any>> {
        const request = new UpdateRequest(this.serviceHolder, this, item, id);
        request.url.geolocation = this.geolocation;
        request.lockScreen = lockScreen;
        return request.execute();
    }

    get restMethod(): string {
        return 'PUT';
    }

    getItem(id): Observable<Item> {
        return new Observable<Item>((obs: Observer<Item>) => {
            this.columns.subscribe((columns: Column[]) => {
                const item = new Item();

                this.entitySingleResource.subscribe(entity => entity.getItem(id).subscribe(itemSingler => {
                    for (const column of columns) {
                        this.addURLParams(column);

                        item.addValue(column.createValue(this.translate, itemSingler.getValue(column)));


                    }
                    this.checkDynamicFieldsAndAddIt(item, itemSingler);
                }));


                obs.next(item);
                obs.complete();
            });
        });
    }

    private get entitySingleResource(): Observable<EntitySingleResource> {
        return new Observable<EntitySingleResource>(observer => {
            const singleResourceEntityName = this.data.options.singleResourceEntityName;

            if (singleResourceEntityName === undefined) {
                observer.next(new EntitySingleResource(this.data, this.serviceHolder, this.translate));
                return;
            }

            this.serviceHolder.entitiesFinder.findEntityByName(singleResourceEntityName)
                .subscribe(entity => observer.next(<EntitySingleResource>entity));
        });
    }

    get requiredEntitiesNames(): string[] {
        if (this.data.options.singleResourceEntityName) {
            return [this.data.options.singleResourceEntityName];
        }

        return [];
    }
}
