import {RestService} from '../services/rest/rest.service';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from '../components/app.component';
import {GeneralConfigService} from '../services/general-config.service';
import {RouterModule} from '@angular/router';
import {HomeModule} from './home.module';
import {AuthService} from '../services/auth.service';
import {LoginModule} from './login.module';
import {BackendModule} from './backend.module';
import {LoginComponent} from '../components/login.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CookiesService} from '../services/cookies.service';
import {ConfigRequest} from '../services/rest/requests/config-request';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {ColorHandler} from '../services/color-handler';
import {PushNotificationModule} from 'ng-push-notification/ng-push-notification';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {Menu} from '../services/menu';
import {RestPreconditionsService} from '../services/rest/preconditions/rest-preconditions-service';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ChartsModule} from 'ng2-charts';
import {DataTablesModule} from 'angular-datatables';
import {LocationService} from '../services/location.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const initialRoutes = [
    {
        path: '**',
        component: LoginComponent,
        pathMatch: 'full',
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],

    imports: [
        BrowserModule,
        RouterModule.forRoot(initialRoutes, {onSameUrlNavigation: 'reload'}),
        BackendModule,
        DataTablesModule,
        HomeModule,
        LoginModule,
        HttpClientModule,
        ChartsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        PushNotificationModule.forRoot()
    ],


    providers: [AuthService, RestService, CookiesService, ConfigRequest, GeneralConfigService, BodyAttributesSetter, LocationService,
        ColorHandler, RequestServiceHolder, Menu, RestPreconditionsService, TranslateService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
