
import {v4} from 'uuid';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {FileElement} from '../model/remote/responses/structures/data/file/file-element';

export interface IFileService {
    add(fileElement: FileElement);

    delete(id: string);

    update(id: string, update: Partial<FileElement>);

    queryInFolder(folderId: string): Observable<FileElement[]>;

    get(id: string): FileElement;
}


export class FileService implements IFileService {
    private map = new Map<string, FileElement>();

    constructor() {
    }

    add(fileElement: FileElement) {
        fileElement.id = v4();
        this.map.set(fileElement.id, this.clone(fileElement));
        return fileElement;
    }

    delete(id: string) {
        this.map.delete(id);
    }

    update(id: string, update: Partial<FileElement>) {
        let element = this.map.get(id);
        element = Object.assign(element, update);
        this.map.set(element.id, element);
    }

    private querySubject: BehaviorSubject<FileElement[]>;

    queryInFolder(folderId: string) {
        const result: FileElement[] = [];
        this.map.forEach(element => {
            if (element.parent === folderId) {
                result.push(this.clone(element));
            }
        });
        if (!this.querySubject) {
            this.querySubject = new BehaviorSubject(result);
        } else {
            this.querySubject.next(result);
        }
        return this.querySubject.asObservable();
    }

    get(id: string) {
        return this.map.get(id);
    }

    clone(element: FileElement) {
        return JSON.parse(JSON.stringify(element));
    }
}
