/* tslint:disable:max-line-ilength */
import {Injectable, ReflectiveInjector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export class DateRangePickerOptions {
    showDropdowns = true;
    opens = 'left';
    locale: {
        cancelLabel: string,
        format?: string,
        daysOfWeek: string[],
        monthNames: string[],
        firstDay: number,
        applyLabel: string,
    };
    singleDatePicker = false;
    autoUpdateInput = false;
    timePicker: boolean;
    timePicker24Hour = true;
    startDate: any;
    endDate: any;
    restParameterFormat: string;

    constructor(format: string, time: boolean, calendar: boolean, single: boolean, translateService: TranslateService = null) {
        this.singleDatePicker = typeof single === 'undefined' || single;
        this.timePicker = time;
        if (translateService == null) {
            this.locale = {
                format: format,
                applyLabel: 'Apply',
                cancelLabel: 'Clear',
                daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                firstDay: 0

            };
        } else {
            switch (translateService.currentLang) {
                case 'es': {
                    this.locale = {
                        format: format,
                        cancelLabel: 'Limpiar',
                        applyLabel: 'Aplicar',
                        daysOfWeek: ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA'],
                        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agusto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                        firstDay: 1

                    };
                    break;
                }
                default: {
                    this.locale = {
                        format: format,
                        applyLabel: 'Apply',
                        cancelLabel: 'Clear',
                        daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        firstDay: 0

                    };
                    break;

                }
            }
        }


        //            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        this.restParameterFormat = format;

        if (!calendar) {
            this.opens += ' no_calendar';
        }
    }

    setCancelLabel(value: string): DateRangePickerOptions {
        this.locale.cancelLabel = value;

        return this;
    }

    setRestParameterFormat(value: string): DateRangePickerOptions {
        this.restParameterFormat = value;

        return this;
    }
}
