import {RestResponse} from './rest-response';
import {ListData} from './structures/data/list/list-data';
import {Item} from '../../item';
import {Column} from '../../columns/column';
import {Value} from '../../values/value';
import {ResponseStructure} from './structures/response-structure';
import {RowData} from './structures/data/list/row-data';
import {EntityResource} from '../../entities/entity-resource';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';

export class ListResponse extends RestResponse<ListData> {
    readonly rows: Item[] = [];

    constructor(response: ResponseStructure<ListData>, private entity: EntityResource,  serviceHolder: RequestServiceHolder) {
        super(response, serviceHolder);
        this.rows = this.getRows(this.data.rows);
    }

    getRows(rows: RowData[]): Item[] {
        const result: Item[] = [];

        for (const row of rows) {
            const item = new Item();
            this.entity.columns.subscribe((columns: Column[]) => {
                for (const column of columns) {
                    item.addValue(new Value(column, row[column.name]));
                }
            });

            result.push(item);
        }

        return result;
    }
}
