import {SecondaryMenuItemData} from '../remote/responses/structures/data/menu/secondary-menu-item-data';

export class SecondaryMenuItem {
    readonly key: number | string;
    readonly value: string;
    readonly children: SecondaryMenuItem[] = [];
    readonly submenu: boolean = false;
    checkboxEnabled = false;
    checked = false;

    constructor(itemData: SecondaryMenuItemData, checkboxEnabled: boolean) {
        this.key = itemData.key;
        this.value = itemData.value;
        this.checkboxEnabled = checkboxEnabled;

        if (Array.isArray(itemData.children)) {
            this.submenu = true;

            itemData.children.map(item => this.children.push(new SecondaryMenuItem(item, this.checkboxEnabled)));
        }
    }

    findItem(key: number | string): SecondaryMenuItem {
        if (this.key === key && !this.submenu) {
            return this;
        }

        let itemFound: SecondaryMenuItem;

        this.children.map(item => {
            if (!itemFound) {
                itemFound = item.findItem(key);
            }
        });

        return itemFound;
    }

    get firstItem(): SecondaryMenuItem {
        if (this.submenu) {
            return this.children[0].firstItem;
        }

        return this;
    }


}