import {Column} from '../columns/column';
import {ColumnType} from '../columns/column-type';

declare type OnValueChanged = () => void;

export class Value {
    private _column: Column;
    private _value: any;
    private originalValue: any;
    private _visualValue: string;
    private _parameterValue;
    private onValueChangedMethods: OnValueChanged[] = [];

    constructor(column: Column, value: any) {
        this.column = column;
        this.visualValue = column.values ? column.getValueById(value) : value;
        this.value = value;
        this.originalValue = value;
    }

    get column(): Column {
        return this._column;
    }

    set column(value: Column) {
        this._column = value;
    }

    addOnValueChanged(method: OnValueChanged) {
        this.onValueChangedMethods.push(method);
    }

    get value(): any {
        return this._value;
    }

    set value(value: any) {
        if (this._value === value) {
            return;
        }

        this._value = value;

        for (const method of this.onValueChangedMethods) {
            method();
        }
    }

    get visualValue(): string {
        return this._visualValue;
    }

    set visualValue(value: string) {
        this._visualValue = value;
    }

    public reset() {
        this.value = this.originalValue;

        if (this.column.type === ColumnType.checkbox && this.originalValue === '') {
            this.value = false;
        }
    }

    get parameterValue() {
        return typeof this._parameterValue === 'undefined' ? this.value : this._parameterValue;
    }

    set parameterValue(value) {
        this._parameterValue = value;
    }
}
