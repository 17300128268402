import {ItemsActionBase} from './items-action-base';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {Params, Router} from '@angular/router';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {Entity} from '../entities/entity';

export class IndividualAction extends ItemsActionBase {
    constructor(actionData: ActionData, router: Router, serviceHolder: RequestServiceHolder, entity: Entity,
                component: any, id: number, urlReplacements: Params) {
        super(actionData, router, serviceHolder, entity, component, {...urlReplacements, '#': id});
    }

    protected getOrphanKeyCorrespondence(currentParams: Params): undefined | string {
        const id = this.getId(currentParams);

        let paramName;

        if (id !== undefined) {
            paramName = this.getNonExistentParameterName(currentParams);
            currentParams[paramName] = id;
        }

        return paramName;
    }

    /**
     * @description We need a non existent parameter name to prevent replacing another value that could be needed.
     *
     * @param {Params} currentParams
     * @returns {string}
     */
    private getNonExistentParameterName(currentParams: Params) {
        let number;

        do {
            number = Math.floor((Math.random() * 10) + 1);
        } while (currentParams.hasOwnProperty(number));

        return 'orphan' + number;
    }

    private getId(currentParams: Params) {
        const id = currentParams['#'];
        delete currentParams['#'];

        return id;
    }
}
