import {Column} from './columns/column';
import {Value} from './values/value';
import {AutoCompleteLinkedColumn} from './remote/responses/structures/data/config/entity/options/autocomplete-linked-column';
import {Params} from '@angular/router';
import {ComposedListValue} from './remote/responses/structures/data/list/composed-list-value';

export class Item {
    private _columns: Column[] = [];
    private _values: { [columnName: string]: Value } = {};
    private lastColumnOrder = 1000;
    private _pendingLinkedValues: { [columnName: string]: string } = {};

    addValue(value: Value) {
        const column: Column = value.column;

        if (!(column.name in this._values)) {
            this.addColumn(column);
        }

        this._values[column.name] = value;

        this.setPendingLinkedColumnsEvents(value);
        this.setLinkedColumnsEvents(value, this.getValueModel(column.data.linkedToColumn));
    }

    private addColumn(column: Column) {
        let order = column.data.order;

        if (order === undefined) {
            order = this.lastColumnOrder++;
        }

        this._columns[order] = column;
    }

    private setPendingLinkedColumnsEvents(value: Value) {
        const column: Column = value.column;
        const childColumnName = this._pendingLinkedValues[column.name];

        if (!childColumnName) {
            return;
        }

        this.setLinkedColumnsEvents(this.getValueModel(childColumnName), value);

        delete this._pendingLinkedValues[column.name];
    }

    private setLinkedColumnsEvents(value: Value, parentValue: Value) {
        const column: Column = value.column;

        if (!column.data.linkedToColumn) {
            return;
        }

        if (!parentValue) {
            this._pendingLinkedValues[column.data.linkedToColumn.local] = column.name;
        } else {
            parentValue.addOnValueChanged(() => Item.onForeignValueChanged(value));
        }
    }

    private static onForeignValueChanged(value: Value) {
        value.value = '';
        value.visualValue = '';
    }

    get columns(): Column[] {
        if (this._columns.length > Object.keys(this._values).length) {
            const columns = [];
            this._columns.map(column => {
                if (column !== undefined) {
                    columns.push(column);
                }
            });
            this._columns = columns;
        }

        return this._columns;
    }

    get values(): Value[] {
        return this.columns.map(column => this.getValueModel(column));
    }

    public getValueModel(column: AutoCompleteLinkedColumn | Column | string): Value;
    public getValueModel(column: any): Value {
        if (column instanceof Column) {
            column = column.name;
        } else if (typeof column === 'object' && 'local' in column) {
            column = column.local;
        }

        if (!(column in this._values)) {
            return null;
        }

        return this._values[column];
    }

    getValues(columns: string[]): Params {
        const values: Params = {};

        for (const columnName of columns) {
            values[columnName] = this.getValue(columnName);
        }

        return values;
    }

    public getValue(column: Column | string): any {
        const value = this.getValueModel(column);

        return value === null ? '' : value.value;
    }

    public getVisualValue(column: Column | string): any | ComposedListValue {
        const value = this.getValueModel(column);

        return value === null ? '' : value.visualValue;
    }

    public getPrimary() {
        for (const column of this.columns) {
            if (column.primary) {
                return this.getValue(column);
            }
        }

        return null;
    }

    public getNameOrNull(crumbFields?: string[]): string {
        let name = '';
        if (crumbFields !== undefined && crumbFields !== null && crumbFields.length !== 0) {
            for (const column of crumbFields) {
                const value = this.getValue(this.columns.filter(c => c.name === column)[0]);
                if (value !== null) {
                    name += (' ' + value);
                }
            }
        }
        return name;
    }

    public resetValues() {
        for (const value of this.values) {
            value.reset();
        }
    }

    public equals(item: Item): boolean {
        return this.getPrimary() == item.getPrimary();
    }
}
