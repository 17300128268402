import {NgModule} from '@angular/core';
import {LoginComponent} from '../components/login.component';
import {HomeModule} from './home.module';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './shared.module';

/**
 * Created by angro on 10/05/2017.
 */

@NgModule({
    declarations: [LoginComponent],
    imports: [HomeModule, FormsModule, SharedModule]
})
export class LoginModule {

}
