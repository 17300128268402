import {Component, Input, OnInit} from '@angular/core';
import {EntityDetail} from '../../model/entities/entity-detail';
import {DetailEditableValue} from '../../model/remote/responses/structures/data/detail/detail-editable-value';
import {DetailImageValue} from '../../model/remote/responses/structures/data/detail/detail-image-value';

@Component({
    selector: 'ol-detail-cell',
    templateUrl: './detail-cell.component.html'
})
export class DetailCellComponent implements OnInit {
    @Input() entity: EntityDetail;
    @Input() cell: DetailEditableValue | DetailImageValue | any;
    @Input() editEntityName: string;
    private oldValue: any = undefined;
    editable: boolean;
    image: boolean;

    ngOnInit(): void {
        if (!this.entity || this.cell === undefined) {
            throw new Error('Entity and cell are required');
        }

        this.editable = this.entity.isEditable(this.cell);
        this.image = this.cell !== null && typeof this.cell === 'object' && 'image' in this.cell;

        if (this.editable && !this.editEntityName) {
            throw new Error('entityNme is required for editable cells');
        }
    }

    update() {
        this.entity.updateField(this.cell, this.editEntityName).subscribe(done => {
            if (!done) {
                this.cell.value = this.oldValue;
            }

            this.oldValue = undefined;
        });
    }

    onKeyPress(event) {
        if (event.charCode === 13) {
            this.update();
        }
    }

    edit() {
        this.oldValue = true;
    }

    get editting(): boolean {
        return this.oldValue !== undefined;
    }

}