export class ColumnType {
    static types: { [name: string]: ColumnType } = {};

    static text: ColumnType = new ColumnType('text', '');
    static select: ColumnType = new ColumnType('select', '');
    static richtexteditor: ColumnType = new ColumnType('richtexteditor', '');
    static textarea: ColumnType = new ColumnType('textarea', '');
    static checkbox: ColumnType = new ColumnType('checkbox', false);
    static hidden: ColumnType = new ColumnType('hidden', '');
    static radio: ColumnType = new ColumnType('radio', '');
    static image: ColumnType = new ColumnType('image', '');
    static password: ColumnType = new ColumnType('password', '');
    static autocomplete: ColumnType = new ColumnType('autocomplete', '');
    static listquery: ColumnType = new ColumnType('listquery', '');
    static date: ColumnType = new ColumnType('date', {});
    static datenorange: ColumnType = new ColumnType('datenorange', {});
    static datetime: ColumnType = new ColumnType('datetime', {});
    static time: ColumnType = new ColumnType('time', {});
    static timenorange: ColumnType = new ColumnType('timenorange', {});
    static progress: ColumnType = new ColumnType('progress', {});
    static html: ColumnType = new ColumnType('html', '');
    static file: ColumnType = new ColumnType('file', '');
    static color: ColumnType = new ColumnType('color', '');
    static canvas: ColumnType = new ColumnType('canvas', '');

    name: string;
    private _defaultValue: any;

    get defaultValue(): any {
        if (typeof this._defaultValue === 'object') {
            return {...this._defaultValue};
        }

        return this._defaultValue;
    }

    constructor(name: string, defaultValue: any) {
        this.name = name;
        this._defaultValue = defaultValue;

        ColumnType.types[name] = this;
    }

    equals(value: any): boolean {
        if (!(value instanceof ColumnType)) {
            return false;
        }

        return this.name == value.name;
    }
}
