import {EntityBase} from './entity-base';
import {BaseOptions} from '../remote/responses/structures/data/config/entity/options/base-options';
import {Params} from '@angular/router';
import {EntityData} from '../remote/responses/structures/data/config/entity/entity-data';
import {BackendOptions} from '../remote/responses/structures/data/config/entity/options/backend-options';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {TranslateService} from '@ngx-translate/core';
import {ChangeOptions} from '../remote/responses/structures/data/config/entity/options/change-options';
import {EntityChange} from './entity-change';
import {Observable, Observer} from 'rxjs';
import {Item} from '../item';
import {BaseResponse} from '../remote/responses/base-response';
import {AddRequest} from '../../services/rest/requests/add-request';
import {EntityAdd} from './entity-add';
import {Column} from '../columns/column';
import {ImagePositionOptions} from '../remote/responses/structures/data/config/entity/options/image-position-options';

export class EntityImagePositionSelector extends EntityBase<ImagePositionOptions> {

    constructor(data: EntityData<ImagePositionOptions>, serviceHolder: RequestServiceHolder) {
        super(data, serviceHolder.entitiesFinder);

    }


    get restMethod(): string {
        return 'GET';
    }

    get linkedEntitiesNames(): Params {
        return {};
    }

    get requiredEntitiesNames(): string[] {
        return [];
    }


}



