import {Injectable} from '@angular/core';

@Injectable()
export class CookiesService {
    setCookie(name: string, value: string, minutes?: number) {
        if (!minutes) {
            minutes = 365 * 24 * 60;
        } // 1 year in minutes

        let expires;

        let date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = '; expires=' + date.toUTCString();

        document.cookie = name + '=' + value + expires + '; path=/';
    }

    getCookie(cname) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return '';
    }

    getAllCookies(): string[] {
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookiesDecoded = decodedCookie.split(';');
        const cookies = [];

        for (const co of cookiesDecoded) {
            const cot = co.split('=');

            if (cot[0].trim() !== 'session') {
                cookies[cot[0].trim()] = cot[1];
            }
        }
        return cookies;

    }
}
