import {Injectable} from '@angular/core';
import {Entity} from '../../model/entities/entity';
import {Route, Routes} from '@angular/router';
import {EntitiesHandlerService} from './entities-handler.service';
import {HomeComponent} from '../../components/home/home.component';
import {AuthService} from '../auth.service';
import {NotificationsComponent} from '../../components/home/notifications.component';
import {Menu} from '../menu';
import {GeneralConfigService} from '../general-config.service';
import {ConfigStyle} from '../../model/remote/responses/structures/data/config/config-style';
import {MenuItem} from '../../model/menu-item';
import {MenuSelectorComponent} from '../../components/home/menu-selector.component';
import {MenuEntitySelectorComponent} from '../../components/menu-entity-selector.component';
import {EntityType} from '../../model/entities/entity-type';

@Injectable()
export class RoutesEntitiesCreator {
    private _routesCache: Routes = [];

    constructor(private entitiesHandler: EntitiesHandlerService, private menu: Menu, private generalConfig: GeneralConfigService) {
    }

    get routes(): Promise<Routes> {
        return new Promise((resolve, reject) => {
            if (this._routesCache.length > 0) {
                resolve(this._routesCache);
                return;
            }
            if (this.generalConfig.style === ConfigStyle.ADVANCED) {
                this.menu.menu.subscribe(m => this.menuAdvancedRouters(m));
            }
            this.entitiesHandler.entities.subscribe(entity => this.addRouteFromEntity(entity),
                (error) => {
                    console.log(error);
                    reject();
                },
                () => {
                    this.addNotificationPath();
                    this.addDefaultPath();

                    resolve(this._routesCache);
                }
            );
        });

    }

    private menuAdvancedRouters(m: MenuItem) {
        this.createRoute(this.getMenuRoute(m, true));
        this.findRouters(m, false, true);
    }

    private findRouters(m: MenuItem, base: boolean, parent: boolean) {
        for (const menu of m.children) {
            if (menu.children.length > 0) {
                this.createRoute(this.getMenuRoute(menu, base, parent));
                this.findRouters(menu, base, parent);
            }
        }
    }

    private getMenuRoute(menu: MenuItem, base: boolean = false, parent: boolean = false): Route {
        const component = this.entitiesHandler.getComponentByName('menu');
        if (!component) {
            return undefined;
        }
        return {
            path: menu.url ? menu.url : 'menu',
            component: base ? MenuSelectorComponent : HomeComponent,
            canActivate: [AuthService],
            pathMatch: 'full',
            children: [{
                path: '',
                component: component,
                pathMatch: 'full',
                data: {
                    'menu': menu.children,
                    'back': parent
                }
            }]
        };


    }

    private addDefaultPath(): void {

        this.menu.menu.subscribe(rootItem => {
            const defaultItem = rootItem.defaultItem;

            let defaultPath;

            if (defaultItem !== undefined) {
                defaultPath = defaultItem.url + '?' + defaultItem.paramsString;
            }

            if (defaultPath === undefined) {
                defaultPath = '/' + this._routesCache[0].path + '/' + this._routesCache[0].children[0].path;

                if (defaultPath.endsWith('/')) {
                    defaultPath = defaultPath.substring(0, defaultPath.length - 1);
                }
            }
            if (this.generalConfig.style === ConfigStyle.ADVANCED) {
                defaultPath = 'menu';
            }
            this._routesCache.push({
                path: '**',
                redirectTo: defaultPath,
                pathMatch: 'full'
            });
        });
    }

    private addNotificationPath(): void {
        this._routesCache.push({
            path: 'notifications',
            component: NotificationsComponent,
            canActivate: [AuthService],
            pathMatch: 'full'
        });
    }

    private addRouteFromEntity(entity: Entity) {
        if (entity.data.type === EntityType.MENU_SELECTOR) {
            this.menu.menu.subscribe(m => {
                // REFACTORIZAR Y HACER UN MEJOR CODIGO
                this.createRoute(this.getMenuRoute(m, true));
                this.findRouters(m, true, true);
            });
        } else {
            const route = this.getEntityRoute(entity);
            this.createRoute(route);
        }
    }

    private createRoute(route: Route) {
        if (!route) {
            return;
        }

        let found = false;
        for (const routeBase of this._routesCache) {
            if (route.path === routeBase.path) {
                routeBase.children = routeBase.children.concat(route.children);
                found = true;
            }
        }

        if (!found) {
            this._routesCache.push(route);
        }
    }

    private getEntityRoute(entity: Entity): Route {

        const component = this.entitiesHandler.getComponent(entity);
        if (!component) {
            return undefined;
        }
        let baseComponent: any = HomeComponent;
        if (component instanceof MenuEntitySelectorComponent) {
            baseComponent = MenuSelectorComponent;
        }
        return {
            path: entity.routePath,
            component: baseComponent,
            canActivate: [AuthService],
            pathMatch: 'full',
            children: [{
                path: '',
                component: component,
                pathMatch: 'full',
                data: {
                    'entity': entity,
                },
            }]
        };
    }
}
