import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Action} from '../model/actions/action';

declare var jQuery;

@Component({
    selector: 'action-button',
    templateUrl: './action-button.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActionButtonComponent implements OnInit {
    @Input() action: Action;
    @Input() entity_name: string;
    @Input() ids: any[] = [];
    @Input() names: any[] = [];
    @Input() isDialog = false;
    classes: { [className: string]: boolean } = {btn: true};

    ngOnInit(): void {
        if (this.action.style) {
            this.classes['btn-' + this.action.style] = true;
        }
    }

    doAction() {
        this.action.ids = this.ids;
        this.action.setFiltersParams(this.entity_name);
        this.action.doAction();

    }

}
