import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Value} from '../../../model/values/value';
import {FormGroup} from '@angular/forms';
import {Entity} from '../../../model/entities/entity';
import {Item} from '../../../model/item';
import {ColumnType} from '../../../model/columns/column-type';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'ol-input-selector',
    templateUrl: './input-selector.component.html'
})
export class InputSelectorComponent implements OnInit {
    @Input() item: Item;
    @Input() entity: Entity;
    @Input() form: FormGroup;
    @Input() formSecondaryColumsDependency: [FormGroup[], string[][]];
    @Input() value: Value;
    @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() escapePressed: EventEmitter<void> = new EventEmitter<void>();
    visualName: SafeHtml;


    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.checkParameters();

        this.visualName = this.sanitizer.bypassSecurityTrustHtml(this.value.column.visualName);

        this.dependencyColumnsValidator();
    }

    protected dependencyColumnsValidator() {
        if (this.formSecondaryColumsDependency !== undefined && this.formSecondaryColumsDependency[0].length === 0) {
            for (const column in this.item.columns) {
                if (this.item.columns[column].data.dependencyColumns !== undefined && this.item.columns[column].data.dependencyColumns !== null
                    && this.item.columns[column].data.dependencyColumns.length > 0 && this.formSecondaryColumsDependency[0][this.item.columns[column].formControlName] === undefined) {

                    this.formSecondaryColumsDependency[0][this.item.columns[column].formControlName] = new FormGroup({});
                    this.formSecondaryColumsDependency[1][this.item.columns[column].formControlName] = this.item.columns[column].data.dependencyColumns;
                }
            }
        }

    }

    private checkParameters() {
        if (this.form === undefined || this.value === undefined) {
            throw new Error('item and value are required parameters');
        }

        if (this.value.column.type.equals(ColumnType.autocomplete)) {
            if (this.entity === undefined || this.item === undefined) {
                throw new Error('For autocomplete, item and entity are required');
            }
        }
    }

    onEnterPressed() {
        this.enterPressed.emit();
    }

    onEscapePressed() {
        this.escapePressed.emit();
    }

    getSecundaryForm(): [FormGroup[], string[][]] {
        return this.formSecondaryColumsDependency !== undefined ? this.formSecondaryColumsDependency : null;
    }
}
