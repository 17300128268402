export class Permission {
    readonly name: string;
    readonly title: string;
    enabled: boolean;
    /**
     * @description Sets if this permission is linked in some entity as a button or a menu item.
     * @type {boolean}
     */
    independent = false;

    /**
     * @description Permissions tha requires this one in order to work.
     * @type {Permission[]}
     */
    readonly requiredBy: Permission[] = [];

    constructor(name: string, title: string, enabled: boolean) {
        this.name = name;
        this.title = title;
        this.enabled = enabled;
    }
}
