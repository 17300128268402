import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface InfoData {
    info: String;
    title: String;
}

@Component({
    templateUrl: 'dialog-info.component.html'
})
export class DialogInfoComponent {

    constructor(
        public dialog: MatDialogRef<DialogInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoData) {
    }
}
