import {RequestBase} from './request-base';
import {Injectable} from '@angular/core';
import {ConfigData} from '../../../model/remote/responses/structures/data/config/config-data';
import {Url} from '../../../model/url';
import {RequestServiceHolder} from '../request-service-holder';
import {BaseResponse} from '../../../model/remote/responses/base-response';
import {Observable} from 'rxjs';

@Injectable()
export class ConfigRequest extends RequestBase<ConfigData> {

    constructor(serviceHolder: RequestServiceHolder) {
        super(new Url('config', true), 'GET', serviceHolder);

        this.afterPreconditions = false;

    }

}
