import {Action} from './action';
import {ActionData} from '../remote/responses/structures/data/config/entity/options/action-data';
import {Params, Router} from '@angular/router';
import {RequestBase} from '../../services/rest/requests/request-base';
import {RestRequest} from '../../services/rest/requests/rest-request';
import {MessageConfirm} from '../../services/messages/message-confirm';
import {Url} from '../url';
import {Entity} from '../entities/entity';
import {RequestServiceHolder} from '../../services/rest/request-service-holder';
import {BaseResponse} from '../remote/responses/base-response';
import {FilterList} from '../filters/filter-list';

export abstract class BaseAction implements Action {
    private styles = ['default', 'primary', 'success', 'info', 'warning', 'danger', 'link'];

    readonly url: Url;
    private method: string;
    private _ids: any[];
    private _names: string[];
    private _style: string;
    readonly data: ActionData;
    actionMethod: () => any;
    onOk: (response: BaseResponse<any>) => any;
    params: { [name: string]: any } = {};
    protected orphanUrlParameterFound = false;
    private response: BaseResponse<any>;
    private filterlist: FilterList;

    constructor(actionData: ActionData, private router: Router, protected serviceHolder: RequestServiceHolder,
                entity: Entity, private component: any, currentUrlReplacements: Params) {

        if (!BaseAction.isActionData(actionData)) {
            throw new Error('Incorrect ActionData');
        }

        if (actionData.urlParamsCorrespondences === undefined) {
            actionData.urlParamsCorrespondences = {};
        }

        this.data = actionData;
        this.url = entity.getActionUrl();
        this.setUrlParams(currentUrlReplacements);
        this.method = entity.restMethod;
        this.actionMethod = this.url.rest ? this.doAjax : this.doRedirect;
        this.onOk = entity.getMethodOnActionOk(component).bind(component);
        this.style = actionData.style;
    }

    protected setUrlParams(currentParams: Params) {
        this.url.urlParamsNames.map(param => {
            if (RequestBase.restPrefixUrl.match('\{' + param + '\}')) {
                return;
            }

            let correspondenceKey = this.data.urlParamsCorrespondences[param];

            if (correspondenceKey === undefined) {
                correspondenceKey = this.getOrphanKeyCorrespondence(currentParams);

                if (correspondenceKey === undefined) {
                    throw new Error('Not found correspondence for url parameter ' + param + ' in url ' + this.url.path);
                }
            }

            const value = currentParams[correspondenceKey];

            if (value === undefined) {
                throw new Error('Value not found for url parameter ' + correspondenceKey + ' in url ' + this.url.path);
            }
            this.url.addUrlParam(param, value);
        });
    }

    protected getOrphanKeyCorrespondence(currentParams: Params) {
        if (this.orphanUrlParameterFound) {
            return undefined;
        }

        this.orphanUrlParameterFound = true;

        const keys = Object.keys(currentParams);

        if (keys.length === 1) {
            return keys[0];
        }

        if (keys.indexOf('id') !== -1) {
            return 'id';
        }

        return undefined;
    }

    public static isActionData(object) {
        return object.hasOwnProperty('entity_name') && object.hasOwnProperty('visualName') && object.hasOwnProperty('onlyIcon');
    }

    get style(): string {
        return this._style;
    }

    set style(value: string) {
        if (this.styles.indexOf(value) > -1) {
            this._style = value;
        } else {
            this._style = '';
        }
    }

    get visualName(): string {
        return this.data.visualName;
    }

    get icon(): string {
        return this.data.icon;
    }

    get askMessage(): string {
        return this.data.askMessage;
    }

    get onlyIcon(): boolean {
        return this.data.onlyIcon;
    }

    get ids(): any[] {
        return this._ids;
    }


    set names(value: string[]) {
        this.params['entity_pagename'] = value.toString();
    }

    set ids(value: any[]) {
        this._ids = value;
    }

    doAction(): void {
        if (!this.checkConditions()) {
            return;
        }

        if (this.askMessage) {
            new MessageConfirm(this.askMessage, this.serviceHolder.translateService.instant('Confirmation'), () => this.actionMethod(),
                this.serviceHolder.translateService.instant('Ok'), this.serviceHolder.translateService.instant('Cancel')).show();
        } else {
            this.actionMethod();
        }
    }

    protected checkConditions(): boolean {
        return true;
    }

    doRedirect() {
        const url = this.getFinalUrl(true);
        url.queryParams = {...url.queryParams, ...this.data.params, ...this.params};
        this.router.navigateByUrl(url.path);
    }

    doAjax() {
        const request = new RequestBase(this.getFinalUrl(true), this.method, this.serviceHolder);
        request.addRequestValues(this.data.params);
        request.addRequestValues(this.params);
        request.type = this.method;
        ;

        this.customizeAjaxRequest(request);

        request.execute().subscribe((response) => {
            if (response.isSuccess()) {
                this.onOk(response);
            }
        });
    }


    protected customizeAjaxRequest(request: RestRequest<any>) {

    }


    protected getFinalUrl(redirect: boolean): Url {
        return this.url;
    }

    setFilters(filerList: FilterList): void {
        this.filterlist = filerList;
    }

    setFiltersParams(entity_name?: string): void {
        if (this.filterlist !== undefined) {
            sessionStorage.setItem(entity_name, JSON.stringify(this.filterlist.filtersForSave()));
            for (const filter of this.filterlist.all) {
                if (filter.value !== '') {
                    this.params[filter.paramName] = filter.value;
                }

            }
        }
    }


}
