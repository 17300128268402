import {Injectable} from '@angular/core';

@Injectable()
export class ColorHandler {
    private darkerTextColor = '#73879c';
    private lighterTextColor = '#FFFFFF';

    normalize(color: string): string {
        if (color.startsWith('#')) {
            color = color.substr(1);
        }

        if (color.length === 3) {
            color = color.substr(0, 1) + color.substr(0, 1) + color.substr(1, 1) + color.substr(1, 1) +
                color.substr(2, 1) + color.substr(2, 1);
        }

        return '#' + color.toLocaleUpperCase();
    }

    getTextColor(backgroundColor: string): string {
        const luminance = this.getLuminance(backgroundColor);
        const luminanceDarkerColor = this.getLuminance(this.darkerTextColor);
        const luminanceLighterColor = this.getLuminance(this.lighterTextColor);

        if ((luminance + 0.05) / (luminanceDarkerColor + 0.05) > (luminanceLighterColor + 0.05) / (luminance + 0.05)) {
            return this.normalize(this.darkerTextColor);
        } else {
            return this.normalize(this.lighterTextColor);
        }
    }

    private getLuminance(color: string) {
        const sRGB = this.getSRGB(color);

        for (const colorName in sRGB) {
            if (sRGB[colorName] <= 0.03928) {
                sRGB[colorName] = sRGB[colorName] / 12.92;
            } else {
                sRGB[colorName] = Math.pow(( sRGB[colorName] + 0.055 ) / 1.055, 2.4);
            }
        }

        return 0.2126 * sRGB.red + 0.7152 * sRGB.green + 0.0722 * sRGB.blue;
    }

    private getSRGB(color: string): {red: number, green: number, blue: number} {
        color = this.normalize(color);

        return {
            red: parseInt(color.substr(1, 2), 16) / 255,
            green: parseInt(color.substr(3, 2), 16) / 255,
            blue: parseInt(color.substr(4, 2), 16) / 255
        };
    }
}
