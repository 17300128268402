import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CookiesService} from './cookies.service';

/**
 * Created by angro on 10/05/2017.
 */

@Injectable()
export class AuthService implements CanActivate, CanActivateChild {

    private _loggedIn = null;
    private _loggedInObserbable: Observable<boolean>;
    private _sessionToken: string;
    expiration: number;
    lang = 'en';

    constructor(private cookiesService: CookiesService) {
        this._sessionToken = cookiesService.getCookie('session');
        this.lang = cookiesService.getCookie('lang');
        this._loggedInObserbable = new Observable<boolean>(observer => {
            if (this.loggedIn !== null) {
                observer.next(this.loggedIn);
            }
        });
    }

    checkAuth() {
        this.loggedIn = !!this.sessionToken;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.loggedIn;
    }


    get loggedInObserbable(): Observable<boolean> {
        return this._loggedInObserbable;
    }

    get loggedIn(): boolean {
        return this._loggedIn;
    }

    set loggedIn(value: boolean) {
        this._loggedIn = value;
    }

    get sessionToken(): string {
        return this._sessionToken;
    }

    set sessionToken(value: string) {
        this._sessionToken = value;
        this.cookiesService.setCookie('session', this.sessionToken, this.expiration);
        this.loggedIn = true;
    }

    get cookies(): string[] {
        return this.cookiesService.getAllCookies();
    }

    logout() {
        this._sessionToken = '';
        this.cookiesService.setCookie('session', '', -1);
        this.loggedIn = false;
    }
}
