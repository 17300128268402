import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GeneralConfigService} from '../../../services/general-config.service';
import {CookiesService} from '../../../services/cookies.service';
import {EntitiesHandlerService} from '../../../services/entities/entities-handler.service';
import {EntityMenu} from '../../../model/entities/entity-menu';
import {TopSecondaryMenuData} from '../../../model/remote/responses/structures/data/config/menus/top-secondary-menu-data';
import {RestService} from '../../../services/rest/rest.service';
import {SecondaryMenuItem} from '../../../model/secondary-menu/secondary-menu-item';
import {SecondaryMenuItemData} from '../../../model/remote/responses/structures/data/menu/secondary-menu-item-data';

@Component({
    selector: '.top-secondary-menu',
    templateUrl: './top-secondary-menu.component.html'
})
export class TopSecondaryMenuComponent implements OnInit {
    selectedKey: number | string;
    selected: string;
    cookieName: string;
    items: SecondaryMenuItem[] = [];
    @Input() menuEntity: string;
    private urlParamName: string;
    @Output() loaded: EventEmitter<void> = new EventEmitter<void>();
    private checkboxParameterName: string;
    private checkboxSelected: string[] = [];

    constructor(private configService: GeneralConfigService, private cookiesService: CookiesService,
                private entitiesFinder: EntitiesHandlerService, private restService: RestService) {

    }

    ngOnInit(): void {
        if (this.menuEntity === undefined) {
            this.configService.config.subscribe(config => this.setOldItems(config.topSecondaryMenu));
        }
        else {
            this.entitiesFinder.findEntityByName(this.menuEntity).subscribe((entity: EntityMenu) => {
                this.cookieName = 'menu_' + entity.name;
                this.urlParamName = entity.data.options.targetUrlParameter;
                this.checkboxParameterName = entity.data.options.targetCheckboxParameter;
                entity.getItems().subscribe(items => this.setItems(items));
            });
        }
    }

    private getSelectedKeyFromCookie() {
        if (this.selectedKey !== undefined) {
            return this.selectedKey;
        }

        let key: number | string = this.cookiesService.getCookie(this.cookieName);

        if (!key) {
            if (this.items.length > 0) {
                let item: SecondaryMenuItem = this.items[0];

                while (item.submenu) {
                    item = item.children[0];
                }

                key = item.key;
            }
        }

        return key;
    }

    private setOldItems(menu: TopSecondaryMenuData) {
        this.cookieName = menu.cookieName;
        this.selectedKey = menu.selected;
        const items: SecondaryMenuItemData[] = [];

        if (menu.items === undefined) {
            return;
        }

        Object.keys(menu.items).map(key => items.push({key: key, value: menu.items[key]}));

        this.setItems(items);
    }

    private setItems(menuItems: SecondaryMenuItemData[]) {
        const cookieKey = this.getSelectedKeyFromCookie();
        let selected: SecondaryMenuItem;

        menuItems.map(itemData => {
            const item = new SecondaryMenuItem(itemData, !!this.checkboxParameterName);
            this.items.push(item);

            if (!selected) {
                selected = item.findItem(cookieKey);
            }
        });

        if (!selected) {
            selected = this.items[0].firstItem;
        }

        this.onItemSelected(selected);

        this.loaded.emit();
    }

    onItemSelected(item: SecondaryMenuItem) {
        this.selectedKey = item.key;
        const value = item.value;

        if (typeof value !== 'string') {
            return;
        }

        this.selected = value;

        this.restService.addGlobalUrlParams(this.urlParamName, <string>this.selectedKey);

        if (!this.cookieName) {
            throw new Error('cookieName not set');
        }

        this.cookiesService.setCookie(this.cookieName, <string>item.key);
    }

    onItemChecked(item: SecondaryMenuItem) {

        if (item.checked) {
            this.checkboxSelected.push(<string>item.key);
        }
        else {
            const index = this.checkboxSelected.indexOf(<string>item.key);

            if (index > -1) {
                this.checkboxSelected.splice(index, 1);
            }
        }

        this.restService.addGlobalRequestParam(this.checkboxParameterName, this.checkboxSelected.join(','));
    }
}
