import {Component, Input, OnInit} from '@angular/core';
import {InputBase} from './input-base';
import {EntityChange} from '../../../model/entities/entity-change';
import {ChangeOptions} from '../../../model/remote/responses/structures/data/config/entity/options/change-options';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RequestServiceHolder} from '../../../services/rest/request-service-holder';
import {FileItem, FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {RestService} from '../../../services/rest/rest.service';
import {AuthService} from '../../../services/auth.service';
import {Location} from '@angular/common';

@Component({
    selector: 'ol-input-upload',
    templateUrl: './input-upload.component.html'

})
export class InputUploadComponent extends InputBase implements OnInit {
    @Input() entity: EntityChange<ChangeOptions>;
    enabled = false;
    placeHolder;
    noFoundText;
    private translate: TranslateService;
    private router: Router;

    constructor(private serviceHolder: RequestServiceHolder, translate: TranslateService, router: Router, private activatedRoute: ActivatedRoute, protected location: Location,
                private restService: RestService, private authService: AuthService) {
        super();
        this.translate = translate;
        this.router = router;
        this.translate.get('app.component.loading').subscribe((value => this.placeHolder = value));
        this.translate.get('noFoundText').subscribe((value => this.noFoundText = value));
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.control.disable();
        this.value.visualValue = '';
    }

}
