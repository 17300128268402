import {Component, Input} from '@angular/core';
import {Value} from '../../../model/values/value';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
    selector: 'ol-input-html',
    template: '<div [innerHTML]="html"></div>'
})
export class InputHtmlComponent {
    html: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {
    }

    @Input()
    set value(value: Value) {
        this.html = this.sanitizer.bypassSecurityTrustHtml(value.value);
    }
}