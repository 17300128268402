import {InputBase} from './input-base';
import {Component} from '@angular/core';

@Component({
    selector: 'ol-input-password',
    template: '<ng-container [formGroup]="form">' +
    '<input [formControlName]="formControlName" class="form-control" id="{{name}}" type="password" name="{{name}}" [placeholder]="placeholder"' +
    '       (keyup.enter)="onEnterPressed()" (keyup.esc)="onEscapePressed()"/>' +
    '</ng-container>'
})
export class InputPasswordComponent extends InputBase {
}
