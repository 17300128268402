import {Component, Inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EntityImagePositionSelector} from '../model/entities/entity-image-position-selector';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';
import {Url} from '../model/url';
import {RequestBase} from '../services/rest/requests/request-base';
import {ImagePositionsPointsData} from '../model/remote/responses/structures/data/image-positions-points-data';
import {DomSanitizer} from '@angular/platform-browser';
import {RequestServiceHolder} from '../services/rest/request-service-holder';
import {Entity} from '../model/entities/entity';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import '@angular/material/prebuilt-themes/indigo-pink.css';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';

@Component({
    templateUrl: './image-position.component.html',

})
export class ImagePositionComponent implements OnInit {
    private pos_x: number;
    private pos_y: number;
    private _visible = false;
    points: Array<Point>;
    filterValue: number;
    filterValues: { rows: [{ id: number, value: string }] };
    entityVisual: string;
    private _entity: EntityImagePositionSelector;
    @Input() selectedOption: { id: number, value: string };
    imageBase64: HTMLImageElement;
    private originalSize = {width: 0, height: 0};
    pointsOriginal: [{ x: number, y: number, number: number, _width?: string, _height?: string, _color?: string }];
     optionalImage: string;

    constructor(activatedRoute: ActivatedRoute, private location: Location, bodyAttributesSetter: BodyAttributesSetter,
                private _sanitizer: DomSanitizer, private serviceHolder: RequestServiceHolder, private dialog: MatDialog) {
        this._entity = activatedRoute.snapshot.data['entity'];

    }

    ngOnInit(): void {
        if (this._entity.data.options['entity_filter'] !== undefined) {
            this.serviceHolder.entitiesFinder.findEntityByName(this._entity.data.options['entity_filter']).subscribe(entityFilter => {
                this.setInitialFilter(entityFilter);
            });
        } else {
            this.selectedFilterValue(null);
        }
    }


    setInitialFilter(it: Entity) {
        this.entityVisual = it.visualName;
        const url = new Url(it.routePath, true);
        url.geolocation = this.entity.geolocation;

        const params = {'autocomplete': 1};
        const request = new RequestBase<{ rows: [{ id: number, value: string }] }>(url, 'GET', this.serviceHolder);
        request.afterPreconditions = false;
        request.addRequestValues(params);
        request.handle401Error = false;
        request.url.geolocation = this.entity.geolocation;

        request.execute().subscribe((response) => {
            this.filterValues = response.data;
        });
    }


    selectedPoint(event) {
        if (event.target instanceof HTMLSpanElement) {
        } else {
            this.pos_x = event.offsetX ? (event.offsetX) : event.pageX - document.getElementById('pointer_div').offsetLeft;
            this.pos_y = event.offsetY ? (event.offsetY) : event.pageY - document.getElementById('pointer_div').offsetTop;
            /* document.getElementById('cross').style.left = (this.pos_x - 15).toString() + 'px';
             document.getElementById('cross').style.top = (this.pos_y - 15).toString() + 'px';*/
            const fileNameDialogRef = this.dialog.open(ImageDialogComponent, {
                data: {pos_x: this.pos_x, pos_y: this.pos_y, fields: this._entity.data.options['fields'].map(a => ({...a}))},
                disableClose: true

            });
            fileNameDialogRef.afterClosed().subscribe(result => {
                this.saveButton(result);
            });
        }
    }

    selectedFilterValue($event?) {
        if ($event !== '') {
            this.filterValue = $event;
            this.serviceHolder.entitiesFinder.findEntityByName(this._entity.data.options['entity_get_information']).subscribe(it => {
                const url = new Url(it.routePath, true);
                url.geolocation = this.entity.geolocation;
                const params = {
                    'filter_value': $event,
                };
                const request = new RequestBase<ImagePositionsPointsData>(url, 'GET', this.serviceHolder);
                request.afterPreconditions = false;
                if (this.filterValues !== null) {
                    request.addRequestValues(params);
                }
                request.handle401Error = false;
                request.url.geolocation = this.entity.geolocation;

                request.execute().subscribe((response) => {
                    const originalImage = new Image();
                    this.pointsOriginal = response.data.points;
                    this.optionalImage = response.data.background;
                    originalImage.addEventListener('load', t => {
                        this.setOriginalSizeImage(t.target);
                    });
                    originalImage.src = response.data.background;
                    const img = <HTMLImageElement>document.getElementById('imageId');
                    if (img !== null) {
                        img.src = response.data.background;
                    }
                    this.imageBase64 = originalImage;

                    if (this.optionalImage !== null) {
                        this.visible = true;
                    }
                    fromEvent(window, 'resize')
                        .subscribe((event: Event) => {
                            this.resizeWindowCallback(event.target);
                        });


                });
            });

        }
    }

    private setOriginalSizeImage(t) {
        this.originalSize.height = t.height;
        this.originalSize.width = t.width;
    }

    private calculateActualSize(): Array<Point> {
        const img = document.getElementById('imageId');
        const width = img.clientWidth;
        const height = img.clientHeight;
        const pointsAu = new Array<Point>();
        this.pointsOriginal.forEach(it => {
            pointsAu.push(new Point(((it.x * width) / this.originalSize.width), ((it.y * height) / this.originalSize.height), it.number, ((parseInt(it._width, null) * width) / this.originalSize.width).toString(), ((parseInt(it._height, null) * height) / this.originalSize.height).toString(), it._color));
        });
        return pointsAu;
    }

    private calculateRealSize(point: DialogData): Point {
        const img = document.getElementById('imageId');
        const width = img.clientWidth;
        const height = img.clientHeight;
        return new Point(((point.pos_x * this.originalSize.width) / width), ((point.pos_y * this.originalSize.height) / height), point.fields['number']);
    }

    resizeWindowCallback(e) {
        let puntos;
        const points = this.calculateActualSize();
        puntos = document.getElementsByClassName('dot');
        for (let i = 0; i < puntos.length; i++) {
            puntos[i].style.left = points[i].x - (parseInt(points[i].width, null) / 2) + 'px';
            puntos[i].style.top = points[i].y - (parseInt(points[i].height, null) / 2) + 'px';
            puntos[i].style.width = points[i].width;
            puntos[i].style.height = points[i].height;
        }


    }

    saveButton(data?: DialogData) {
        if (data !== null) {
            const point = this.calculateRealSize(data);
            this.serviceHolder.entitiesFinder.findEntityByName(this._entity.data.options['entity_set_point']).subscribe(it => {
                    const url = it.getRestUrl();
                    const params = {
                        'filter_value': this.filterValue,
                        'x': point.x,
                        'y': point.y,
                    };
                    const request = new RequestBase<any>(url, 'POST', this.serviceHolder);
                    request.afterPreconditions = false;
                    request.addRequestValues(params);

                    request.addRequestValue('_height', 40);
                    request.addRequestValue('_width', 40);
                    request.addRequestValue('_color', '#9100FF40');

                    for (const value of data.fields) {
                        request.addRequestValue(value.name, value.type === 'number' ? +value.value : value.value);
                    }
                request.url.geolocation = this.entity.geolocation;

                request.handle401Error = false;
                    request.execute().subscribe((response) => {
                        this.selectedFilterValue(this.filterValue);


                    });
                }
            );
        }
    }


    get visible(): boolean {
        return this._visible;
    }

    set visible(value: boolean) {
        this._visible = value;
    }

    deleteButton(value: Point) {


        const fileNameDialogRef = this.dialog.open(ImageDialogComponent, {
            disableClose: true,
            data: {pos_x: value.x, pos_y: value.y, id: value.value, delete: true},
        });
        fileNameDialogRef.afterClosed().subscribe(result => {
                if (result !== null) {
                    this.serviceHolder.entitiesFinder.findEntityByName(this._entity.data.options['entity_delete']).subscribe(it => {
                        const url = it.getRestUrl({id: result.id});
                        const params = {
                            'filter_value': this.filterValue,
                        };

                        const request = new RequestBase<any>(url, 'DELETE', this.serviceHolder);
                        request.afterPreconditions = false;
                        request.addRequestValues(params);
                        request.handle401Error = false;
                        request.url.geolocation = this.entity.geolocation;

                        request.execute().subscribe((response) => {
                            this.selectedFilterValue(this.filterValue);
                        });
                    });
                }
            }
        );

    }


    get entity(): EntityImagePositionSelector {
        return this._entity;
    }


    back() {
        this.location.back();
    }


    deleteall() {
        this.serviceHolder.entitiesFinder.findEntityByName(this._entity.data.options['entity_delete_all']).subscribe(it => {
            const url = new Url(it.routePath, true);
            url.geolocation = this.entity.geolocation;
            const params = {
                'filter_value': this.filterValue,
            };
            const request = new RequestBase<any>(url, 'DELETE', this.serviceHolder);
            request.afterPreconditions = false;
            request.addRequestValues(params);
            request.handle401Error = false;
            request.url.geolocation = this.entity.geolocation;

            request.execute().subscribe((response) => {
            }, () => this.location.back());
        });

    }

    onLoadImage() {
        this.points = this.calculateActualSize();

    }

    point_it2($mouseEvent, point: Point) {
        this.deleteButton(point);
    }
}

class Point {
    private _x: number;
    private _y: number;
    private _value: number;
    private _color: string;
    private _height: string;
    private _width: string;

    constructor(x: number, y: number, value: number, width?: string, height?: string, color?: string) {
        this._x = x;
        this._y = y;
        this._value = value;
        this._height = height;
        this._width = width;
        this._color = color;
    }

    get x(): number {
        return this._x;
    }

    get pointXInImage(): number {
        return this._x - (parseInt(this.width, null) / 2);

    }

    get pointYInImage(): number {
        return this._y - (parseInt(this.height, null) / 2);

    }

    set x(value: number) {
        this._x = value;
    }

    get y(): number {
        return this._y;
    }

    set y(value: number) {
        this._y = value;
    }

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }

    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = value;
    }

    get height(): string {
        return this._height + 'px';
    }

    set height(value: string) {
        this._height = value;
    }

    get width(): string {
        return this._width + 'px';
    }

    set width(value: string) {
        this._width = value;
    }
}

export interface DialogData {
    pos_x: number;
    pos_y: number;
    id: number;
    delete: boolean;
    fields?: [FieldsImagePositionData];
}

declare type OnValueChanged = () => void;

@Component({
    templateUrl: 'image-position-dialog.component.html',
})
export class ImageDialogComponent implements OnInit {
    form: FormGroup;
    control: FormControl;
    private changed: ((value: any) => void)[] = [];

    constructor(
        public dialog: MatDialogRef<ImageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.form = new FormGroup({});
    }


    private createControl(value: FieldsImagePositionData) {
        this.control = new FormControl(value.value);
        this.control.setValidators(Validators.required);

        this.control.valueChanges.subscribe(value2 => value.value = value2);

        this.registerOnChange(value3 => {
            if (this.control.value !== value3) {
                this.control.setValue(value3);
            }
        });

        this.form.addControl(value.name, this.control);
    }

    registerOnChange(fn: (value: any) => void) {
        this.changed.push(fn);
    }

    ngOnInit(): void {
        if (this.data.fields !== undefined) {
            this.data.fields.forEach(it => {
                this.createControl(it);
            });
        }
    }

    setColor(value: FieldsImagePositionData) {
        this.control.setValue(value.value);
    }
}



