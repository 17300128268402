import {Component, Input, OnInit} from '@angular/core';
import {DashboardChartItem} from '../../model/remote/responses/structures/data/dashboard/dashboard-chart-item';

@Component({
    selector: 'dashboard-chart',
    templateUrl: './chart.component.html',
})
export class ChartComponent implements OnInit {
    public options: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'top',
            fullWidth: true
        }
    };

    @Input() item: DashboardChartItem;
    @Input() columns: number;

    get height(): string {
        return Math.floor(100 / this.columns) + 'vw';
    }

    public chartColors: Array<any> = [];

    ngOnInit(): void {
        if (this.item.data !== undefined) {
            for (const col of this.item.data) {
                if (col.color !== undefined) {
                    this.chartColors.push({backgroundColor: col.color});
                }
            }
        }

    }

}
