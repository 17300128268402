import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {EntityPermissionList} from '../model/entities/entity-permission-list';
import {ActivatedRoute, Router} from '@angular/router';
import {IndividualAction} from '../model/actions/individual-action';
import {GoBackComponent} from './go-back-component';
import {PermissionGroup} from '../model/permissions/permission-group';
import {BaseEntityComponent} from './base-entity.component';
import {BodyAttributesSetter} from '../services/body-attributes-setter.service';

@Component({
    templateUrl: 'permission-list.component.html'
})
export class PermissionListComponent extends BaseEntityComponent<EntityPermissionList> implements OnInit, GoBackComponent {
    permissionsGroup: PermissionGroup[] = [];
    title = 'Change permissions';
    private id;
    individualActions: IndividualAction[] = [];

    constructor(activatedRoute: ActivatedRoute, private location: Location, private router: Router,
                bodyAttributesSetter: BodyAttributesSetter) {
        super(activatedRoute, bodyAttributesSetter);
    }

    ngOnInit(): void {
        localStorage.setItem('last_entity', this.entity.name);
        this.activatedRoute.params.subscribe(params => {
            this.id = params['id'];
            this.entity.getIndividualActions(this.id, this.router, this).subscribe((action) => {
                this.individualActions.push(action);
            });

            this.setPermissions();
        });
    }

    private setPermissions() {
        this.entity.getPermissions(this.id)
            .subscribe(
                group => this.permissionsGroup.push(group)


                , error => this.location.back());
    }

    back() {
        this.location.back();
    }

    save() {
        this.entity.save(this.id).subscribe(() => this.location.back());
    }

    setAllNull() {
        this.entity.setAllNull(this.id).subscribe(() => this.location.back());
    }
}
